import React, { useState } from "react";
import FavouriteButton from "./FavouriteButton";

import image15TUND from './assets/parts/15TUND_image_shopped.jpg';
import image22TUND from './assets/parts/22TUND_image_shopped.jpg';
import imageINSTABLEND from './assets/parts/INTABLEND_image_shopped.jpg';
import imageHON22ZONEVALV from './assets/parts/HON22ZONEVALV_image_shopped.jpg';
import imageVC22 from './assets/parts/VC22_image_shopped.jpg';
import imageALTTGSTAT from './assets/parts/ALTTGSTAT_image_shopped.jpg';
import imageAQUA2002 from './assets/parts/AQUA2002_image_shopped.jpg';
import imageALTINLET222BAR from './assets/parts/ALTINLET222.2BAR_image_shopped.jpg';
import imageALTINLET223BAR from './assets/parts/ALTINLET223BAR_image_shopped.jpg';
import imageALTINLET283BAR from './assets/parts/ALTINLET283BAR_image_shopped.jpg';
import imageALT12PVALVE35 from './assets/parts/ALT12PVALVE35_image_shopped.jpg';
import imageALT6BARPRVORINGWIDE from './assets/parts/ALT6BARPRVORINGWIDE_image_shopped.jpg';
import imageALT12TP45 from './assets/parts/ALT12TP45_image_shopped.jpg';
import imageALT12TP7BAR from './assets/parts/ALT12TP7BAR_image_shopped.jpg';
import imageSHELINK14SS6KW from './assets/parts/SHELINK14SS6KW_image_shopped.jpg';
import imageSHELINK14TITANIUM3KW from './assets/parts/SHELINK14TITANIUM3KW_image_shopped.jpg';
import imageSHELINK14TITANIUM6KW from './assets/parts/SHELINK14TITANIUM6KW_image_shopped.jpg';
import imageSHELINK14SSUNV from './assets/parts/SHELINK14SSUNV_image_shopped.jpg';
import imageTHERMOW14SSUNV from './assets/parts/THERMOW14SSUNV_image_shopped.jpg';
import imageSHELINK11SSUNV from './assets/parts/SHELINK11SSUNV_image_shopped.jpg';
import imageSHELINK27SSVEN from './assets/parts/SHELINK27SSVEN_image_shopped.jpg';
import imageSHELINK14TRI from './assets/parts/SHELINK14TRI_image_shopped.jpg';
import imageSHELINK143PH3KW from './assets/parts/SHELINK143PH3KW_image_shopped.jpg';
import imageSHELINK143PH6KW from './assets/parts/SHELINK143PH6KW_image_shopped.jpg';
import imageSHELINK143PH9KW from './assets/parts/SHELINK143PH9KW_image_shopped.jpg';
import imageIMMUNVSTAT from './assets/parts/IMMUNVSTAT_image_shopped.jpg';
import imageIMMUNVSTAT80 from './assets/parts/IMMUNVSTAT80_image_shopped.jpg';
import image1EXP from './assets/parts/1EXP_image_shopped.jpg';
import image12EXP from './assets/parts/12EXP_image_shopped.jpg';
import image12EXPTORN from './assets/parts/12EXPTORN_image_shopped.jpg';
import image35EXP from './assets/parts/35EXP_image_shopped.jpg';
import image50EXP from './assets/parts/50EXP_image_shopped.jpg';
import imageEXBRACK from './assets/parts/EXBRACK_image_shopped.jpg';
import imageEXBRACKOLD from './assets/parts/EXBRACK-OLD_image_shopped.jpg';
import imageFLOATSWITCH from './assets/parts/FLOATSWITCH_image_shopped.jpg';
import image3MOVERFILL from './assets/parts/3MOVERFILL_image_shopped.jpg';
import image3MWSAFE from './assets/parts/3MWSAFE_image_shopped.jpg';
import image3MWSENSOR from './assets/parts/3MWSENSOR_image_shopped.jpg';
import image3MAUTOFILL from './assets/parts/3MAUTOFILL_image_shopped.jpg';
import imageSOLENOID from './assets/parts/SOLENOID_image_shopped.jpg';
import imageNONE from './assets/parts/noimage.png'

// Import icons
import searchIcon from "./assets/admin-icon.png";
import tundishIcon from "./assets/admin-icon.png";
import valveIcon from "./assets/admin-icon.png";
import inletIcon from "./assets/admin-icon.png";
import timerIcon from "./assets/admin-icon.png";
import immersionIcon from "./assets/admin-icon.png";
import expvesIcon from "./assets/admin-icon.png";
import thermalIcon from "./assets/admin-icon.png";

// Import parts data (Replace with actual import or data source)
const partsData = [
  {
    id: 1,
    category: 'tundish',
    title: 'Tundish 15-22',
    imagePath: image15TUND,
    description: '<b>Product Code:</b> 15TUND <br><br> <b>Suitable for:</b> Tempest & Tornado 90-300LTR <br> The Tundish has 15mm - 22mm compression, used on the discharge pipe for pressure/temperature relief valves.',
    datasheet: './assets/datasheet/tundish.pdf',
  },
  {
    id: 2,
    category: 'tundish',
    title: 'Tundish 22-28',
    imagePath: image22TUND,
    description: '<b>Product Code:</b> 22TUND <br><br> <b>Suitable for:</b> Tempest & Tornado 400-500LTR <br> The Tundish has 22mm - 28mm compression, used on the discharge pipe for pressure/temperature relief valves.',
    datasheet: './assets/datasheet/tundish.pdf',
  },
  {
    id: 3,
    category: 'timer',
    title: 'E7 Horstmann Timer Clock',
    imagePath: imageNONE,
    description: '<b>Product Code:</b> HORSTELEC7 <br><br> <b>Suitable for:</b> 3KW Immersions Only <br> The Horstmann is an Immersion heater timer, suitable for economy 7.',
    datasheet: './assets/datasheet/horstmann.pdf',
  },
  {
    id: 4,
    category: 'valve',
    title: 'Blend Valve 15mm only',
    imagePath: imageINSTABLEND,
    description: '<b>Product Code:</b> INTABLEND <br><br> <b>Suitable for:</b> Thermal Stores (Older Thermal Stores may require pipe work alterations) <br> The Blend Valve is used to mix the water temperature to a suitable value.',
    datasheet: './assets/datasheet/blendvalve.pdf',
  },
  {
    id: 5,
    category: 'valve',
    title: '2 Port Valve Honeywell V4043H',
    imagePath: imageHON22ZONEVALV,
    description: '<b>Product Code:</b> HON22ZONEVALV <br><br> <b>Suitable for:</b> Indirect Cylinders <br> The 2 Port Valve V4043H is a Zone valve used for stopping water once the cylinder is up to the desired temperature.',
    datasheet: './assets/datasheet/v4043h.pdf',
  },
  {
    id: 6,
    category: 'valve',
    title: '2 Port Valve (VC4613) Honeywell',
    imagePath: imageVC22,
    description: '<b>Product Code:</b> VC22 <br><br> <b>Suitable for:</b> Tristors (Manual Fill) <br> The 2 Port Valve (VC4613) is used for Domestic Water only and is used for isolation only.',
    datasheet: './assets/datasheet/vc4613.pdf',
  },
  {
    id: 7,
    category: 'timer',
    title: 'Dual Aquastat (TS95H30.55) Campini (White Box)',
    imagePath: imageALTTGSTAT,
    description: '<b>Product Code:</b> ALTTGSTAT <br><br> <b>Suitable for:</b> Indirect Unvented Cylinders <br> The Dual Aquastat is used to control the temperature of the Cylinder.',
    datasheet: './assets/datasheet/dualstat.pdf',
  },
  {
    id: 8,
    category: 'timer',
    title: 'Single Aquastat (Orange Box)',
    imagePath: imageAQUA2002,
    description: '<b>Product Code:</b> AQUA2002 <br><br> <b>Suitable for:</b> Thermal Stores <br> The Single Aquastat is a single control used to control temperature of Thermal Store.',
    datasheet: './assets/datasheet/singlestat.pdf',
  },
  {
    id: 9,
    category: 'inlet',
    title: 'Inlet Pack 2.2 Bar',
    imagePath: imageALTINLET222BAR,
    description: '<b>Product Code:</b> ALTINLET222.2BAR <br><br> <b>Suitable for:</b> Copper Tornado Cylinders  <br> The Inlet Pack 2.2 Bar <b>(Replacement of the 1.8 Bar Inlet Pack)</b> is used to reduce the pressure of the mains water supply.',
    datasheet: './assets/datasheet/2barinlet.pdf',
  },
  {
    id: 10,
    category: 'inlet',
    title: '22mm Inlet Pack 3 Bar',
    imagePath: imageALTINLET223BAR,
    description: '<b>Product Code:</b> ALTINLET223BAR <br><br> <b>Suitable for:</b> Stainless Unvented Cylinders Below 400L <br> The 22mm Inlet Pack 3 Bar is used to reduce the pressure of the mains water supply and also comes with a 6 bar expansion/pressure relief valve.',
    datasheet: './assets/datasheet/3bar22inlet.pdf',
  },
  {
    id: 11,
    category: 'inlet',
    title: '28mm Inlet Pack 3 Bar (400L&500L Or 1 Inch Cons)',
    imagePath: imageALTINLET283BAR,
    description: 'Product Code:</b> ALTINLET283BAR <br><br> <b>Suitable for:</b> Stainless Unvented Cylinders (400L&500L) <br> The 28mm Inlet Pack 3 Bar is used to reduce the pressure of the mains water supply and also comes with a 6 bar expansion/pressure relief valve.',
    datasheet: './assets/datasheet/3bar28inlet.pdf',
  },
  {
    id: 12,
    category: 'valve',
    title: 'Pressure Relief Valve 3.5BAR (1/2" Only)',
    imagePath: imageALT12PVALVE35,
    description: '<b>Product Code:</b> ALT1/2PVALVE3.5 <br><br> <b>Suitable for:</b> Copper Unvented Cylinders <br> The Pressure Relief Valve 3.5Bar can be used to replace the 3Bar (Grey cap) Pressure relief valve on old copper Tornados',
    datasheet: './assets/datasheet/3barinlet.pdf',
  },
  {
    id: 14,
    category: 'valve',
    title: '6 Bar Safety Valve O Ring Type (Grub Screw)',
    imagePath: imageALT6BARPRVORINGWIDE,
    description: '<b>Product Code:</b> ALT6BARPRVORINGWIDE <br> <b>Suitable for:</b> Inlet Control Group on Stainless Steel Unvented <br> O - Ring Type is identifiable by the Grub Screw holding it in place.',
    datasheet: './assets/datasheet/ALT6BARPRVORINGWIDE.pdf',
  },
  {
    id: 16,
    category: 'valve',
    title: '6 Bar Safety Valve Compression',
    imagePath: imageNONE,
    description: '<b>Product Code:</b> ALT6BARPRVCOMPTYPE <br> <b>Suitable for:</b> Inlet Control Group on Stainless Steel Unvented <br> Comp Type is identifiable by the Compression nut holding it in place.',
    datasheet: './assets/datasheet/ALT6BARPRVCOMPTYPE.pdf',
  },
  {
    id: 17,
    category: 'valve',
    title: 'T&P Relief Valve 1/2 4.5BAR TPR22/TP192 Copper',
    imagePath: imageALT12TP45,
    description: '<b>Product Code:</b> ALT1/2TP4.5 <br> <b>Suitable for:</b> Copper Unvented Cylinders <br> The T&P Relief Valve 1/2" is used to replace the 1/2" 4.5 Bar (Grey cap) on old Copper Tornados.',
    datasheet: './assets/datasheet/ALT12TP45.pdf',
  },
  {
    id: 18,
    category: 'valve',
    title: 'T&P Relief Valve 1/2 7 Bar TPR15 S/S (309473 CST)',
    imagePath: imageALT12TP7BAR,
    description: '<b>Product Code:</b> ALT1/2TP7BAR <br> <b>Suitable for:</b> Stainless Unvented Cylinders <br> T&P Relief Valve 1/2 7 Bar is used to relieve pressure & temperature at 7Bar and/or 90C Degrees Celcius.',
    datasheet: './assets/datasheet/ALT12TP7BAR.pdf',
  },
  {
    id: 19,
    category: 'immersion',
    title: 'S/S Immersion Heater 14" 1 3/4 6KW',
    imagePath: imageSHELINK14SS6KW,
    description: '<b>Product Code:</b> SHELINK14SS6KW <br> <b>Suitable for:</b> Stainless Cylinders (with 1 1/3" Thread) <br> Incoloy Immersion Heater 14" 6KW 1 3/4 Thread.',
    datasheet: './assets/datasheet/SHELINK14SS6KW.pdf',
  },
  {
    id: 20,
    category: 'immersion',
    title: 'S/S Titanium Immersion Heater 14" 1 3/4 3KW',
    imagePath: imageSHELINK14TITANIUM3KW,
    description: '<b>Product Code:</b> SHELINK14TITANIUM3KW <br> <b>Suitable for:</b> Stainless Cylinders (with 1 1/3" Thread) <br> Titanium Immersion Heater 14" 3KW 1 3/4 Thread.',
    datasheet: './assets/datasheet/SHELINK14TITANIUM3KW.pdf',
  },
  {
    id: 21,
    category: 'immersion',
    title: 'S/S Titanium Immersion Heater 14" 1 3/4 6KW',
    imagePath: imageSHELINK14TITANIUM6KW,
    description: '<b>Product Code:</b> SHELINK14TITANIUM6KW <br> <b>Suitable for:</b> Stainless Cylinders (with 1 1/3" Thread) <br> Titanium Immersion Heater 14" 6KW 1 3/4 Thread.',
    datasheet: './assets/datasheet/SHELINK14TITANIUM6KW.pdf',
  },
  {
    id: 22,
    category: 'immersion',
    title: 'S/S Immersion Heater 14" 1 3/4 3KW (80014ATSTA)',
    imagePath: imageSHELINK14SSUNV,
    description: '<b>Product Code:</b> SHELINK14SSUNV <br> <b>Suitable for:</b> Stainless Cylinders (with 1 3/4" Thread) <br> Incoloy Immersion Heater 14" 3KW 1 3/4 Thread includes IMMUNV stat (Make = COTHERM).',
    datasheet: './assets/datasheet/SHELINK14SSUNV.pdf',
  },
  {
    id: 23,
    category: 'immersion',
    title: 'S/S Immersion Heater 14" 1 3/4 3KW THERMOWATT',
    imagePath: imageTHERMOW14SSUNV,
    description: '<b>Product Code:</b> THERMOW14SSUNV <br> <b>Suitable for:</b> Stainless Cylinders (with 1 3/4" Thread) <br> Incoloy Immersion Heater 14" 3KW 1 3/4 Thread includes IMMUNV stat (Make = THERMOWATT)',
    datasheet: './assets/datasheet/THERMOW14SSUNV.pdf',
  },
  {
    id: 24,
    category: 'immersion',
    title: 'S/S Immersion Heater 11" 1 3/4 3KW (80011ATSTA)',
    imagePath: imageSHELINK11SSUNV,
    description: '<b>Product Code:</b> SHELINK11SSUNV <br> <b>Suitable for:</b> Stainless Cylinders (with 1 3/4" Thread) <br> Incoloy Immersion Heater 11" 3KW 1 3/4 Thread includes IMMUNV stat (Make = COTHERM). Can be used if 14" Immersion is obstructed by coil.',
    datasheet: './assets/datasheet/SHELINK11SSUNV.pdf',
  },
  {
    id: 25,
    category: 'immersion',
    title: 'S/S 27" Immersion Heater Vented',
    imagePath: imageSHELINK27SSVEN,
    description: '<b>Product Code:</b> SHELINK27SSVEN <br> <b>Suitable for:</b> Stainless Vented Cylinders (with 1 3/4" Thread) <br> Incoloy Immersion Heater 27" 3KW 1 3/4 Thread used for Top entry immersion heater bosses.',
    datasheet: './assets/datasheet/SHELINK27SSVEN.pdf',
  },
  {
    id: 26,
    category: ['immersion', 'therm'],
    title: 'Thermal Store Immersion Heater 2 1/4',
    imagePath: imageSHELINK14TRI,
    description: '<b>Product Code:</b> SHELINK14TRI <br> <b>Suitable for:</b> Copper Tristors & Tristars <br> Incoloy Immersion Heater 14" 2 1/4" only to be used with Copper Tristors and Tristars.',
    datasheet: './assets/datasheet/SHELINK14TRI.pdf',
  },
  {
    id: 27,
    category: 'immersion',
    title: '3KW 3 Phase 2 1/4" 14" Immersion Heater',
    imagePath: imageSHELINK143PH3KW,
    description: '<b>Product Code:</b> SHELINK143PH3KW <br> <b>Suitable for:</b> Copper Cylinders <br> Incoloy Immersion Heater 14" 2 1/4" running at 3KW with a triple phase.',
    datasheet: './assets/datasheet/SHELINK143PH3KW.pdf',
  },
  {
    id: 28,
    category: 'immersion',
    title: '6KW 3 Phase 2 1/4" 14" Immersion Heater',
    imagePath: imageSHELINK143PH6KW,
    description: '<b>Product Code:</b> SHELINK143PH6KW <br> <b>Suitable for:</b> Copper Cylinders <br> Incoloy Immersion Heater 14" 2 1/4" running at 6KW with a triple phase.',
    datasheet: './assets/datasheet/SHELINK143PH6KW.pdf',
  },
  {
    id: 29,
    category: 'immersion',
    title: '9KW 3 Phase 2 1/4" 14" Immersion Heater',
    imagePath: imageSHELINK143PH9KW,
    description: '<b>Product Code:</b> SHELINK143PH9KW <br> <b>Suitable for:</b> Copper Cylinders <br> Incoloy Immersion Heater 14" 2 1/4" running at 9KW with a triple phase.',
    datasheet: './assets/datasheet/SHELINK143PH9KW.pdf',
  },
  {
    id: 30,
    category: 'immersion',
    title: 'S/S Immersion Stat 14" 1 3/4 65 Degrees',
    imagePath: imageIMMUNVSTAT,
    description: '<b>Product Code:</b> IMMUNVSTAT <br> <b>Suitable for:</b> SHELINK14SSUNV & THERMO14SSUNV <br> S/S Thermastat for Unvented Cylinder Immersion heaters.',
    datasheet: './assets/datasheet/IMMUNVSTAT.pdf',
  },
  {
    id: 31,
    category: 'immersion',
    title: 'S/S Immersion Stat 14" 1 3/4 (Backer Imms)',
    imagePath: imageIMMUNVSTAT80,
    description: '<b>Product Code:</b> IMMUNVSTAT80 <br> <b>Suitable for:</b> SHELINK14SSUNV & THERMO14SSUNV <br> 80 Degree Celcius Thermastat for Unvented Cylinder Immersion Heaters.',
    datasheet: './assets/datasheet/IMMUNVSTAT80.pdf',
  },
  {
    id: 32,
    category: 'immersion',
    title: 'Thermal Store Thermostat Copper 73 Degrees',
    imagePath: imageIMMUNVSTAT,
    description: '<b>Product Code:</b> IMMTHERMSTAT <br> <b>Suitable for:</b> SHELINK14TRI (Thermal Stores) <br> 73 Degrees Celcius Thermastat for Thermal Store Immersion Heaters.',
    datasheet: './assets/datasheet/IMMTHERMSTAT.pdf',
  },
  {
    id: 33,
    category: ['expves', 'therm'],
    title: '1 LTR Expansion Vessel (Shock Arrestor)',
    imagePath: image1EXP,
    description: '<b>Product Code:</b> 1EXP <br> <b>Suitable for:</b> Thermal Stores <br> 1 Ltr Expansion Vessel suitable for Thermal Store domestic hot water coils.',
    datasheet: './assets/datasheet/1EXP.pdf',
  },
  {
    id: 34,
    category: 'expves',
    title: 'Tempest 12 LTR Expansion Vessel 90/125/150LTR Cylinder',
    imagePath: image12EXP,
    description: '<b>Product Code:</b> 12EXP <br> <b>Suitable for: </b> 90/150Ltr Tempest Cylinders <br> Wall mounted potable Expansion Vessel, with 22mm Compression fitting. <br> 310mm Height : 280mm Diameter.',
    datasheet: './assets/datasheet/12EXP.pdf',
  },
  {
    id: 35,
    category: 'expves',
    title: 'Tornado 12 LTR Expansion Vessel 90/125/150LTR Cylinder',
    imagePath: image12EXPTORN,
    description: '<b>Product Code:</b> 12EXPTORN <br> <b>Suitable for:</b> 90/150Ltr Tornado Cylinders <br> Internal potable Expansion Vessel, with 3/4" Male fitting. <br> 310mm Height : 280mm Diameter.',
    datasheet: './assets/datasheet/12EXPTORN.pdf',
  },
  {
    id: 36,
    category: 'expves',
    title: 'Tempest 19 LTR Expansion Vessel 170/200LTR Cylinder',
    imagePath: image12EXP,
    description: '<b>Product Code:</b> 19EXP <br> <b>Suitable for:</b> 170/200Ltr Tempest Cylinders <br> Wall mounted potable Expansion Vessel, with 22mm Compression fitting. <br> 380mm Height : 280mm Diameter.',
    datasheet: './assets/datasheet/19EXP.pdf',
  },
  {
    id: 37,
    category: 'expves',
    title: 'Tornado 19 LTR Expansion Vessel 170/200LTR Cylinder',
    imagePath: image12EXPTORN,
    description: '<b>Product Code:</b> 19EXPTORN <br> <b>Suitable for:</b> 170/200Ltr Tornado Cylinders <br> Internal potable Expansion Vessel, with 3/4" Male fitting. <br> 380mm Height : 280mm Diameter.',
    datasheet: './assets/datasheet/19EXPTORN.pdf',
  },
  {
    id: 38,
    category: 'expves',
    title: 'Tempest 24 LTR Expansion Vessel 250/300LTR Cylinder',
    imagePath: image12EXP,
    description: '<b>Product Code:</b> 24EXP <br> <b>Suitable for:</b> 250/300Ltr Tempest Cylinders <br> Wall mounted potable Expansion Vessel, with 22mm Compression fitting. <br> 500mm Height : 280mm Diameter.',
    datasheet: './assets/datasheet/24EXP.pdf',
  },
  {
    id: 39,
    category: 'expves',
    title: 'Tornado 24 LTR Expansion Vessel 250/300LTR Cylinder',
    imagePath: image12EXPTORN,
    description: '<b>Product Code:</b> 24EXPTORN <br> <b>Suitable for:</b> 250/300Ltr Tornado Cylinders <br> Internal potable Expansion Vessel, with 3/4" Male fitting. <br> 500mm Height : 280mm Diameter',
    datasheet: './assets/datasheet/24EXPTORN.pdf',
  },
  {
    id: 40,
    category: 'expves',
    title: 'Tempest 35 LTR Expansion Vessel 400LTR Cylinder',
    imagePath: image35EXP,
    description: '<b>Product Code:</b> 35EXP <br> <b>Suitable for:</b> 400Ltr Tempest Cylinders <br> Wall mounted potable Expansion Vessel, with 22mm Compression fitting. <br> 455mm Height : 354mm Diameter.',
    datasheet: './assets/datasheet/35EXP.pdf',
  },
  {
    id: 41,
    category: 'expves',
    title: 'Tempest 50 LTR Expansion Vessel 500LTR Cylinder',
    imagePath: image50EXP,
    description: '<b>Product Code:</b> 50EXP <br> <b>Suitable for:</b> 500Ltr Tempest Cylinders <br> Wall mounted potable Expansion Vessel, with 22mm Compression fitting. <br> 605mm Height : 409mm Diameter.',
    datasheet: './assets/datasheet/50EXP.pdf',
  },
  {
    id: 42,
    category: 'expves',
    title: 'Expansion Vessel Bracket',
    imagePath: imageEXBRACK,
    description: '<b>Product Code:</b> EXBRACK <br> <b>Suitable for:</b> Expansion Vessels without built in bracket <br> Used for mounting expansion vessels.',
    datasheet: './assets/datasheet/EXBRACK.pdf',
  },
  {
    id: 43,
    category: 'expves',
    title: 'Expansion Vessel Bracket/Jubilee Clip',
    imagePath: imageEXBRACKOLD,
    description: '<b>Product Code:</b> EXBRACK/OLD <br> <b>Suitable for:</b> Expansion Vessels without built in bracket <br> Used for mounting expansion vessels.',
    datasheet: './assets/datasheet/EXBRACKOLD.pdf',
  },
  {
    id: 44,
    category: 'therm',
    title: 'Float Switch',
    imagePath: imageFLOATSWITCH,
    description: '<b>Product Code:</b> FLOATSWITCH <br> <b>Suitable for:</b> Tristor Manual Fill & HS7 <br> To control water level in Tristor MF & HS7 cylinders.',
    datasheet: './assets/datasheet/FLOATSWITCH.pdf',
  },
  {
    id: 45,
    category: 'therm',
    title: 'Overfill Box',
    imagePath: image3MOVERFILL,
    description: '<b>Product Code:</b> 3MOVERFILL <br> <b>Suitable for:</b> Tristors Manual Fill <br> Connected to Float Switch and VC22 to prevent Cylinder overflowing and flooding.',
    datasheet: './assets/datasheet/3MOVERFILL.pdf',
  },
  {
    id: 46,
    category: 'therm',
    title: 'Watersafe Box',
    imagePath: image3MWSAFE,
    description: '<b>Product Code:</b> 3MWSAFE <br> <b>Suitable for:</b> HS7 <br> Control box connecting to Watersafe Sensor (Pad & Lead).',
    datasheet: './assets/datasheet/3MWSAFE.pdf',
  },
  {
    id: 47,
    category: 'therm',
    title: 'Watersafe Sensor (Pad & Lead)',
    imagePath: image3MWSENSOR,
    description: '<b>Product Code:</b> 3MWSENSOR <br> <b>Suitable for:</b> HS7 <br> Pad & Lead detecting water, connected to Watersafe.',
    datasheet: './assets/datasheet/3MWSENSOR.pdf',
  },
  {
    id: 48,
    category: 'therm',
    title: 'Autofill Box',
    imagePath: image3MAUTOFILL,
    description: '<b>Product Code:</b> 3MAUTOFILL <br> <b>Suitable for:</b> HS7/HS6 <br> Control box for moderating water level inside cylinder.',
    datasheet: './assets/datasheet/3MAUTOFILL.pdf',
  },
  {
    id: 49,
    category: ['therm', 'valve'],
    title: 'Solenoid Valve',
    imagePath: imageSOLENOID,
    description: '<b>Product Code:</b> SOLENOID <br> <b>Suitable for:</b> HS7/HS6 <br> Controls flow of water using signals from the Autofill.',
    datasheet: './assets/datasheet/SOLENOID.pdf',
  },
  // Add more parts as per your data...
];

function PartsList() {
  const [filter, setFilter] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");

  // Filtered parts based on category & search query
  const filteredParts = partsData.filter((part) => {
    const matchesCategory =
      filter === "all" ||
      (Array.isArray(part.category)
        ? part.category.includes(filter)
        : part.category === filter);
    const matchesSearch =
      part.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      part.description.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesCategory && matchesSearch;
  });

  return (
    <div className="min-h-screen bg-gray-100 p-6 flex flex-col items-center">
      {/* Favorite Button */}
      <FavouriteButton pageUrl={window.location.pathname} />

      {/* Page Title */}
      <h1 className="text-4xl font-semibold text-gray-800 mb-6">Parts List</h1>

      {/* 🔍 Search Bar */}
      <div className="relative mb-6 w-full max-w-3xl">
        <input
          type="text"
          placeholder="Search for a part..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full px-5 py-3 text-sm border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
        />
        <img src={searchIcon} alt="Search" className="absolute top-3 right-4 w-6 opacity-50" />
      </div>

      {/* 📌 Category Filter Buttons with Icons */}
      <div className="flex flex-wrap gap-4 mb-6">
        {[
          { name: "All", category: "all", icon: null },
          { name: "Tundish", category: "tundish", icon: tundishIcon },
          { name: "Valves", category: "valve", icon: valveIcon },
          { name: "Inlets", category: "inlet", icon: inletIcon },
          { name: "Timers", category: "timer", icon: timerIcon },
          { name: "Immersions", category: "immersion", icon: immersionIcon },
          { name: "Expansion Vessels", category: "expves", icon: expvesIcon },
          { name: "Thermal Store", category: "therm", icon: thermalIcon },
        ].map((btn) => (
          <button
            key={btn.category}
            onClick={() => setFilter(btn.category)}
            className={`px-5 py-2 flex items-center gap-2 text-sm font-medium rounded-lg transition ${
              filter === btn.category
                ? "bg-primary text-white"
                : "bg-gray-200 text-gray-800 hover:bg-primary hover:text-white"
            }`}
          >
            {btn.icon && <img src={btn.icon} alt={btn.name} className="w-5 h-5" />}
            {btn.name}
          </button>
        ))}
      </div>

      {/* 📦 Parts Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-6xl">
        {filteredParts.length > 0 ? (
          filteredParts.map((part) => (
            <div
              key={part.id}
              className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition cursor-pointer border border-gray-200 flex flex-col items-center text-center"
            >
              <img src={part.imagePath} alt={part.title} className="w-32 h-32 object-cover mb-4" />
              <h4 className="text-lg font-semibold text-gray-800">{part.title}</h4>
              <p
                className="text-sm text-gray-600 mt-2"
                dangerouslySetInnerHTML={{
                  __html: part.description.replace(/\n/g, "<br>"),
                }}
              ></p>
              <button
                onClick={() => window.open(part.datasheet, "_blank")}
                className="mt-4 bg-primary text-white px-4 py-2 rounded-md hover:bg-opacity-90 transition"
              >
                View Datasheet
              </button>
            </div>
          ))
        ) : (
          <p className="text-gray-600">No parts found matching your search.</p>
        )}
      </div>
    </div>
  );
}

export default PartsList;
