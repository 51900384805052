import React from "react";

const FavouritesModal = ({ isOpen, onClose, favourites }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50" onClick={onClose}>
      <div className="bg-white p-6 rounded-lg shadow-lg w-96 z-50 relative" onClick={(e) => e.stopPropagation()}>
        <button onClick={onClose} className="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded">Close</button>
        <h2 className="text-lg font-bold mb-4">Favourites</h2>

        {favourites && favourites.length > 0 ? (
          <ul className="space-y-2">
            {favourites.map((favPath, index) => (
              <li key={index}>
                <a href={favPath} className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                  {favPath}
                </a>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-500">You have no favourites</p>
        )}
      </div>
    </div>
  );
};

export default FavouritesModal;
