import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import FavouriteButton from "./FavouriteButton";

// Import icons
import mapIcon from "./assets/bigmap-icon.png";
import partsIcon from "./assets/parts-icon.png";
import poundIcon from "./assets/pound.png";
import waresaleIcon from "./assets/warehousesales.png";
import productIcon from "./assets/product-return.png";
import allocateIcon from "./assets/allocate.png";

const SalesDashboard = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const userEmail = auth.currentUser?.email;
      if (userEmail) {
        const db = getFirestore();
        const userDocRef = doc(db, "Users", userEmail);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          setUserData(userDoc.data()); // Save user data
        } else {
          console.log("No user data found!");
        }
      }
    };

    fetchData();
  }, [auth]);

  // Tile data for all users
  const baseTiles = [
    { title: "Parts Prices", icon: partsIcon, route: "/PartsPrices" },
    { title: "Quoting", icon: poundIcon, route: "/QuoteList" },
    { title: "Merchant Map", icon: mapIcon, route: "/MerchantMap" },
    { title: "Sales Warehouse", icon: waresaleIcon, route: "/SalesWarehouse" },
    { title: "Sketch Pads", icon: waresaleIcon, route: "/SketchPads" },
    { title: "Sales Rep Search", icon: mapIcon, route: "/SalesRepSearch" },
    
  ];

  // Conditional Tiles Based on `userData`
  const dynamicTiles = [];
  if (userData?.internal) {
    dynamicTiles.push({ title: "Stock Allocation", icon: allocateIcon, route: "/StockAllocation" });
  }
  if (userData?.sb) {
    dynamicTiles.push({ title: "Warehouse Archive", icon: productIcon, route: "/WarehouseArchive" });
  }

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center p-6">
      {/* Favorite Button */}
      <FavouriteButton pageUrl={window.location.pathname} />

      {/* Page Title */}
      <h1 className="text-4xl font-semibold text-gray-800 mb-8">Sales Dashboard</h1>

      {/* Tile Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-5xl">
        {/* Render Base Tiles */}
        {baseTiles.map((tile, index) => (
          <Tile key={index} title={tile.title} icon={tile.icon} onClick={() => navigate(tile.route)} />
        ))}

        {/* Render Dynamic Tiles */}
        {dynamicTiles.map((tile, index) => (
          <Tile key={index} title={tile.title} icon={tile.icon} onClick={() => navigate(tile.route)} />
        ))}
      </div>
    </div>
  );
};

// ✅ Reusable Tile Component
const Tile = ({ title, icon, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="group bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition cursor-pointer transform hover:-translate-y-1 flex flex-col items-center justify-center text-center w-full h-48 border border-gray-200"
    >
      <div className="flex justify-center items-center w-20 h-20 bg-gray-200 rounded-full group-hover:bg-primary transition">
        <img src={icon} alt={title} className="h-10 w-10 object-contain" />
      </div>
      <p className="mt-4 text-lg font-semibold text-gray-800 group-hover:text-primary transition">{title}</p>
    </div>
  );
};

export default SalesDashboard;
