import React, { useState, useEffect } from 'react';
import { db } from '../firebase/firebaseConfig';
import { doc, setDoc } from 'firebase/firestore';

const MassUpload = () => {
  const [countdown, setCountdown] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const dataToUpload = [
    
    {
      id: "15TUND",
        data: {
          desc: "TUNDISH 15-22",
	    code: "15TUND",
          costexvat: "8",
 	    costincvat: "9.6",
category: "Safety Devices",

      }
},
 
{
      id: "22TUND",
        data: {
          desc: "TUNDISH 22-28",
	    code: "22TUND",
          costexvat: "10",
 	    costincvat: "12",
category: "Safety Devices",

      }
},
 
{
      id: "HORSTELEC7DIG",
        data: {
          desc: "E7 HORSTMANN TIMER CLOCK",
	    code: "HORSTELEC7DIG",
          costexvat: "65",
 	    costincvat: "78",
category: "Timers",

      }
},
 
{
      id: "BLENDVALVE",
        data: {
          desc: "BLEND VALVE ASSEMBLY 15MM ONLY",
	    code: "BLENDVALVE",
          costexvat: "75",
 	    costincvat: "90",
category: "Valves",

      }
},
 
{
      id: "HON22ZONEVALV",
        data: {
          desc: "22mm PORT VALVE HONEYWELL V4043H",
	    code: "HON22ZONEVALV",
          costexvat: "60",
 	    costincvat: "72",
category: "Valves",

      }
},
 
{
      id: "HON28ZONEVALV",
        data: {
          desc: "28mm PORT VALVE HONEYWELL V4043H",
	    code: "HON28ZONEVALV",
          costexvat: "82",
 	    costincvat: "98.4",
category: "Valves",

      }
},
 
{
      id: "HON3PORT28",
        data: {
          desc: "28mm 3 PORT VALVE HONEYWELL",
	    code: "HON3PORT28",
          costexvat: "90",
 	    costincvat: "108",
category: "Valves",

      }
},
 
{
      id: "VC22",
        data: {
          desc: "2 PORT VALVE (VC4613) HONEYWELL",
	    code: "VC22",
          costexvat: "60",
 	    costincvat: "72",
category: "Valves",

      }
},
 
{
      id: "VC28",
        data: {
          desc: "28MM ENERGY CUT OUT VALVE",
	    code: "VC28",
          costexvat: "180",
 	    costincvat: "216",
category: "Valves",

      }
},
 
{
      id: "ALTTGSTAT",
        data: {
          desc: "DUAL AQUASTAT (TS95H30.55) CAMPINI(WHITE BOX)",
	    code: "ALTTGSTAT",
          costexvat: "63",
 	    costincvat: "75.6",
category: "Thermostats",

      }
},
 
{
      id: "AQUA2002",
        data: {
          desc: "SINGLE HIGH LIMIT AQUASTAT(WHITE BOX)",
	    code: "AQUA2002",
          costexvat: "65",
 	    costincvat: "78",
category: "Thermostats",

      }
},
 
{
      id: "ALTINLET222BAR",
        data: {
          desc: "INLET PACK 2.2BAR",
	    code: "ALTINLET222BAR",
          costexvat: "78",
 	    costincvat: "93.6",
category: "Pressure Reducing Valves",

      }
},
 
{
      id: "ALTINLET223BAR",
        data: {
          desc: "22mm INLET PACK 3 BAR",
	    code: "ALTINLET223BAR",
          costexvat: "78",
 	    costincvat: "93.6",
category: "Pressure Reducing Valves",

      }
},
 
{
      id: "ALTINLET223BAR-OLD",
        data: {
          desc: "22mm INLET PACK OLD (O RING RELIEF VERSION)",
	    code: "ALTINLET223BAR-OLD",
          costexvat: "78",
 	    costincvat: "93.6",
category: "Pressure Reducing Valves",

      }
},
 
{
      id: "ALTINLET283BAR",
        data: {
          desc: "28mm INLET PACK 3 BAR (400L&500L OR 1 INCH CON'S)",
	    code: "ALTINLET283BAR",
          costexvat: "100",
 	    costincvat: "120",
category: "Pressure Reducing Valves",

      }
},
 
{
      id: "ALTINLET11-43BAR",
        data: {
          desc: "1 1-4 INCH INLET PACK 3 BAR",
	    code: "ALTINLET11-43BAR",
          costexvat: "200",
 	    costincvat: "240",
category: "Pressure Reducing Valves",

      }
},
 
{
      id: "ALTINLET11-23BAR",
        data: {
          desc: "1 1-2INCH INLET PACK 3 BAR",
	    code: "ALTINLET11-23BAR",
          costexvat: "290",
 	    costincvat: "348",
category: "Pressure Reducing Valves",

      }
},
 
{
      id: "ALT2PRV",
        data: {
          desc: "2 INCH INLET PACK 3 BAR",
	    code: "ALT2PRV",
          costexvat: "380",
 	    costincvat: "456",
category: "Pressure Reducing Valves",

      }
},
 
{
      id: "ALT1-2PVALVE3.5",
        data: {
          desc: "PRESSURE RELIEF VALVE 3.5BAR (1-2 INCH ONLY)",
	    code: "ALT1-2PVALVE3.5",
          costexvat: "30",
 	    costincvat: "36",
category: "Safety Valves",

      }
},
 
{
      id: "ALT6BARPRVORINGWIDE",
        data: {
          desc: "6 BAR SAFETY VALVE O RING TYPE(Grub Screw)",
	    code: "ALT6BARPRVORINGWIDE",
          costexvat: "30",
 	    costincvat: "36",
category: "Safety Valves",

      }
},
 
{
      id: "ALT6BARPRVCOMPTYPE",
        data: {
          desc: "6 BAR SAFETY VALVE COMPRESSION TYPE",
	    code: "ALT6BARPRVCOMPTYPE",
          costexvat: "30",
 	    costincvat: "36",
category: "Safety Valves",

      }
},
 
{
      id: "ALT1-2TP4.5",
        data: {
          desc: "T&P RELIEF VALVE 3-4 4.5BAR TPR22-TP192 COPPER",
	    code: "ALT1-2TP4.5",
          costexvat: "30",
 	    costincvat: "36",
category: "Safety Valves",

      }
},
 
{
      id: "ALT1-2TP7BAR",
        data: {
          desc: "T&P RELIEF VALVE 3-4 7 BAR TPR15 S-S (309473 CST)",
	    code: "ALT1-2TP7BAR",
          costexvat: "30",
 	    costincvat: "36",
category: "Safety Valves",

      }
},
 
{
      id: "THERMOWUNVSTAT",
        data: {
          desc: "Brown RTS Thermowatt Replament Imm Stat (2018)",
	    code: "THERMOWUNVSTAT",
          costexvat: "20",
 	    costincvat: "24",
category: "Thermostats",

      }
},
 
{
      id: "THERMOW14SMART",
        data: {
          desc: "S-S Immersion Heater 14 INCH 1 -4 INCH Smart",
	    code: "THERMOW14SMART",
          costexvat: "48",
 	    costincvat: "57.6",
category: "Immersion Heaters",

      }
},
 
{
      id: "IMMUNVSTAT-SMART",
        data: {
          desc: "Grey smart stat Termowatt",
	    code: "IMMUNVSTAT-SMART",
          costexvat: "28",
 	    costincvat: "33.6",
category: "Thermostats",

      }
},
 
{
      id: "THERMOWTHERMSTAT",
        data: {
          desc: "Termal store STAT, Brown, Cotherm",
	    code: "THERMOWTHERMSTAT",
          costexvat: "20",
 	    costincvat: "24",
category: "Thermostats",

      }
},
 
{
      id: "COMLID18",
        data: {
          desc: "Plastic Combi Ltd",
	    code: "COMLID18",
          costexvat: "10",
 	    costincvat: "12",
category: "Misc",

      }
},
 
{
      id: "IMMPLG13-4",
        data: {
          desc: "1 3-4 INCH immersion plug",
	    code: "IMMPLG13-4",
          costexvat: "15",
 	    costincvat: "18",
category: "Immersion Heaters",

      }
},
 
{
      id: "SHELINK14SS6KW",
        data: {
          desc: "S-S IMMERSION HEATER 14 INCH 13-4 6KW",
	    code: "SHELINK14SS6KW",
          costexvat: "90",
 	    costincvat: "108",
category: "Immersion Heaters",

      }
},
 
{
      id: "SHELINK14TITANIUM3KW",
        data: {
          desc: "S-S TITANIUM IMMERSION HEATER 14 INCH 13-4 3KW",
	    code: "SHELINK14TITANIUM3KW",
          costexvat: "75",
 	    costincvat: "90",
category: "Immersion Heaters",

      }
},
 
{
      id: "SHELINK14TITANIUM6KW",
        data: {
          desc: "S-S TITANIUM IMMERSION HEATER 14 INCH 13-4 6KW",
	    code: "SHELINK14TITANIUM6KW",
          costexvat: "215.18",
 	    costincvat: "258.22",
category: "Immersion Heaters",

      }
},
 
{
      id: "SHELINK14SSUNV",
        data: {
          desc: "S-S IMMERSION HEATER 14 INCH COTHERM 13-4 (80014ATSTA)",
	    code: "SHELINK14SSUNV",
          costexvat: "35",
 	    costincvat: "42",
category: "Immersion Heaters",

      }
},
 
{
      id: "THERMOW14SSUNV",
        data: {
          desc: "S-S IMMERSION HEATER 14 INCH 13-4 INCH THERMOWATT",
	    code: "THERMOW14SSUNV",
          costexvat: "35",
 	    costincvat: "42",
category: "Immersion Heaters",

      }
},
 
{
      id: "SHELINK11SSUNV",
        data: {
          desc: "S-S IMMERSION 11 INCH 13-4 (80011ATSTA)",
	    code: "SHELINK11SSUNV",
          costexvat: "35",
 	    costincvat: "42",
category: "Immersion Heaters",

      }
},
 
{
      id: "SHELINK27SSVEN",
        data: {
          desc: "S-S 27 INCH IMMERSION HEATER VENTED",
	    code: "SHELINK27SSVEN",
          costexvat: "35",
 	    costincvat: "42",
category: "Immersion Heaters",

      }
},
 
{
      id: "SHELINK14TRI",
        data: {
          desc: "THERMAL STORE IMMERSION HEATER 21-4 INCH",
	    code: "SHELINK14TRI",
          costexvat: "35",
 	    costincvat: "42",
category: "Immersion Heaters",

      }
},
 
{
      id: "SHELINK14TRI-SEACON",
        data: {
          desc: "THERMAL STORE IMMERSION HEATER 21-4 INCH Hallmark",
	    code: "SHELINK14TRI-SEACON",
          costexvat: "55",
 	    costincvat: "66",
category: "Immersion Heaters",

      }
},
 
{
      id: "SHELINK143PH3KW-2.25",
        data: {
          desc: "3KW 3PHASE 2 1-4 INCH 14 INCH IMMERSION",
	    code: "SHELINK143PH3KW-2.25",
          costexvat: "95",
 	    costincvat: "114",
category: "Immersion Heaters",

      }
},
 
{
      id: "SHELINK143PH6KW-2.25",
        data: {
          desc: "6KW 3PHASE 2 1-4 INCH 14 INCH IMMERSION",
	    code: "SHELINK143PH6KW-2.25",
          costexvat: "120",
 	    costincvat: "144",
category: "Immersion Heaters",

      }
},
 
{
      id: "SHELINK143PH9KW-2.25",
        data: {
          desc: "9KW 3PHASE 2 1-4 INCH 14 INCH IMMERSION",
	    code: "SHELINK143PH9KW-2.25",
          costexvat: "150",
 	    costincvat: "180",
category: "Immersion Heaters",

      }
},
 
{
      id: "IMMUNVSTAT",
        data: {
          desc: "S-S IMMERSION STAT 14 INCH 13-4 65DEGREES",
	    code: "IMMUNVSTAT",
          costexvat: "20",
 	    costincvat: "24",
category: "Thermostats",

      }
},
 
{
      id: "IMMUNVSTAT80",
        data: {
          desc: "S-S IMMERSION STAT 14 INCH 13-4(Backer Imm's)",
	    code: "IMMUNVSTAT80",
          costexvat: "20",
 	    costincvat: "24",
category: "Thermostats",

      }
},
 
{
      id: "IMMTHERMSTAT",
        data: {
          desc: "THERMAL STORE THERMOSTAT COPPER 73DEGREES",
	    code: "IMMTHERMSTAT",
          costexvat: "20",
 	    costincvat: "24",
category: "Thermostats",

      }
},
 
{
      id: "1EXP",
        data: {
          desc: "1LTR EXPANSION VESSEL (SHOCK ARRESTOR)",
	    code: "1EXP",
          costexvat: "38",
 	    costincvat: "45.6",
category: "Expansion Vessels",

      }
},
 
{
      id: "TECTS2STR",
        data: {
          desc: "1LTR EXPANSION VESSEL FITTING",
	    code: "TECTS2STR",
          costexvat: "10.8",
 	    costincvat: "12.96",
category: "Expansion Vessels",

      }
},
 
{
      id: "12EXP",
        data: {
          desc: "TEMPEST 12LTR EXPANSION VESSEL 125-150LTR",
	    code: "12EXP",
          costexvat: "30",
 	    costincvat: "36",
category: "Expansion Vessels",

      }
},
 
{
      id: "12EXPTORN",
        data: {
          desc: "TORNADO 12LTR EXPANSION VESSEL 125-150LTR",
	    code: "12EXPTORN",
          costexvat: "30",
 	    costincvat: "36",
category: "Expansion Vessels",

      }
},
 
{
      id: "19EXP",
        data: {
          desc: "TEMPEST 18LTR EXPANSION VESSEL 170-200LTR",
	    code: "19EXP",
          costexvat: "38",
 	    costincvat: "45.6",
category: "Expansion Vessels",

      }
},
 
{
      id: "19EXPTORN",
        data: {
          desc: "TORNADO 18LTR EXPANSION VESSEL 170-200LTR",
	    code: "19EXPTORN",
          costexvat: "38",
 	    costincvat: "45.6",
category: "Expansion Vessels",

      }
},
 
{
      id: "24EXP",
        data: {
          desc: "TEMPEST 24LTR EXPANSION VESSEL 250-300LTR",
	    code: "24EXP",
          costexvat: "45",
 	    costincvat: "54",
category: "Expansion Vessels",

      }
},
 
{
      id: "24EXPTORN",
        data: {
          desc: "TORNADO 24LTR EXPANSION VESSEL 250-300LTR",
	    code: "24EXPTORN",
          costexvat: "45",
 	    costincvat: "54",
category: "Expansion Vessels",

      }
},
 
{
      id: "ZIL24",
        data: {
          desc: "TORNADO 24LTR EXPANSION VESSEL 250-300LTR",
	    code: "ZIL24",
          costexvat: "48",
 	    costincvat: "57.6",
category: "Expansion Vessels",

      }
},
 
{
      id: "35EXP",
        data: {
          desc: "TEMPEST 35LTR EXPANSION VESSEL 400LTR",
	    code: "35EXP",
          costexvat: "72",
 	    costincvat: "86.4",
category: "Expansion Vessels",

      }
},
 
{
      id: "50EXP",
        data: {
          desc: "TEMPEST 50LTR EXPANSION VESSEL 500LTR",
	    code: "50EXP",
          costexvat: "105",
 	    costincvat: "126",
category: "Expansion Vessels",

      }
},
 
{
      id: "EXBRACK",
        data: {
          desc: "EXPANSION VESSEL BRACKET",
	    code: "EXBRACK",
          costexvat: "9",
 	    costincvat: "10.8",
category: "Expansion Vessels",

      }
},
 
{
      id: "EXBRACK-OLD",
        data: {
          desc: "EXPANSION VESSEL BRACKET-Jubilee Clip",
	    code: "EXBRACK-OLD",
          costexvat: "14",
 	    costincvat: "16.8",
category: "Expansion Vessels",

      }
},
 
{
      id: "COMLID18",
        data: {
          desc: "PLASTIC COMBI LID",
	    code: "COMLID18",
          costexvat: "10",
 	    costincvat: "12",
category: "Misc",

      }
},
 
{
      id: "FLOATSWITCH",
        data: {
          desc: "FLOAT SWITCH",
	    code: "FLOATSWITCH",
          costexvat: "28",
 	    costincvat: "33.6",
category: "Thermal Stores",

      }
},
 
{
      id: "3MOVERFILL",
        data: {
          desc: "OVERFILL",
	    code: "3MOVERFILL",
          costexvat: "100",
 	    costincvat: "120",
category: "Thermal Stores",

      }
},
 
{
      id: "3MWSAFE",
        data: {
          desc: "WATERSAFE",
	    code: "3MWSAFE",
          costexvat: "100",
 	    costincvat: "120",
category: "Thermal Stores",

      }
},
 
{
      id: "3MWSENSOR",
        data: {
          desc: "WATERSAFE SENSOR (PAD & LEAD)",
	    code: "3MWSENSOR",
          costexvat: "58",
 	    costincvat: "69.6",
category: "Thermal Stores",

      }
},
 
{
      id: "3MAUTOFILL",
        data: {
          desc: "AUTOFILL",
	    code: "3MAUTOFILL",
          costexvat: "100",
 	    costincvat: "120",
category: "Thermal Stores",

      }
},
 
{
      id: "SOLENOID",
        data: {
          desc: "SOLENOID",
	    code: "SOLENOID",
          costexvat: "28",
 	    costincvat: "33.6",
category: "Thermal Stores",

      }
},
 
{
      id: "WILOSTARZNOVAA",
        data: {
          desc: "Wilo De-stratification pump",
	    code: "WILOSTARZNOVAA",
          costexvat: "N-A",
 	    costincvat: "N-A",
category: "Pumps",

      }
},
 
{
      id: "SCALEM22",
        data: {
          desc: "22mm scalemaster (lime fighter)",
	    code: "SCALEM22",
          costexvat: "50",
 	    costincvat: "60",
category: "Valves",

      }
},
 
{
      id: "ALTSINGLECHECKVALV",
        data: {
          desc: "22mm Single Check Valve (Non Return Valve)",
	    code: "ALTSINGLECHECKVALV",
          costexvat: "9",
 	    costincvat: "10.8",
category: "Valves",

      }
},
 
{
      id: "ALTDBLCHECKVALV",
        data: {
          desc: "15mm Check Valve",
	    code: "ALTDBLCHECKVALV",
          costexvat: "15",
 	    costincvat: "18",
category: "Valves",

      }
},
 
{
      id: "GATEVALVE22",
        data: {
          desc: "22mm Gate Valve",
	    code: "GATEVALVE22",
          costexvat: "10",
 	    costincvat: "12",
category: "Valves",

      }
},
 
{
      id: "CONORING13-4",
        data: {
          desc: "1 3-4 INCH Rubber immersion O Ring",
	    code: "CONORING13-4",
          costexvat: "2",
 	    costincvat: "2.4",
category: "Immersion Heaters",

      }
},
 
{
      id: "SM1FASTCLEANSE",
        data: {
          desc: "SM1 Inhibitor (for manual fill)",
	    code: "SM1FASTCLEANSE",
          costexvat: "15",
 	    costincvat: "18",
category: "Thermal Stores",

      }
},
 
{
      id: "ALTPRV3BARADJ",
        data: {
          desc: "Adjustable PRV",
	    code: "ALTPRV3BARADJ",
          costexvat: "35",
 	    costincvat: "42",
category: "Pressure Reducing Valves",

      }
},
 
{
      id: "ALTFLEX3-4",
        data: {
          desc: "Flexi Hose for Stainless-Expansion Vessels",
	    code: "ALTFLEX3-4",
          costexvat: "12",
 	    costincvat: "14.4",
category: "Misc",

      }
},
 
{
      id: "FLEX15",
        data: {
          desc: "Flexi Hose for Tristors",
	    code: "FLEX15",
          costexvat: "12",
 	    costincvat: "14.4",
category: "Thermal Stores",

      }
},
 
{
      id: "WASHTAPS",
        data: {
          desc: "Washing Machine Valve (Pair Red & Blue)",
	    code: "WASHTAPS",
          costexvat: "12",
 	    costincvat: "14.4",
category: "Valves",

      }
},
 
{
      id: "ALTECISOVALVE",
        data: {
          desc: "Ballafix",
	    code: "ALTECISOVALVE",
          costexvat: "8",
 	    costincvat: "9.6",
category: "Valves",

      }
},
 
{
      id: "ALTAUTOAIRVENT",
        data: {
          desc: "Auto Air Vent",
	    code: "ALTAUTOAIRVENT",
          costexvat: "6",
 	    costincvat: "7.2",
category: "Safety Devices",

      }
},
 
{
      id: "ALTAUTOBYPASS",
        data: {
          desc: "Auto Bypass",
	    code: "ALTAUTOBYPASS",
          costexvat: "5.5",
 	    costincvat: "6.6",
category: "Valves",

      }
},
 
{
      id: "DOC",
        data: {
          desc: "Drain off",
	    code: "DOC",
          costexvat: "2.7",
 	    costincvat: "3.24",
category: "Safety Devices",

      }
},
 
{
      id: "CON3-4BLANKINGCAP",
        data: {
          desc: "3-4 Inch Blanking Cap",
	    code: "CON3-4BLANKINGCAP",
          costexvat: "2.2",
 	    costincvat: "2.64",
category: "Misc",

      }
},
 
{
      id: "22COMPBLANK",
        data: {
          desc: "22mm Compression Blank Disc",
	    code: "22COMPBLANK",
          costexvat: "2.2",
 	    costincvat: "2.64",
category: "Fittings",

      }
},
 
{
      id: "15TECELB",
        data: {
          desc: "15mm Tectite Elbows",
	    code: "15TECELB",
          costexvat: "4.5",
 	    costincvat: "5.4",
category: "Fittings",

      }
},
 
{
      id: "22TECELB",
        data: {
          desc: "22mm Tectite Elbows",
	    code: "22TECELB",
          costexvat: "4.5",
 	    costincvat: "5.4",
category: "Fittings",

      }
},
 
{
      id: "15TECSTR",
        data: {
          desc: "15mm Tectite Straights",
	    code: "15TECSTR",
          costexvat: "4.5",
 	    costincvat: "5.4",
category: "Fittings",

      }
},
 
{
      id: "22TECSTR",
        data: {
          desc: "22mm Tectite Straights",
	    code: "22TECSTR",
          costexvat: "4.5",
 	    costincvat: "5.4",
category: "Fittings",

      }
},
 
{
      id: "15TECTEE",
        data: {
          desc: "15mm Tectite Tees",
	    code: "15TECTEE",
          costexvat: "4.5",
 	    costincvat: "5.4",
category: "Fittings",

      }
},
 
{
      id: "22TECTEE",
        data: {
          desc: "22mm Tectite Tees",
	    code: "22TECTEE",
          costexvat: "4.5",
 	    costincvat: "5.4",
category: "Fittings",

      }
},
 
{
      id: "222215TECTEE",
        data: {
          desc: "22mm-15mm-22mm Tee",
	    code: "222215TECTEE",
          costexvat: "4.5",
 	    costincvat: "5.4",
category: "Fittings",

      }
},
 
{
      id: "15x15STRAIGHT",
        data: {
          desc: "15mm Compression Straights-Couplers",
	    code: "15x15STRAIGHT",
          costexvat: "4.5",
 	    costincvat: "5.4",
category: "Fittings",

      }
},
 
{
      id: "22x22STRAIGHT",
        data: {
          desc: "22mm Compression Straights-Couplers",
	    code: "22x22STRAIGHT",
          costexvat: "4.5",
 	    costincvat: "5.4",
category: "Fittings",

      }
},
 
{
      id: "15x15STR",
        data: {
          desc: "15mm Straight Coupler",
	    code: "15x15STR",
          costexvat: "4.5",
 	    costincvat: "5.4",
category: "Fittings",

      }
},
 
{
      id: "22x3-4TECSTR",
        data: {
          desc: "22 x 3-4 Pro Male Coupler",
	    code: "22x3-4TECSTR",
          costexvat: "4.5",
 	    costincvat: "5.4",
category: "Fittings",

      }
},
 
{
      id: "22X3-4TECELB",
        data: {
          desc: "22 x 3-4 Pro Male Elbow",
	    code: "22X3-4TECELB",
          costexvat: "4.5",
 	    costincvat: "5.4",
category: "Fittings",

      }
},
 
{
      id: "22X3-4FICOUP",
        data: {
          desc: "22 x 3-4 Couplers Female",
	    code: "22X3-4FICOUP",
          costexvat: "4.5",
 	    costincvat: "5.4",
category: "Fittings",

      }
},
 
{
      id: "",
        data: {
          desc: "Tectite Couplers VARIOUS SIZES",
	    code: "",
          costexvat: "4.5",
 	    costincvat: "5.4",
category: "Fittings",

      }
},
 
{
      id: "15MMHARD",
        data: {
          desc: "1m of 15mm copper pipe",
	    code: "15MMHARD",
          costexvat: "12",
 	    costincvat: "14.4",
category: "Fittings",

      }
},
 
{
      id: "22MMHARD",
        data: {
          desc: "1m of 22mm copper pipe",
	    code: "22MMHARD",
          costexvat: "15",
 	    costincvat: "18",
category: "Fittings",

      }
},
 
{
      id: "CONPTFE",
        data: {
          desc: "PTFE Tape",
	    code: "CONPTFE",
          costexvat: "0",
 	    costincvat: "0",
category: "Misc",

      }
},
 
{
      id: "IMMBRASSAPT",
        data: {
          desc: "Brass Immersion Adapter",
	    code: "IMMBRASSAPT",
          costexvat: "32",
 	    costincvat: "38.4",
category: "Immersion Heaters",

      }
},
 
{
      id: "CONGLUE",
        data: {
          desc: "Brass Immersion Adapter Glue",
	    code: "CONGLUE",
          costexvat: "0",
 	    costincvat: "0",
category: "Immersion Heaters",

      }
},
 
{
      id: "CONCABLE3CORE-1.5",
        data: {
          desc: "1m of 1.5mm 3 core heat resistant flex-cable",
	    code: "CONCABLE3CORE-1.5",
          costexvat: "3.5",
 	    costincvat: "4.2",
category: "Misc",

      }
},
 
{
      id: "15x1-2COMP",
        data: {
          desc: "15x1-2 INCH comp CxMI Str",
	    code: "15x1-2COMP",
          costexvat: "3.5",
 	    costincvat: "4.2",
category: "Fittings",

      }
},
 
{
      id: "15COMPSTOP",
        data: {
          desc: "15mm compact comp stop end",
	    code: "15COMPSTOP",
          costexvat: "6.8",
 	    costincvat: "8.16",
category: "Fittings",

      }
},
 
{
      id: "6410",
        data: {
          desc: "Drain Down",
	    code: "6410",
          costexvat: "25",
 	    costincvat: "30",
category: "Fittings",

      }
},
 
{
      id: "LITGUIDETRISTOR",
        data: {
          desc: "Tristor Guide",
	    code: "LITGUIDETRISTOR",
          costexvat: "0",
 	    costincvat: "0",
category: "Booklets",

      }
},
 
{
      id: "LITGUIDETORNTEMP",
        data: {
          desc: "Tempest-Tornado Guide",
	    code: "LITGUIDETORNTEMP",
          costexvat: "0",
 	    costincvat: "0",
category: "Booklets",

      }
},
 
{
      id: "LITENGREPORT",
        data: {
          desc: "Service Book",
	    code: "LITENGREPORT",
          costexvat: "0",
 	    costincvat: "0",
category: "Booklets",

      }
},
 


     
    // Add more objects to this array for more documents to upload
  ];

  const addDocument = async (docId, data) => {
    try {
      await setDoc(doc(db, "partsPrices", docId), data);
      console.log(`Document ${docId} uploaded successfully`);
    } catch (error) {
      console.error(`Error uploading document ${docId}:`, error);
    }
  };

  const handleUploadClick = () => {
    setIsUploading(true);
    setCountdown(5); // Starting countdown from 10

    dataToUpload.forEach(doc => {
      addDocument(doc.id, doc.data);
    });
  };

  useEffect(() => {
    let interval = null;

    if (isUploading && countdown > 0) {
      interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsUploading(false);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isUploading, countdown]);

  return (
    <div className="center-container">
      <button className="rainbow-button" onClick={handleUploadClick} disabled={isUploading}>
        {isUploading ? 'Uploading...' : 'Upload to Firestore'}
      </button>
      {isUploading && <div className="countdown">Countdown: {countdown}</div>}
    </div>
  );
};
  

export default MassUpload; // This should match the name of your component
