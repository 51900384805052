// SketchDashboard.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import FavouriteButton from './FavouriteButton';

import uploadIcon from './assets/upload.png';
import emailIcon from './assets/mail.png';
import importIcon from './assets/import.png';
import deleteIcon from './assets/delete.png';


const AdminDashboard = () => {
  const navigate = useNavigate();

  // Navigation Handlers
  const handleNavigation = (route) => {
    navigate(route);
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center p-6">
      {/* Favorite Button */}
      <FavouriteButton pageUrl={window.location.pathname} />

      {/* Page Title */}
      <h1 className="text-4xl font-semibold text-gray-800 mb-8">Admin Dashboard</h1>

      {/* Grid Container for Tiles */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-5xl">
        <Tile title="Mass Upload" icon={uploadIcon} onClick={() => handleNavigation("/MassUpload")} />
        <Tile title="Mass Download" icon={uploadIcon} onClick={() => handleNavigation("/MassDownload")} />
        <Tile title="Delete Manufacture Data" icon={deleteIcon} onClick={() => handleNavigation("/DeleteManufactureData")} />
        <Tile title="Email Test" icon={emailIcon} onClick={() => handleNavigation("/EmailTestComponent")} />
        <Tile title="Warehouse Delete" icon={deleteIcon} onClick={() => handleNavigation("/WarehouseDelete")} />
        <Tile title="Duplicate Firestore Form" icon={deleteIcon} onClick={() => handleNavigation("/DuplicateFirestoreForm")} />
        <Tile title="Duplicate Warehouse" icon={deleteIcon} onClick={() => handleNavigation("/DuplicateWarehouse")} />
        <Tile title="Update Stock" icon={deleteIcon} onClick={() => handleNavigation("/UpdateStock")} />
        <Tile title="Add Product Form" icon={importIcon} onClick={() => handleNavigation("/AddProductForm")} />
        <Tile title="Product Upload" icon={importIcon} onClick={() => handleNavigation("/ProductUpload")} />
        <Tile title="SMS Test" icon={emailIcon} onClick={() => handleNavigation("/SMSTest")} />
      </div>
    </div>
  );
};

// ✅ Reusable Tile Component
const Tile = ({ title, icon, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="group bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition cursor-pointer transform hover:-translate-y-1 flex flex-col items-center justify-center text-center w-full h-48 border border-gray-200"
    >
      <div className="flex justify-center items-center w-20 h-20 bg-gray-200 rounded-full group-hover:bg-primary transition">
        <img src={icon} alt={title} className="h-10 w-10 object-contain" />
      </div>
      <p className="mt-4 text-lg font-semibold text-gray-800 group-hover:text-primary transition">{title}</p>
    </div>
  );
};

export default AdminDashboard;
