import React, { useState } from "react";
import { getFirestore, collection, getDocs } from "firebase/firestore";

const SalesRepSearch = () => {
  const [postCode, setPostCode] = useState("");
  const [salesReps, setSalesReps] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const firestore = getFirestore();

  // Extracts only the first letters before any numbers
  const extractOutcode = (postcode) => {
    if (!postcode) return "";

    const cleanedPostcode = postcode.toUpperCase().replace(/\s+/g, "");
    const match = cleanedPostcode.match(/^([A-Z]+)\d/);

    return match ? match[1] : cleanedPostcode; // Keep only letters before first digit
  };

  const fetchSalesReps = async () => {
    if (!postCode.trim()) return;

    setLoading(true);
    setError("");
    setSalesReps([]);

    try {
      const salesRepsCollection = collection(firestore, "sales_rep");
      const snapshot = await getDocs(salesRepsCollection);

      const searchOutcode = extractOutcode(postCode); // Extract correct outcode

      const matchedReps = snapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((rep) => rep.postcodes.includes(searchOutcode));

      if (matchedReps.length === 0) {
        setError("No sales rep found for this postcode.");
      } else {
        setSalesReps(matchedReps);
      }
    } catch (err) {
      console.error("Error fetching sales reps:", err);
      setError("An error occurred while searching.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-4 text-[#606bbf]">Find Your Sales Rep</h2>
      <input
        type="text"
        placeholder="Enter postcode (e.g., TF3 1SP or N1)"
        value={postCode}
        onChange={(e) => setPostCode(e.target.value)}
        className="w-full p-3 border rounded-md focus:ring-2 focus:ring-[#606bbf] focus:outline-none"
      />
      <button
        onClick={fetchSalesReps}
        className="w-full mt-3 px-4 py-2 bg-[#606bbf] hover:bg-[#4c54a1] text-white rounded-md font-semibold"
      >
        Search
      </button>

      {loading && <p className="mt-3 text-gray-500">Searching...</p>}
      {error && <p className="mt-3 text-red-500">{error}</p>}

      {salesReps.length > 0 && (
        <div className="mt-5">
          <h3 className="text-xl font-semibold">Results:</h3>
          {salesReps.map((rep) => (
            <div key={rep.id} className="mt-3 p-4 border rounded-md bg-gray-50">
              <p><strong>Name:</strong> {rep.name}</p>
              <p><strong>Email:</strong> <a href={`mailto:${rep.email}`} className="text-blue-600">{rep.email}</a></p>
              <p><strong>Phone:</strong> {rep.phone}</p>
              <p><strong>Postcodes Covered:</strong> {rep.postcodes.join(", ")}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SalesRepSearch;
