import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, addDoc } from 'firebase/firestore';
import Modal from './Modal'; // Assuming Modal is in the same directory
import FavouriteButton from './FavouriteButton';

const BarcodeRequestsView = () => {
  const [barcodeRequests, setBarcodeRequests] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({ productCode: '', description: '' });

  useEffect(() => {
    const fetchData = async () => {
      const firestore = getFirestore();
      const snapshot = await getDocs(collection(firestore, "barcodeRequest"));
      const requests = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setBarcodeRequests(requests);
    };

    fetchData();
  }, []);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setFormData({ productCode: '', description: '' }); // Reset form data on close
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const firestore = getFirestore();
    try {
      await addDoc(collection(firestore, "barcodeRequest"), {
        productCode: formData.productCode,
        description: formData.description,
        barcodeNumber: 'Generating...', // Placeholder until real barcode logic is implemented
        completed: false // Initial state for completion
      });
      console.log('New request added!');
      // Fetch updated data after submission
      const snapshot = await getDocs(collection(firestore, "barcodeRequest"));
      const requests = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setBarcodeRequests(requests);
      handleModalClose(); // Close modal after successful submission
    } catch (error) {
      console.error('Error adding new request:', error);
    }
  };

  return (
    <>
      <FavouriteButton pageUrl={window.location.pathname} />
      <h1>Missing Barcode Request Form</h1>
      <button onClick={handleOpenModal}>Add New Request</button>
      <table className="warehouse-table">
        <thead>
          <tr>
            <th>Description</th>
            <th>Product Code</th>
            <th>Barcode Number</th>
            <th>Completed</th>
          </tr>
        </thead>
        <tbody>
          {barcodeRequests.map(request => (
            <tr key={request.id}>
              <td>{request.description}</td>
              <td>{request.productCode}</td>
              <td>{request.barcodeNumber}</td>
              <td>{request.completed ? 'Yes' : 'No'}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {showModal && (
        <Modal onClose={handleModalClose}>
          <form onSubmit={handleSubmit}>
            <label>
              Product Code:
              <input type="text" name="productCode" value={formData.productCode} onChange={handleChange} />
            </label>
            <label>
              Description:
              <input type="text" name="description" value={formData.description} onChange={handleChange} />
            </label>
            <button type="submit">Submit</button>
          </form>
        </Modal>
      )}
    </>
  );
};

export default BarcodeRequestsView;
