import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  getFirestore,
  doc,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import * as XLSX from "xlsx";

const ITEMS_PER_PAGE = 50;

const StockComparison = () => {
  const [comparisonData, setComparisonData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "ascending" });
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const firestore = getFirestore();

  useEffect(() => {
    fetchStockData();
  }, []);

  const fetchAllProductCodes = async () => {
    const snapshot = await getDocs(collection(firestore, "cyl_info"));
    const map = {};
    snapshot.docs.forEach(doc => {
      const data = doc.data();
      if (data?.productCode) map[doc.id] = data.productCode;
    });
    return map;
  };

  const fetchStockData = async () => {
    setLoading(true);
    const warehouseSnap = await getDocs(collection(firestore, "cylinders_warehouse"));
    const stocktakeSnap = await getDocs(collection(firestore, "stocktake_scanned"));

    const warehouseData = warehouseSnap.docs.map((docSnap) => ({
      ...docSnap.data(),
      docId: docSnap.id,
      source: "warehouse",
    }));

    const stocktakeData = stocktakeSnap.docs.map((docSnap) => ({
      ...docSnap.data(),
      docId: docSnap.id,
      source: "stocktake",
    }));

    const productCodeMap = await fetchAllProductCodes();

    const groupByCompoundKey = (arr) => {
      const map = {};
      for (const item of arr) {
        const key = `${item.SerialNumber}-${item.ProductId}`;
        if (!map[key]) map[key] = [];
        map[key].push(item);
      }
      return map;
    };

    const warehouseMap = groupByCompoundKey(warehouseData);
    const stocktakeMap = groupByCompoundKey(stocktakeData);

    const allKeys = new Set([...Object.keys(warehouseMap), ...Object.keys(stocktakeMap)]);
    const results = [];

    for (let key of allKeys) {
      const warehouseItems = warehouseMap[key] || [];
      const stocktakeItems = stocktakeMap[key] || [];
      const matchCount = Math.min(warehouseItems.length, stocktakeItems.length);
      const [serial, productId] = key.split("-");
      const productCode = productCodeMap[productId] || "N/A";

      for (let i = 0; i < matchCount; i++) {
        results.push({
          id: `${key}-MATCH-${i}`,
          SerialNumber: serial,
          ProductId: productId,
          ProductCode: productCode,
          Location: stocktakeItems[i]?.LocationId || warehouseItems[i]?.LocationId || "Unknown",
          InWarehouse: true,
          InStockTake: true,
        });
      }

      for (let i = matchCount; i < warehouseItems.length; i++) {
        results.push({
          id: `${key}-WAREHOUSE-${i}`,
          SerialNumber: serial,
          ProductId: productId,
          ProductCode: productCode,
          Location: warehouseItems[i].LocationId || "Unknown",
          InWarehouse: true,
          InStockTake: false,
          docId: warehouseItems[i].docId,
        });
      }

      for (let i = matchCount; i < stocktakeItems.length; i++) {
        results.push({
          id: `${key}-STOCKTAKE-${i}`,
          SerialNumber: serial,
          ProductId: productId,
          ProductCode: productCode,
          Location: stocktakeItems[i].LocationId || "Unknown",
          InWarehouse: false,
          InStockTake: true,
        });
      }
    }

    setComparisonData(results);
    setLoading(false);
  };

  const sortData = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    setComparisonData((prevData) => {
      return [...prevData].sort((a, b) => {
        if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
        if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
        return 0;
      });
    });
  };

  const getRowClass = (item) => {
    if (item.InWarehouse && item.InStockTake) return "bg-green-200";
    if (item.InWarehouse && !item.InStockTake) return "bg-red-200";
    if (!item.InWarehouse && item.InStockTake) return "bg-yellow-200";
    return "";
  };

  const filteredData = comparisonData.filter((item) => {
    const q = searchQuery.toLowerCase();
    return (
      item.SerialNumber?.toLowerCase().includes(q) ||
      item.ProductCode?.toLowerCase().includes(q) ||
      item.Location?.toLowerCase().includes(q)
    );
  });

  const paginatedData = filteredData.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(comparisonData.map((item) => ({
      SerialNumber: item.SerialNumber,
      ProductCode: item.ProductCode,
      Location: item.Location,
      InWarehouse: item.InWarehouse ? "Yes" : "",
      InStockTake: item.InStockTake ? "Yes" : "",
    })));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Stock Comparison");
    XLSX.writeFile(workbook, "Stock_Comparison.xlsx");
  };

  const confirmNewStock = async () => {
    const newEntries = comparisonData.filter((item) => item.InStockTake && !item.InWarehouse);
    const toArchive = comparisonData.filter((item) => item.InWarehouse && !item.InStockTake);

    for (const item of newEntries) {
      await setDoc(doc(firestore, "cylinders_warehouse", crypto.randomUUID()), {
        SerialNumber: item.SerialNumber,
        ProductId: item.ProductId,
        LocationId: item.Location,
        BookedInTime: new Date(),
      });
    }

    for (const item of toArchive) {
      if (item.docId) {
        await deleteDoc(doc(firestore, "cylinders_warehouse", item.docId));
      }
    }

    alert("New stock confirmed and missing stock archived.");
    fetchStockData();
  };

  return (
    <div className="p-6 max-w-7xl mx-auto">
      <h1 className="text-3xl font-bold mb-6">Stock Take Comparison</h1>

      <div className="flex flex-wrap items-center gap-4 mb-4">
        <input
          type="text"
          placeholder="Search serial, product, or location..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="p-2 w-full max-w-md border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          onClick={exportToExcel}
          className="bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded"
        >
          Download Excel
        </button>
        <button
          onClick={confirmNewStock}
          className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded"
        >
          Confirm New Stock
        </button>
      </div>

      {loading ? (
        <p className="text-center text-gray-500">Loading stock data...</p>
      ) : (
        <>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-300 rounded">
              <thead className="bg-gray-100">
                <tr>
                  {["SerialNumber", "ProductCode", "Location", "InWarehouse", "InStockTake"].map((key) => (
                    <th
                      key={key}
                      className="px-4 py-2 text-left cursor-pointer text-sm font-semibold text-gray-700 hover:bg-gray-200"
                      onClick={() => sortData(key)}
                    >
                      {key === "InWarehouse"
                        ? "Warehouse"
                        : key === "InStockTake"
                        ? "Stock Take"
                        : key.replace(/([A-Z])/g, " $1")}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((item) => (
                  <tr key={item.id} className={`${getRowClass(item)} border-t`}>
                    <td className="px-4 py-2">{item.SerialNumber}</td>
                    <td className="px-4 py-2">{item.ProductCode}</td>
                    <td className="px-4 py-2">{item.Location}</td>
                    <td className="px-4 py-2 text-center">
                      <input type="checkbox" readOnly checked={item.InWarehouse} />
                    </td>
                    <td className="px-4 py-2 text-center">
                      <input type="checkbox" readOnly checked={item.InStockTake} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination Controls */}
          <div className="flex justify-between items-center mt-4">
            <button
              disabled={currentPage === 1}
              onClick={() => setCurrentPage((prev) => prev - 1)}
              className="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300"
            >
              Previous
            </button>
            <span className="text-gray-700">
              Page {currentPage} of {Math.ceil(filteredData.length / ITEMS_PER_PAGE)}
            </span>
            <button
              disabled={currentPage * ITEMS_PER_PAGE >= filteredData.length}
              onClick={() => setCurrentPage((prev) => prev + 1)}
              className="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300"
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default StockComparison;
