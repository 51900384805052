import React, { useState, useEffect } from "react";
import { getFirestore, collection, getDocs, doc, getDoc } from "firebase/firestore";

const DespatchSummary = () => {
  const firestore = getFirestore();
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState([]);

  useEffect(() => {
    const fetchSummary = async () => {
      setLoading(true);

      try {
        const despatchReportsRef = collection(firestore, "despatchreports");
        const reportsSnap = await getDocs(despatchReportsRef);

        let dailySummary = {};

        for (let report of reportsSnap.docs) {
          const reportData = report.data();
          const reportDate = new Date(reportData.createdAt.seconds * 1000).toDateString();
          const detailsRef = doc(firestore, "despatchreportsdetailed", report.id);
          const detailsSnap = await getDoc(detailsRef);

          if (detailsSnap.exists()) {
            const itemCount = detailsSnap.data().items.length || 0;

            if (!dailySummary[reportDate]) {
              dailySummary[reportDate] = { totalReports: 0, totalItems: 0 };
            }

            dailySummary[reportDate].totalReports += 1;
            dailySummary[reportDate].totalItems += itemCount;
          }
        }

        // Convert object to sorted array for display
        const summaryArray = Object.keys(dailySummary).map((date) => ({
          date,
          ...dailySummary[date],
        })).sort((a, b) => new Date(b.date) - new Date(a.date));

        setSummary(summaryArray);
      } catch (error) {
        console.error("Error fetching despatch summary:", error);
      }

      setLoading(false);
    };

    fetchSummary();
  }, []);

  return (
    <div className="min-h-screen flex flex-col items-center p-6">
      <h1 className="text-2xl font-semibold mb-4 text-[#606bbf]">Despatch Summary</h1>

      {loading ? (
        <p className="text-blue-500">Loading summary...</p>
      ) : summary.length > 0 ? (
        <div className="overflow-x-auto w-full max-w-3xl">
          <table className="min-w-full border border-gray-200 text-sm shadow-lg rounded-lg">
            <thead className="bg-[#606bbf] text-white">
              <tr>
                <th className="px-4 py-3 border-b text-left">Date</th>
                <th className="px-4 py-3 border-b text-center">Total Reports</th>
                <th className="px-4 py-3 border-b text-center">Total Items</th>
              </tr>
            </thead>
            <tbody>
              {summary.map((row, index) => (
                <tr
                  key={index}
                  className="text-center bg-white hover:bg-gray-100 transition"
                >
                  <td className="px-4 py-3 border-b text-left">{row.date}</td>
                  <td className="px-4 py-3 border-b">{row.totalReports}</td>
                  <td className="px-4 py-3 border-b font-bold">{row.totalItems}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-gray-500">No data available.</p>
      )}
    </div>
  );
};

export default DespatchSummary;
