const UserCard = ({ user }) => {
    return (
      <div className="bg-white p-4 rounded-xl shadow flex flex-col justify-between">
        <div>
          <h3 className="text-lg font-semibold text-gray-800">{user.name}</h3>
          <p className="text-sm text-gray-500">{user.uid}</p>
        </div>
        <div className="mt-2">
          <span
            className={`inline-block px-3 py-1 text-sm font-semibold rounded-full text-white ${
              user.in_status ? "bg-green-500" : "bg-red-500"
            }`}
          >
            {user.in_status ? "IN" : "OUT"}
          </span>
        </div>
      </div>
    );
  };
  
  export default UserCard;
  