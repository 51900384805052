import React, { useState } from 'react';
import { collection, addDoc, getFirestore, Timestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import Select from 'react-select';
import Modal from 'react-modal';

const ReturnsEntry = () => {
    const [formState, setFormState] = useState({
        returnsNoteNumber: '',
        serialNumber: '',
        sretNumber: '',
        cylinderModel: '',
        capacity: '',
        dateOfManufacture: '',
        reasonForReturn: '',
        faultFound: '',
        images: [],
        operators: {
            welded: '',
            airTest: '',
            hotTested: '',
            tested: ''
        },
    });

    // State for managing parts
    const [partsState, setPartsState] = useState({
        noPartsReturned: false,
        allPartsReturned: false,
        partialReturn: false,
        partialReturnItems: {
            inletGroup: false,
            tundish: false,
            expVessel: false,
            portValve: false,
            dualStat: false,
            immersion: false
        }
    });

    // State for modal controls and checks
    const [cylinderCheckModalIsOpen, setCylinderCheckModalIsOpen] = useState(false);
    const [leaksModalIsOpen, setLeaksModalIsOpen] = useState(false);
    const [cylinderCheck, setCylinderCheck] = useState({
        cylinderInstalled: false,
        damageToCase: false,
        damageToPackaging: false,
        damageToBosses: false,
        stripAndTest: false
    });
    const [leakChecks, setLeakChecks] = useState({
        verticalSeam: false,
        topSeam: false,
        bottomSeam: false,
        hotDrawOff: false,
        secondaryRet: false,
        coldFeed: false,
        statPocket: false,
        coilBoss: false,
        coilBody: false,
        bodySheet: false,
        tAndPRelief: false,
        immersionBoss: false,
        other: false,
        otherComments: ""
    });

    const handleCheckboxChange = (modal, field) => (e) => {
        if (modal === 'cylinderCheck') {
            setCylinderCheck(prev => ({ ...prev, [field]: e.target.checked }));
        } else if (modal === 'leakChecks') {
            setLeakChecks(prev => ({ ...prev, [field]: e.target.checked }));
        }
    };
    
    const handleInputChange = (modal, field) => (e) => {
        if (modal === 'leakChecks') {
            setLeakChecks(prev => ({ ...prev, [field]: e.target.value }));
        }
    };

    const [operatorsModalIsOpen, setOperatorsModalIsOpen] = useState(false);
    const [partsModalIsOpen, setPartsModalIsOpen] = useState(false);

    const firestore = getFirestore();
    const storage = getStorage();

    const handleChange = (field, value) => {
        if (field === "images") {
            const newFiles = Array.from(value.target.files).map(file => ({
                file,
                url: URL.createObjectURL(file)
            }));
            setFormState(prev => ({ ...prev, images: [...prev.images, ...newFiles] }));
        } else if (["returnsNoteNumber", "serialNumber", "sretNumber"].includes(field)) {
            // Only accept numeric input for these specific fields
            if (/^\d*$/.test(value)) {
                setFormState(prev => ({ ...prev, [field]: value }));
            }
        } else if (field.includes(".")) {
            // Split the field name to handle nested state updates
            const [mainField, subField] = field.split(".");
            setFormState(prev => ({ ...prev, [mainField]: { ...prev[mainField], [subField]: value } }));
        } else {
            setFormState(prev => ({ ...prev, [field]: value }));
        }
    };
    
    
    

    // Function to remove a selected image
    const removeImage = index => {
        setFormState(prev => ({
            ...prev,
            images: prev.images.filter((_, i) => i !== index)
        }));
    };

    // Function to handle part selection changes
    const handlePartsChange = (field) => {
        setPartsState(prev => {
            const newState = {
                ...prev,
                noPartsReturned: field === 'noPartsReturned' ? !prev.noPartsReturned : false,
                allPartsReturned: field === 'allPartsReturned' ? !prev.allPartsReturned : false,
                partialReturn: field === 'partialReturn' ? !prev.partialReturn : false,
                partialReturnItems: field === 'partialReturn' && !prev.partialReturn ? prev.partialReturnItems : {
                    inletGroup: false,
                    tundish: false,
                    expVessel: false,
                    portValve: false,
                    dualStat: false,
                    immersion: false
                }
            };
    
            if (field === 'noPartsReturned' && newState.noPartsReturned) {
                newState.allPartsReturned = false;
                newState.partialReturn = false;
            } else if (field === 'allPartsReturned' && newState.allPartsReturned) {
                newState.noPartsReturned = false;
                newState.partialReturn = false;
            } else if (field === 'partialReturn' && newState.partialReturn) {
                newState.noPartsReturned = false;
                newState.allPartsReturned = false;
            }
    
            return newState;
        });
    };

    const uploadImages = async () => {
        const urls = [];
        for (const image of formState.images) {
            const file = image.file;
            const fileName = `${formState.sretNumber}-${Date.now()}-${file.name}`;
            const storageRef = ref(storage, `returns/${fileName}`);
            const uploadTask = uploadBytesResumable(storageRef, file);
            const downloadURL = await new Promise((resolve, reject) => {
                uploadTask.on('state_changed', 
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log('Upload is ' + progress + '% done');
                    }, 
                    error => reject(error), 
                    () => getDownloadURL(uploadTask.snapshot.ref).then(resolve)
                );
            });
            urls.push(downloadURL);
        }
        return urls;
    };
    
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const imageUrls = await uploadImages();
            const { images, dateOfManufacture, ...restOfFormState } = formState; // Destructure formState to exclude images and dateOfManufacture
            
            // Prepare the returnData object with only defined fields
            const returnData = {
                ...restOfFormState,
                imageUrls,  // Store URLs of uploaded images
                ReturnDate: Timestamp.now(),
                Parts: partsState,
                CylinderCheck: cylinderCheck,
                LeakChecks: leakChecks
            };
    
            // Include dateOfManufacture only if it is valid and defined
            if (dateOfManufacture) {
                const date = new Date(dateOfManufacture);
                if (!isNaN(date.getTime())) {
                    returnData.DateOfManufacture = date;
                } else {
                    console.error("Invalid dateOfManufacture value");
                }
            }
    
            await addDoc(collection(firestore, 'returns'), returnData);
            console.log("Return report and images added to Firestore!");
    
            // Resetting the form after successful submission
            setFormState({
                returnsNoteNumber: '',
                serialNumber: '',
                sretNumber: '',
                cylinderModel: '',
                capacity: '',
                dateOfManufacture: '',
                reasonForReturn: '',
                faultFound: '',
                images: [],
                operators: {
                    welded: '',
                    airTest: '',
                    hotTested: '',
                    tested: ''
                },
            });
            setPartsState({
                noPartsReturned: false,
                allPartsReturned: false,
                partialReturn: false,
                partialReturnItems: {
                    inletGroup: false,
                    tundish: false,
                    expVessel: false,
                    portValve: false,
                    dualStat: false,
                    immersion: false
                }
            });
            setCylinderCheck({
                cylinderInstalled: false,
                damageToCase: false,
                damageToPackaging: false,
                damageToBosses: false,
                stripAndTest: false
            });
            setLeakChecks({
                verticalSeam: false,
                topSeam: false,
                bottomSeam: false,
                hotDrawOff: false,
                secondaryRet: false,
                coldFeed: false,
                statPocket: false,
                coilBoss: false,
                coilBody: false,
                bodySheet: false,
                tAndPRelief: false,
                immersionBoss: false,
                other: false,
                otherComments: ""
            });
        } catch (error) {
            console.error("Error adding return report and images to Firestore: ", error);
        }
    };
    
    
    

    

    // Hardcoded options for the dropdowns
    const reasonForReturnOptions = [
      { value: 'IMPLODED', label: 'IMPLODED - Cylinder has Imploded' },
      { value: 'LABELLED', label: 'LABELLED - Labelled Incorrectly' },
      { value: 'NAVDUPLI', label: 'NAVDUPLI - Duplicated on NAV' },
      { value: 'OLDSPEC', label: 'OLDSPEC - Old Spec Cylinder' },
      { value: 'PACKAGE', label: 'PACKAGE - Cylinder needs re-packing' },
      { value: 'SCACCOUNT', label: 'SCACCOUNT - Incorrect Account' },
      { value: 'SCALT', label: 'SCALT - Returned for alteration' },
      { value: 'SCCAN', label: 'SCCAN - Cancelled order' },
      { value: 'SCCASEEXP', label: 'SCCASEEXP - Case expanding and contracting' },
      { value: 'SCCOM', label: 'SCCOM - Faulty component' },
      { value: 'SCCONSTO', label: 'SCCONSTO - Consignment stock' },
      { value: 'SCCOR', label: 'SCCOR - Corrosion' },
      { value: 'SCCUTAWAY', label: 'SCCUTAWAY - Cutaway cylinder' },
      { value: 'SCCYLNR', label: 'SCCYLNR - Cylinder not been collected' },
      { value: 'SCDEL', label: 'SCDEL - Cylinder not delivered on time' },
      { value: 'SCDES', label: 'SCDES - Cylinder not despatched or made' },
      { value: 'SCDESERR', label: 'SCDESERR - Despatch error' },
      { value: 'SCDIT', label: 'SCDIT - Damaged in transit' },
      { value: 'SCDOB', label: 'SCDOB - Dropped on base' },
      { value: 'SCDUP', label: 'SCDUP - Duplicated order' },
      { value: 'SCIF', label: 'SCIF - Further investigation required' },
      { value: 'SCLABEL', label: 'SCLABEL - Labelled incorrectly' },
      { value: 'SCLIR', label: 'SCLIR - Lost in returns' },
      { value: 'SCLIT', label: 'SCLIT - Lost in transit' },
      { value: 'SCLOAD', label: 'SCLOAD - Not loaded or despatched' },
      { value: 'SCMD', label: 'SCMD - Manufacturers defect' },
      { value: 'SCNFF', label: 'SCNFF - No fault found' },
      { value: 'SCNFOCYL', label: 'SCNFOCYL - No fault found on original cylinder' },
      { value: 'SCNOM', label: 'SCNOM - Not our manufacture' },
      { value: 'SCNTCS', label: 'SCNTCS - Not to customer spec' },
      { value: 'SCNTD', label: 'SCNTD - Not to drawing' },
      { value: 'SCOERR', label: 'SCOERR - Office order error' },
      { value: 'SCORD', label: 'SCORD - Ordered incorrectly' },
      { value: 'SCPE', label: 'SCPE - Duplicate pricing error' },
      { value: 'SCPF', label: 'SCPF - Plumber or installers error' },
      { value: 'SCPI', label: 'SCPI - Packaged incorrectly' },
      { value: 'SCPNR', label: 'SCPNR - Component part not collected' },
      { value: 'SCRAP', label: 'SCRAP - Out of warranty' },
      { value: 'SCRED', label: 'SCRED - Redirection of cylinder' },
      { value: 'SCREW', label: 'SCREW - Rework' },
      { value: 'SCRNA', label: 'SCRNA - Returned not accepted' },
      { value: 'SCRND', label: 'SCRND - Returned not delivered' },
      { value: 'SCSD', label: 'SCSD - Short delivered' },
      { value: 'SCSFE', label: 'SCSFE - Shopfloor error' },
      { value: 'SCSRMF', label: 'SCSRMF - Supplied raw material failure' },
      { value: 'SCSTO', label: 'SCSTO - For restocking' },
      { value: 'SCSTOLE', label: 'SCSTOLE - Stolen cylinders' },
      { value: 'SCT&P', label: 'SCT&P - Temperature and pressured not fitted' },
      { value: 'SCWHD', label: 'SCWHD - Warehouse damage' },
      { value: '2Q', label: '2Q - Warranty part' },
      { value: 'STOLEN', label: 'STOLEN - Stolen' },
      { value: 'TEST', label: 'TEST - Waiting for report' },
      { value: 'WRONGSKETC', label: 'WRONGSKETC - Wrong sketch attached' },
      { value: 'OTHER', label: 'OTHER - See comments' },
    ];

const [isoReason, setIsoReason] = useState('');

const isoReasonOptions = [
  { value: '1EXP', label: '1EXP - 1L Expansion Vessel' },
  { value: '3/4TESTBOSS', label: '3/4TESTBOSS - 3/4 Test Boss' },
  { value: 'ACID', label: 'ACID - Still contains Acid Solution' },
  { value: 'ADDRESS', label: 'ADDRESS - Sent to wrong address' },
  { value: 'ADMIN', label: 'ADMIN - Admin Error' },
  { value: 'AIR', label: 'AIR - Not filled with air before foam' },
  { value: 'AQUASTAT', label: 'AQUASTAT - Leaking from pocket' },
  { value: 'BFC', label: 'BFC - Badly fitted coil' },
  { value: 'BLENDVALVE', label: 'BLENDVALVE - Blend Valve assembly' },
  { value: 'BLOCK', label: 'BLOCK - Bottom pipe not cut out' },
  { value: 'BODY', label: 'BODY - Leak on body sheet' },
  { value: 'BOSSOUTSHA', label: 'BOSSOUTSHA - Boss out of shape' },
  { value: 'BRAZE', label: 'BRAZE - Braze imm boss thread' },
  { value: 'BUBBLE', label: 'BUBBLE - Wrapped whilst still wet' },
  { value: 'BULGESEAM', label: 'BULGESEAM - Bulge on seam' },
  { value: 'CANC', label: 'CANC - Cancelled after production' },
  { value: 'CASED', label: 'CASED - Should have been a cased' },
  { value: 'CASEEXPANDING', label: 'CASEEXPANDING - Case expanding' },
  { value: 'CBRAZE', label: 'CBRAZE - Braze in coil' },
  { value: 'COE', label: 'COE - Customer order error' },
  { value: 'COIL', label: 'COIL - Will not allow cirulation' },
  { value: 'COILBOSSES', label: 'COILBOSSES - Coil bosses slightly out' },
  { value: 'COILNOTINLINE', label: 'COILNOTINLINE - Coil out of line' },
  { value: 'COILPIN', label: 'COILPIN - Pin hole in coil tube' },
  { value: 'COILS', label: 'COILS - Strapping snapped' },
  { value: 'COLD', label: 'COLD - No cold feed pipe' },
  { value: 'COLDF', label: 'COLDF - Cold feed in wrong position or damaged' },
  { value: 'CONNECTION', label: 'CONNECTION - Connection not cut out' },
  { value: 'CRACKING&POPPING', label: 'CRACKING&POPPING - Cracking and popping' },
  { value: 'CXIMM', label: 'CXIMM - Coil or Immersion position' },
  { value: 'DBC', label: 'DBC - Dropped before casing' },
  { value: 'DBOX', label: 'DBOX - Damaged boxes' },
  { value: 'DBS', label: 'DBS - Damaged bottom side' },
  { value: 'DCASE', label: 'DCASE - Damaged case only' },
  { value: 'DCH', label: 'DCH - Damage coil head' },
  { value: 'DEL', label: 'DEL - Delivered too late' },
  { value: 'DENTED', label: 'DENTED - Boss' },
  { value: 'DESPATCH', label: 'DESPATCH - Not despatched but invoiced' },
  { value: 'DIB', label: 'DIB - Debris in boss' },
  { value: 'DIC', label: 'DIC - Debris in coil' },
  { value: 'DIT', label: 'DIT - Damaged in transit' },
  { value: 'DRAIN', label: 'DRAIN - Fitted too low' },
  { value: 'DUP', label: 'DUP - Duplicated order' },
  { value: 'E7', label: 'E7 - Should have been E7' },
  { value: 'EXP', label: 'EXP - Cannot screw in exp vessel' },
  { value: 'EXTRA', label: 'EXTRA - Extra imm boss required' },
  { value: 'FAULTYCOIL', label: 'FAULTYCOIL - Incorrect coil' },
  { value: 'FAULTYCOLDFEEDBOSS', label: 'FAULTYCOLDFEEDBOSS - Faulty cold feed boss' },
  { value: 'FAULTYPART', label: 'FAULTYPART - Faulty part' },
  { value: 'FLUX', label: 'FLUX - Flux corroded cylinder' },
  { value: 'FOAM', label: 'FOAM - Foamed incorrectly/foam lifting' },
  { value: 'FOAMBOSS', label: 'FOAMBOSS - Foamed over boss' },
  { value: 'FOAMING', label: 'FOAMING - Damage before foaming' },
  { value: 'FOAMSHRINKAGE', label: 'FOAMSHRINKAGE - Form shrinkage' },
  { value: 'FRAUD', label: 'FRAUD - Fraud order' },
  { value: 'HEIGHT', label: 'HEIGHT - Wrong height' },
  { value: 'HIFLOWCOIL', label: 'HIFLOWCOIL - Should have been hi flow coil' },
  { value: 'HOLE', label: 'HOLE - Hole in body sheet' },
  { value: 'IMMBOSSOUT', label: 'IMMBOSSOUT - Imm bosses out of line' },
  { value: 'IMMPINHOLE', label: 'IMMPINHOLE - Pin hole around immersion boss' },
  { value: 'IMMTHREAD', label: 'IMMTHREAD - Faulty immersion thread' },
  { value: 'IMMTOOSMALL', label: 'IMMTOOSMALL - Immersion boss to small' },
  { value: 'INCORR', label: 'INCORR - Incorrectly boxed' },
  { value: 'INSBRAZE', label: 'INSBRAZE - Insufficient braze' },
  { value: 'INSFOAM', label: 'INSFOAM - Insuffcient covering of foam' },
  { value: 'L T&P', label: 'L T&P - Leak on T&P boss' },
  { value: 'LABEL', label: 'LABEL - Labeled incorrectly' },
  { value: 'LBN', label: 'LBN - Leaking back nut' },
  { value: 'LBOSS', label: 'LBOSS - Leaking boss' },
  { value: 'LBOTTCIRCUM', label: 'LBOTTCIRCUM - Leak on bottom circumference' },
  { value: 'LBOTTSEAM', label: 'LBOTTSEAM - Leaking bottom seam' },
  { value: 'LC', label: 'LC - Leaking coil' },
  { value: 'LCNX', label: 'LCNX - Leaking connection' },
  { value: 'LCOILBOSS', label: 'LCOILBOSS - Leaking coil return boss' },
  { value: 'LCOILFLOWBOSS', label: 'LCOILFLOWBOSS - Leaking coil flow boss' },
  { value: 'LCOLDFEED', label: 'LCOLDFEED - Leaking cold feed' },
  { value: 'LEAKUPPBOSS', label: 'LEAKUPPBOSS - Leaking upper 1 1/2 boss' },
  { value: 'LEANING', label: 'LEANING - Leaning to one side' },
  { value: 'LFOAM', label: 'LFOAM - Leaking behind foam' },
  { value: 'LHOTDRAWOFF', label: 'LHOTDRAWOFF - Leak on hot water draw off' },
  { value: 'LID', label: 'LID - Does not sit correctly' },
  { value: 'LIMM', label: 'LIMM - Leaking from immersion boss or heater' },
  { value: 'LOOSECONN', label: 'LOOSECONN - Loose wire connection' },
  { value: 'LPPE', label: 'LPPE - Leaking pipe' },
  { value: 'LSEAM', label: 'LSEAM - Leaking seam' },
  { value: 'LSEAMCIR', label: 'LSEAMCIR - Leaking seam on circumference' },
  { value: 'LSECRET', label: 'LSECRET - Leaking secon' },
  { value: 'LSOLARCOILRET', label: 'LSOLARCOILRET - Leaking solar coil return' },
  { value: 'LSPINN', label: 'LSPINN - Leak on spinning' },
  { value: 'LTOPCIRWELD', label: 'LTOPCIRWELD - Leak on top circumference weld' },
  { value: 'LTOPSEAM', label: 'LTOPSEAM - Leak on top seam' },
  { value: 'LVERTSEAM', label: 'LVERTSEAM - Leak on vertical seam' },
  { value: 'MARKS', label: 'MARKS - Pickling marks on casing' },
  { value: 'MBISS', label: 'MBISS - Misshapen imm boss' },
  { value: 'MISSSTATPOCKET', label: 'MISSSTATPOCKET - Missing stat pocket' },
  { value: 'MULTIPLE', label: 'MULTIPLE - Multiple leaks' },
  { value: 'NOIMM', label: 'NOIMM - No immersion heater' },
  { value: 'NOISYIMM', label: 'NOISYIMM - Noise immersion heater' },
  { value: 'OERR', label: 'OERR - Office order error' },
  { value: 'OLDSPEC', label: 'OLDSPEC - Needed old spec' },
  { value: 'OLDTYPE', label: 'OLDTYPE - Needed old type' },
  { value: 'ORING', label: 'ORING - O Ring not sealing properly' },
  { value: 'OVERPRESSURE', label: 'OVERPRESSURE - Over pressurised' },
  { value: 'OVLIMM', label: 'OVLIMM - Oval immersion boss' },
  { value: 'PART', label: 'PART - Parts missing' },
  { value: 'PBRAZE', label: 'PBRAZE - Poor brazing' },
  { value: 'PHSP', label: 'PHSP - Pinhole stat pocket' },
  { value: 'PICKLE', label: 'PICKLE - Not pickled' },
  { value: 'PIN1"FBOSS', label: 'PIN1"FBOSS - Pinhole leak in weld around 1"' },
  { value: 'PINBOTTSEAM', label: 'PINBOTTSEAM - Pin hole bottom seam' },
  { value: 'PINCOILBOSS', label: 'PINCOILBOSS - Pin hole in weld around coil' },
  { value: 'PINCOILFLOW', label: 'PINCOILFLOW - Pin hole around coil flow boss' },
  { value: 'PINCOILRETURN', label: 'PINCOILRETURN - Pin hole around coil return' },
  { value: 'PINCOLDFEED', label: 'PINCOLDFEED - Pin hole leak on cold feed bos' },
  { value: 'PINHOLE', label: 'PINHOLE - Pin hole in weld' },
  { value: 'PINHOTDRAW', label: 'PINHOTDRAW - Pin hole in weld hot draw off' },
  { value: 'PINIMMBOSS', label: 'PINIMMBOSS - Pin hole on imm boss' },
  { value: 'PINLEABRAIMMBOSS', label: 'PINLEABRAIMMBOSS - Pin hole leak in braze immboss' },
  { value: 'PINLOWERIMMBOSS', label: 'PINLOWERIMMBOSS - Pin hole in lower imm boss' },
  { value: 'PINSECRET', label: 'PINSECRET - Pin hole in 1 1/2 sec ret' },
  { value: 'PINTOPSEAM', label: 'PINTOPSEAM - Pin hole in top seam' },
  { value: 'PINUPPERIMMBOSS', label: 'PINUPPERIMMBOSS - Pin hole in upper imm boss' },
  { value: 'PINVERTSEAM', label: 'PINVERTSEAM - Pin hole in vertical seam' },
  { value: 'PRODUCT', label: 'PRODUCT - Wrong product supplied/made' },
  { value: 'QUALITY', label: 'QUALITY - Quality of cylinder' },
  { value: 'RATCOIL', label: 'RATCOIL - Rattling coil' },
  { value: 'REPACKAGE', label: 'REPACKAGE - RE-PACKING OF CYLINDER' },
  { value: 'RESTOCK', label: 'RESTOCK - Restock' },
  { value: 'RETURNED', label: 'RETURNED - Cylinderhapen and returned' },
  { value: 'REWORK', label: 'REWORK - Rework' },
  { value: 'RISING', label: 'RISING - Rising coils' },
  { value: 'ROLL', label: 'ROLL - Not rolled properly' },
  { value: 'SCDOB', label: 'SCDOB - Dropped on base' },
  { value: 'SCRAP', label: 'SCRAP - Scrap' },
  { value: 'SCRATCHED', label: 'SCRATCHED - Scratched lids' },
  { value: 'SEAM', label: 'SEAM - Lumpe on seam' },
  { value: 'SEC', label: 'SEC - foamedhapen sec return' },
  { value: 'SERIALNUMBER', label: 'SERIALNUMBER - Serial number provided incorrectly' },
  { value: 'SHOPERROR', label: 'SHOPERROR - Factory error' },
  { value: 'SHORTSTATPOCK', label: 'SHORTSTATPOCK - Stat pocket to short' },
  { value: 'SKETCH', label: 'SKETCH - Sketch incorrect' },
  { value: 'SNAP', label: 'SNAP - Snapped off glued in exp vess' },
  { value: 'SPBOSS', label: 'SPBOSS - Split Boss' },
  { value: 'SPCOPP', label: 'SPCOPP - Split copper material' },
  { value: 'SPLIT', label: 'SPLIT - Split lids' },
  { value: 'SPSS', label: 'SPSS - Split stainless material' },
  { value: 'SPSSVERTSEAM', label: 'SPSSVERTSEAM - Leak on vertical seam split' },
  { value: 'STAT', label: 'STAT - Faulty stat pocket' },
  { value: 'T & P', label: 'T & P - Cased over' },
  { value: 'T&P VALVE', label: 'T&P VALVE - T&P out of line' },
  { value: 'TAPP', label: 'TAPP - Incorrect size tappings' },
  { value: 'THREAD', label: 'THREAD - threads not deep enough' },
  { value: 'UNABLE', label: 'UNABLE - Unable to locate leak' },
  { value: 'WRAP', label: 'WRAP - Not wrapped correctly' },
  { value: 'WRONGCAS', label: 'WRONGCAS - Incorrectly cased' },
  { value: 'WRONGCOIL', label: 'WRONGCOIL - Incorrect coil' },
  { value: 'XBOSS', label: 'XBOSS - Cross threaded boss' },
  { value: 'XCOIL', label: 'XCOIL - Cross threaded coil head' },
  { value: 'XIMM', label: 'XIMM - Cross threaded imm boss' },
  { value: 'OTHER', label: 'OTHER - See comments' },
  // Add or modify these options as needed
];

const handleIsoReasonChange = (selectedOption) => {
  setIsoReason(selectedOption.value);
};

    return (
      <div className="ReturnFormBody">
          <div className="returnFormContainer">
              <h1>Cylinder Return Form</h1>
              <form onSubmit={handleSubmit}>
                  {Object.entries(formState).map(([key, value]) =>
                      key !== 'reasonForReturn' && key !== 'images' && key !== 'faultFound' && key !== 'operators' && key !== 'parts' ? (
                          <div className="formField" key={key}>
                              <label htmlFor={key}>{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}:</label>
                              <input 
                                type={key === 'dateOfManufacture' ? 'date' : 'text'} 
                                id={key} 
                                value={value} 
                                onChange={(e) => handleChange(key, e.target.value)} 
                                placeholder={`Enter ${key.replace(/([A-Z])/g, ' $1').toLowerCase()}`}
                              />
                          </div>
                      ) : null
                  )}
                  <div className="formField">
                      <label htmlFor="reasonForReturn">Reason for return:</label>
                      <Select
                          inputId="reasonForReturn"
                          options={reasonForReturnOptions}
                          value={reasonForReturnOptions.find(option => option.value === formState.reasonForReturn)}
                          onChange={(option) => handleChange('reasonForReturn', option.value)}
                          styles={{ container: (base) => ({ ...base, width: '100%' }), menu: (base) => ({ ...base, width: '100%' }) }}
                      />
                  </div>
                  <div className="formField">
                      <label htmlFor="faultFound">Fault found:</label>
                      <Select
                          inputId="faultFound"
                          options={reasonForReturnOptions}
                          value={reasonForReturnOptions.find(option => option.value === formState.faultFound)}
                          onChange={(option) => handleChange('faultFound', option.value)}
                          styles={{ container: (base) => ({ ...base, width: '100%' }), menu: (base) => ({ ...base, width: '100%' }) }}
                      /> <br />
                      {formState.faultFound === 'SCMD' && (
                        <div className="formField">
                            <label htmlFor="isoReason">ISO Reason:</label>
                            <Select
                                inputId="isoReason"
                                options={isoReasonOptions}
                                value={isoReasonOptions.find(option => option.value === isoReason)}
                                onChange={handleIsoReasonChange}
                                styles={{ container: (base) => ({ ...base, width: '100%' }), menu: (base) => ({ ...base, width: '100%' }) }}
                            />
                        </div>
                    )}
                  </div>
                  {formState.images.map((image, index) => (
                      <div key={index} className="imagePreview">
                          <img src={image.url} alt="Preview" style={{ width: 100 }} />
                          <button type="button" onClick={() => removeImage(index)}>Remove</button>
                      </div>
                  ))}
                  <div className="formField">
                      <label htmlFor="imageUpload">Upload Images or Take a Picture:</label>
                      <input type="file" id="imageUpload" accept="image/*" capture="environment" multiple onChange={(e) => handleChange('images', e)} />
                  </div>
                  
                  <div className="formField">
                    <button type="button" onClick={() => setOperatorsModalIsOpen(true)}>Operators</button>
                  </div>
                  <div className="formField">
                    <button type="button" onClick={() => setPartsModalIsOpen(true)}>Parts</button>
                  </div>
                  <div className="formField">
                    <button type="button" onClick={() => setCylinderCheckModalIsOpen(true)}>Open Cylinder Checklist</button>
                  </div>
                  <div className="formField">
                    <button type="button" onClick={() => setLeaksModalIsOpen(true)}>Open Leaks Checklist</button>
                  </div>

                  <button type="submit">Submit Report</button>
              </form>
          </div>

          <Modal
                isOpen={operatorsModalIsOpen}
                onRequestClose={() => setOperatorsModalIsOpen(false)}
                contentLabel="Operators Modal"
                className="modalContent" // Apply specific modal content styles
                overlayClassName="modalOverlay"
            >
            <h2>Operators (Initials)</h2>
            <h3>Stainless</h3>
            <div className="formField">
              <label htmlFor="welded">Welded:</label>
              <input type="text" id="welded" value={formState.operators.welded} onChange={(e) => handleChange('operators.welded', e.target.value)} />
            </div>
            <div className="formField">
              <label htmlFor="airTest">Air Test:</label>
              <input type="text" id="airTest" value={formState.operators.airTest} onChange={(e) => handleChange('operators.airTest', e.target.value)} />
            </div>
            <div className="formField">
              <label htmlFor="hotTested">Hot Tested:</label>
              <input type="text" id="hotTested" value={formState.operators.hotTested} onChange={(e) => handleChange('operators.hotTested', e.target.value)} />
            </div>
            <h3>Copper</h3>
            <div className="formField">
              <label htmlFor="tested">Tested:</label>
              <input type="text" id="tested" value={formState.operators.tested} onChange={(e) => handleChange('operators.tested', e.target.value)} />
            </div>
            <button type="button" onClick={() => setOperatorsModalIsOpen(false)}>Save</button>
          </Modal>

          <Modal
    isOpen={partsModalIsOpen}
    onRequestClose={() => setPartsModalIsOpen(false)}
    contentLabel="Parts Modal"
    className="modalContent"
    overlayClassName="modalOverlay"
>
    <h2>Parts</h2>
    <div className="formField">
        <input type="checkbox" id="noPartsReturned" checked={partsState.noPartsReturned} onChange={() => handlePartsChange('noPartsReturned')} />
        <label htmlFor="noPartsReturned">No Parts Returned</label>
    </div>
    <div className="formField">
        <input type="checkbox" id="allPartsReturned" checked={partsState.allPartsReturned} onChange={() => handlePartsChange('allPartsReturned')} />
        <label htmlFor="allPartsReturned">All Parts Returned</label>
    </div>
    <div className="formField">
        <input type="checkbox" id="partialReturn" checked={partsState.partialReturn} onChange={() => handlePartsChange('partialReturn')} />
        <label htmlFor="partialReturn">Partial Return</label>
    </div>

    {partsState.partialReturn && (
        <>
            {Object.entries(partsState.partialReturnItems).map(([key, value]) => (
                <div className="formField" key={key}>
                    <input type="checkbox" id={key} checked={value} onChange={() => setPartsState(prev => ({
                        ...prev,
                        partialReturnItems: {
                            ...prev.partialReturnItems,
                            [key]: !prev.partialReturnItems[key]
                        }
                    }))} />
                    <label htmlFor={key}>{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</label>
                </div>
            ))}
        </>
    )}

    <button type="button" onClick={() => setPartsModalIsOpen(false)}>Save</button>
</Modal>

          <Modal
    isOpen={cylinderCheckModalIsOpen}
    onRequestClose={() => setCylinderCheckModalIsOpen(false)}
    contentLabel="Cylinder Checklist Modal"
    className="modalContent"
    overlayClassName="modalOverlay"
>
    <h2>Cylinder Checklist</h2>
    <div className="formField">
        <input type="checkbox" id="cylinderInstalled" checked={cylinderCheck.cylinderInstalled} onChange={handleCheckboxChange('cylinderCheck', 'cylinderInstalled')} />
        <label htmlFor="cylinderInstalled">Cylinder Installed</label>
    </div>
    <div className="formField">
        <input type="checkbox" id="damageToCase" checked={cylinderCheck.damageToCase} onChange={handleCheckboxChange('cylinderCheck', 'damageToCase')} />
        <label htmlFor="damageToCase">Damage to Case</label>
    </div>
    <div className="formField">
        <input type="checkbox" id="damageToPackaging" checked={cylinderCheck.damageToPackaging} onChange={handleCheckboxChange('cylinderCheck', 'damageToPackaging')} />
        <label htmlFor="damageToPackaging">Damage to Packaging</label>
    </div>
    <div className="formField">
        <input type="checkbox" id="damageToBosses" checked={cylinderCheck.damageToBosses} onChange={handleCheckboxChange('cylinderCheck', 'damageToBosses')} />
        <label htmlFor="damageToBosses">Damage to Bosses</label>
    </div>
    <div className="formField">
        <input type="checkbox" id="stripAndTest" checked={cylinderCheck.stripAndTest} onChange={handleCheckboxChange('cylinderCheck', 'stripAndTest')} />
        <label htmlFor="stripAndTest">Strip and Test</label>
    </div>
    <button type="button" onClick={() => setCylinderCheckModalIsOpen(false)}>Save</button>
</Modal>
<Modal
    isOpen={leaksModalIsOpen}
    onRequestClose={() => setLeaksModalIsOpen(false)}
    contentLabel="Leaks Modal"
    className="modalContent"  // This class should have the flex-wrap property
    overlayClassName="modalOverlay"
>
    <h2>Leaks</h2>
    <div style={{ display: 'flex', flexWrap: 'wrap' }}> {/* This ensures wrapping */}
        {Object.entries(leakChecks).map(([key, value]) => (
            key !== 'otherComments' ? (
                <div className="formField" key={key}>
                    <input type="checkbox" id={key} checked={value} onChange={handleCheckboxChange('leakChecks', key)} />
                    <label htmlFor={key}>{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</label>
                </div>
            ) : null
        ))}
    </div>
    {leakChecks.other && (
        <div className="formField" style={{ width: '100%' }}>  {/* Full width for 'Other' input */}
            <label htmlFor="otherComments">Other:</label>
            <input type="text" id="otherComments" value={leakChecks.otherComments} onChange={handleInputChange('leakChecks', 'otherComments')} />
        </div>
    )}
    <button type="button" onClick={() => setLeaksModalIsOpen(false)}>Save</button>
</Modal>


      </div>
  );
};

export default ReturnsEntry;
