import React, { useState, useEffect, useMemo } from "react";
import { getFirestore, collection, doc, getDoc, getDocs } from "firebase/firestore";
import * as XLSX from "xlsx";
import FavouriteButton from "./FavouriteButton";

const SalesWarehouse = () => {
  const [cylinders, setCylinders] = useState([]);
  const [viewMode, setViewMode] = useState("table");
  const [selectedProductCode, setSelectedProductCode] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "ascending" });

  useEffect(() => {
    const firestore = getFirestore();

    const fetchCode = async (productId) => {
      if (!productId || typeof productId !== "string") {
        return "N/A";
      }
      const docRef = doc(firestore, "cyl_info", productId);
      const docSnap = await getDoc(docRef);
      return docSnap.exists() ? docSnap.data().productCode : "N/A";
    };

    const fetchLocation = async (LocationId) => {
      if (LocationId.length < 5) return LocationId;
      const locationsRef = collection(firestore, "warehouse_locations");
      const querySnapshot = await getDocs(locationsRef);
      const uniqueId = LocationId.slice(-5);
      const matchingDoc = querySnapshot.docs.find((doc) => doc.data().barcode.endsWith(uniqueId));
      return matchingDoc ? matchingDoc.id : LocationId;
    };

    const fetchData = async () => {
      const cylindersCollection = collection(firestore, "cylinders_warehouse");
      const querySnapshot = await getDocs(cylindersCollection);

      const cylindersData = await Promise.all(
        querySnapshot.docs.map(async (docSnapshot) => {
          const cylinderData = docSnapshot.data();
          return {
            id: docSnapshot.id,
            ProductCode: await fetchCode(cylinderData.ProductId),
            SerialNumber: cylinderData.SerialNumber,
            Special: cylinderData.Special,
            BookedInTime: cylinderData.BookedInTime,
            LocationId: await fetchLocation(cylinderData.LocationId),
            ProductId: cylinderData.ProductId,
            Stock: cylinderData.Stock ? "STOCK" : "ORDER",
            Allocated: cylinderData.Allocated || false,
          };
        })
      );
      setCylinders(cylindersData);
    };

    fetchData();
  }, []);

  const sortData = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    setCylinders((currentCylinders) => {
      return [...currentCylinders].sort((a, b) => {
        if (a[key] < b[key]) {
          return direction === "ascending" ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    });
  };

  const formatDate = (timestamp) => {
    return timestamp ? new Date(timestamp.seconds * 1000).toLocaleString() : "N/A";
  };

  const filteredCylinders = useMemo(
    () =>
      cylinders.filter(
        (cylinder) =>
          cylinder.ProductCode.toLowerCase().includes(searchQuery.toLowerCase()) ||
          cylinder.SerialNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
          (cylinder.LocationId && cylinder.LocationId.toLowerCase().includes(searchQuery.toLowerCase()))
      ),
    [searchQuery, cylinders]
  );

  const exportToExcel = () => {
    const dataToExport = filteredCylinders.map((cylinder) => ({
      ProductCode: cylinder.ProductCode,
      SerialNumber: cylinder.SerialNumber,
      BookedInTime: formatDate(cylinder.BookedInTime),
      LocationId: cylinder.LocationId,
      Stock: cylinder.Stock,
      Allocated: cylinder.Allocated,
    }));
    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Cylinders");
    XLSX.writeFile(wb, "cylinders.xlsx");
  };

  return (
    <>
      <FavouriteButton pageUrl={window.location.pathname} />
      <div className="min-h-screen bg-gray-100 flex flex-col items-center p-6">
        <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-6xl border border-gray-300">
          <h2 className="text-2xl font-semibold text-center text-gray-800 mb-4">
            Warehouse Stock
          </h2>

          {/* Search & Filters */}
          <div className="flex flex-wrap justify-between mb-6 gap-4">
            <input
              type="text"
              placeholder="Search by Product Code, Serial Number or Location..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full md:w-1/2 p-3 border rounded-md focus:ring-2 focus:ring-primary focus:outline-none"
            />
            <div className="flex gap-2">
              <select
                value={viewMode}
                onChange={(e) => setViewMode(e.target.value)}
                className="p-3 border rounded-md focus:ring-2 focus:ring-primary focus:outline-none"
              >
                <option value="table">Table View</option>
                <option value="list">List View</option>
              </select>
              <button
                onClick={exportToExcel}
                className="bg-primary hover:bg-hover text-white px-4 py-2 rounded-md transition"
              >
                Export to Excel
              </button>
            </div>
          </div>

          {/* Table View */}
          {viewMode === "table" && (
            <div className="overflow-x-auto">
              <table className="w-full bg-white shadow-md rounded-lg">
                <thead className="bg-primary text-white">
                  <tr>
                    <th className="p-3 cursor-pointer" onClick={() => sortData("ProductCode")}>
                      Product Code
                    </th>
                    <th className="p-3 cursor-pointer" onClick={() => sortData("SerialNumber")}>
                      Serial Number
                    </th>
                    <th className="p-3">Type</th>
                    <th className="p-3 cursor-pointer" onClick={() => sortData("BookedInTime")}>
                      Booked In
                    </th>
                    <th className="p-3 cursor-pointer" onClick={() => sortData("LocationId")}>
                      Location
                    </th>
                    <th className="p-3 cursor-pointer" onClick={() => sortData("Stock")}>
                      Stock Status
                    </th>
                    <th className="p-3 cursor-pointer" onClick={() => sortData("Allocated")}>
                      Allocated
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredCylinders.map((cylinder) => (
                    <tr key={cylinder.id} className="border-t hover:bg-gray-100 transition">
                      <td className="p-3">{cylinder.ProductCode}</td>
                      <td className="p-3">{cylinder.SerialNumber || "N/A"}</td>
                      <td className="p-3">{cylinder.Special}</td>
                      <td className="p-3">{formatDate(cylinder.BookedInTime)}</td>
                      <td className="p-3">{cylinder.LocationId}</td>
                      <td className="p-3">{cylinder.Stock}</td>
                      <td className="p-3">{cylinder.Allocated ? "Yes" : "No"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SalesWarehouse;
