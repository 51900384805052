import React from "react";
import { useNavigate } from "react-router-dom";
import FavouriteButton from "./FavouriteButton";

const TechnicalCalculator = () => {
  const navigate = useNavigate();

  const calculators = [
    { name: "Heat Loss Calculator", route: "/HeatLossCalculator", external: false },
    { name: "Heat Up kW Required Calculator", route: "/HeatUpCalculator", external: false },
    { name: "Heat Up Time Required Calculator", route: "/TimeRequiredCalculator", external: false },
    { name: "Cylinder Sizing", route: "https://www.hotwater.org.uk/hot-water-cylinder-calculator/", external: true },
    { name: "Expansion Vessel Sizing", route: "/ExpansionVesselCalculator", external: false },
  ];

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center p-6">
      {/* Favorite Button */}
      <FavouriteButton pageUrl={window.location.pathname} />

      {/* Title */}
      <h1 className="text-4xl font-semibold text-gray-800 mb-6">Technical Calculators</h1>

      {/* List Container */}
      <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-3xl">
        <ul className="space-y-4">
          {calculators.map((calc, index) => (
            <li
              key={index}
              onClick={() => {
                if (calc.external) {
                  window.open(calc.route, "_blank"); // Opens in a new tab
                } else {
                  navigate(calc.route);
                }
              }}
              className="p-4 bg-gray-100 hover:bg-primary hover:text-white transition rounded-lg shadow cursor-pointer text-lg font-medium flex justify-between items-center"
            >
              {calc.name}
              <span className="text-xl">&rarr;</span> {/* Right Arrow Icon */}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TechnicalCalculator;
