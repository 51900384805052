import React, { useState, useEffect } from "react";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { useParams, useNavigate } from "react-router-dom";

const DespatchDetailView = () => {
  const firestore = getFirestore();
  const { id } = useParams();
  const navigate = useNavigate();

  // States
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch Detailed Report Data
  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const detailRef = doc(firestore, "despatchreportsdetailed", id);
        const detailSnap = await getDoc(detailRef);

        if (detailSnap.exists()) {
          setDetails(detailSnap.data().items || []);
        } else {
          console.warn("No details found for this report.");
        }
      } catch (error) {
        console.error("Error fetching details:", error);
      }

      setLoading(false);
    };

    fetchDetails();
  }, [id]);

  return (
    <div className="min-h-screen flex flex-col items-center p-6">
      <h1 className="text-2xl font-semibold mb-4">Despatch Report Details</h1>

      {/* Back Button */}
      <button
        onClick={() => navigate("/DespatchView")}
        className="mb-4 bg-primary text-white px-4 py-2 rounded hover:bg-hover"
      >
        Back to Reports
      </button>

      {/* Loading State */}
      {loading ? <p className="text-blue-500">Loading details...</p> : null}

      {/* Details Table */}
      {details.length > 0 ? (
        <div className="overflow-x-auto w-full max-w-3xl">
          <table className="min-w-full border border-gray-200 text-sm">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-4 py-2 border-b">#</th>
                <th className="px-4 py-2 border-b">Serial</th>
                <th className="px-4 py-2 border-b">Product</th>
                <th className="px-4 py-2 border-b">Type</th>
                <th className="px-4 py-2 border-b">Full Barcode</th>
              </tr>
            </thead>
            <tbody>
              {details.map((item, index) => (
                <tr key={index} className="text-center">
                  <td className="px-4 py-2 border-b">{index + 1}</td>
                  <td className="px-4 py-2 border-b">{item.serialNumber}</td>
                  <td className="px-4 py-2 border-b">{item.productCode}</td>
                  <td className="px-4 py-2 border-b">{item.special}</td>
                  <td className="px-4 py-2 border-b">{item.barcode}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        !loading && <p className="text-gray-500">No details found.</p>
      )}
    </div>
  );
};

export default DespatchDetailView;
