import { useEffect, useState } from "react";
import { db } from "../firebase/firebaseConfig";
import { doc, setDoc, collection, addDoc, getDocs, getDoc } from "firebase/firestore";
import { format } from "date-fns";

const ManualFixForm = ({ issue, onClose, adminUser }) => {
  const [logs, setLogs] = useState([]);
  const [newFixes, setNewFixes] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchDailyEntries = async () => {
    const dailyEntriesRef = collection(db, `clock-users/${issue.uid}/${issue.date}`);
    const snap = await getDocs(dailyEntriesRef);
    const existing = snap.docs.map((d) => d.data()).sort((a, b) => a.timestamp.localeCompare(b.timestamp));
    setLogs(existing);
  };

  useEffect(() => {
    fetchDailyEntries();
  }, []);

  const handleAddFix = () => {
    setNewFixes([...newFixes, { time: "", status: "OUT" }]);
  };

  const handleApply = async () => {
    setLoading(true);
    try {
      for (let fix of newFixes) {
        if (!fix.time) continue;

        const timestamp = `${issue.date} ${fix.time}:00`;
        const key = fix.time.replace(":", "");
        const id = `${issue.uid}-${key}`;
        const newEntry = { uid: issue.uid, status: fix.status, timestamp };

        await setDoc(doc(db, `clock-users/${issue.uid}/${issue.date}/${key}`), newEntry);
        await setDoc(doc(db, `clock-days/${issue.date}/entries/${id}`), newEntry);

        await addDoc(collection(db, "audit-log"), {
          uid: issue.uid,
          action: "manual_clock_entry_added",
          timestamp: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
          performed_by_email: adminUser.email,
          performed_by_name: adminUser.name,
          issue_reason: issue.message,
          new_value: newEntry,
        });
      }

      alert("✅ Entries added");
      onClose();
    } catch (e) {
      console.error("❌ Failed to add fix:", e);
      alert("Error occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-xl w-full max-w-2xl shadow-lg">
        <h2 className="text-xl font-bold mb-2">Fix Clock Entries for {issue.name}</h2>
        <p className="text-sm text-gray-600 mb-2">{issue.message}</p>
        <p className="text-sm text-gray-500 mb-4">Date: {issue.date}</p>

        <div className="mb-4">
          <h3 className="font-semibold text-gray-700 mb-2">Current Entries:</h3>
          {logs.length === 0 ? (
            <p className="text-gray-500 text-sm">No entries yet.</p>
          ) : (
            <ul className="text-sm bg-gray-100 rounded p-2">
              {logs.map((log, i) => (
                <li key={i} className="py-1">
                  {log.timestamp.split(" ")[1]} — {log.status}
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className="mb-4">
          <h3 className="font-semibold text-gray-700 mb-2">New Fixes:</h3>
          {newFixes.map((fix, i) => (
            <div key={i} className="flex space-x-2 mb-2">
              <input
                type="time"
                value={fix.time}
                onChange={(e) =>
                  setNewFixes((prev) => {
                    const copy = [...prev];
                    copy[i].time = e.target.value;
                    return copy;
                  })
                }
                className="border p-2 rounded w-28"
              />
              <select
                value={fix.status}
                onChange={(e) =>
                  setNewFixes((prev) => {
                    const copy = [...prev];
                    copy[i].status = e.target.value;
                    return copy;
                  })
                }
                className="border p-2 rounded"
              >
                <option value="IN">IN</option>
                <option value="OUT">OUT</option>
              </select>
            </div>
          ))}

          <button
            onClick={handleAddFix}
            className="text-sm text-blue-600 underline mt-1"
          >
            + Add Fix
          </button>
        </div>

        <div className="flex justify-end space-x-2 mt-6">
          <button
            onClick={handleApply}
            disabled={loading}
            className="bg-[#606bbf] text-white px-4 py-2 rounded hover:bg-[#4e54a6]"
          >
            ✅ Apply
          </button>
          <button onClick={onClose} className="text-gray-600 px-4 py-2 hover:underline">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ManualFixForm;
