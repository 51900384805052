import React from 'react';
import { FiDownload, FiFileText } from 'react-icons/fi';

// Use dynamic imports with public folder paths for PDFs
const files = [
  { name: 'Direct Unvented', description: 'Sketch Pad for a Direct Unvented Cylinder.', size: '861KB', path: '/assets/sketchpad/direct_standard_sp.pdf' },
  { name: 'Direct Vented', description: 'Sketch Pad for a Direct Vented Cylinder.', size: '844KB', path: '/assets/sketchpad/direct_vented_standard_sp.pdf' },
  { name: 'High Gain Unvented', description: 'Sketch Pad for a High Gain Unvented Cylinder.', size: '864KB', path: '/assets/sketchpad/hg_standard_sp.pdf' },
  { name: 'Heat Pump Unvented', description: 'Sketch Pad for a Heat Pump Unvented Cylinder.', size: '867KB', path: '/assets/sketchpad/hp_standard_sp.pdf' },
  { name: 'Indirect Unvented', description: 'Sketch Pad for a Indirect Unvented Cylinder.', size: '877KB', path: '/assets/sketchpad/indirect_standard_sp.pdf' },
  { name: 'Indirect Vented', description: 'Sketch Pad for a Indirect Vented Cylinder.', size: '854KB', path: '/assets/sketchpad/indirect_vented_sp.pdf' },
  { name: 'Indirect Horizontal Unvented', description: 'Sketch Pad for a Horizontal Indirect Unvented Cylinder.', size: '888KB', path: '/assets/sketchpad/indirect_horiz_sp.pdf' },
  { name: 'Indirect Solar Unvented', description: 'Sketch Pad for a Solar Indirect Unvented Cylinder.', size: '882KB', path: '/assets/sketchpad/indirect_solar_sp.pdf' },
];

export default function Downloads() {
  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <header className="mb-8 text-center">
        <h1 className="text-4xl font-bold text-[#606bbf] mb-2">Download Sketch Pads</h1>
        <p className="text-lg text-gray-600">Find and download sketch pads for designing custom cylinders.</p>
      </header>

      <div className="max-w-4xl mx-auto space-y-6">
        {files.map((file, index) => (
          <div
            key={index}
            className="bg-white shadow-lg rounded-lg p-6 flex items-center justify-between transition hover:shadow-xl hover:scale-105"
          >
            <div className="flex items-center space-x-4">
              <FiFileText className="text-4xl text-[#606bbf]" />
              <div>
                <h2 className="text-xl font-semibold text-gray-800">{file.name}</h2>
                <p className="text-sm text-gray-500">{file.description}</p>
                <p className="text-xs text-gray-400 mt-1">{file.size}</p>
              </div>
            </div>

            <a
              href={file.path}
              target="_blank"
              rel="noopener noreferrer"
              download
              className="px-4 py-2 text-white bg-[#606bbf] hover:bg-[#4c54a1] rounded-lg flex items-center space-x-2 transition"
            >
              <FiDownload />
              <span>Download</span>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}
