import React, { useState, useEffect } from "react";
import { getFirestore, collection, getDocs, doc, getDoc, query, where, Timestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const DespatchView = () => {
  const firestore = getFirestore();
  const navigate = useNavigate();

  // States
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [availableDates, setAvailableDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [searchSerial, setSearchSerial] = useState("");

  useEffect(() => {
    const fetchReports = async () => {
      setLoading(true);
      try {
        const despatchReportsRef = collection(firestore, "despatchreports");
        const snap = await getDocs(despatchReportsRef);

        const reportList = snap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setReports(reportList);

        // Extract unique available dates
        const uniqueDates = [
          ...new Set(reportList.map((r) => new Date(r.createdAt.seconds * 1000).toDateString())),
        ];
        setAvailableDates(uniqueDates);
      } catch (error) {
        console.error("Error fetching reports:", error);
      }
      setLoading(false);
    };

    fetchReports();
  }, []);

  // Filter reports when a date is selected
  const filterByDate = async (selected) => {
    setSelectedDate(selected);
    setLoading(true);

    try {
      const despatchReportsRef = collection(firestore, "despatchreports");
      const startDate = new Date(selected);
      startDate.setHours(0, 0, 0, 0);

      const endDate = new Date(selected);
      endDate.setHours(23, 59, 59, 999);

      // Query reports created on the selected date
      const q = query(
        despatchReportsRef,
        where("createdAt", ">=", Timestamp.fromDate(startDate)),
        where("createdAt", "<=", Timestamp.fromDate(endDate))
      );

      const snap = await getDocs(q);
      const filteredReports = snap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setReports(filteredReports);
    } catch (error) {
      console.error("Error filtering reports:", error);
    }

    setLoading(false);
  };

  // Search reports by Serial Number
  const searchBySerial = async () => {
    if (!searchSerial.trim()) return;

    setLoading(true);
    setReports([]);

    try {
      const despatchReportsRef = collection(firestore, "despatchreports");
      const reportsSnap = await getDocs(despatchReportsRef);

      let matchedReports = [];

      for (let report of reportsSnap.docs) {
        const detailsRef = doc(firestore, "despatchreportsdetailed", report.id);
        const detailsSnap = await getDoc(detailsRef);

        if (detailsSnap.exists()) {
          const items = detailsSnap.data().items || [];
          const found = items.some((item) => item.serialNumber.includes(searchSerial));

          if (found) {
            matchedReports.push({
              id: report.id,
              ...report.data(),
            });
          }
        }
      }

      setReports(matchedReports);
      if (matchedReports.length === 0) {
        alert("No reports found for this Serial Number.");
      }
    } catch (error) {
      console.error("Error searching by serial number:", error);
    }

    setLoading(false);
  };

  return (
    <div className="min-h-screen flex flex-col items-center p-6">
      <h1 className="text-2xl font-semibold mb-4">Despatch Reports</h1>

      {/* Date Selection Dropdown */}
      <div className="mb-6 w-full max-w-2xl">
        <label className="block text-gray-700 font-medium mb-2">Select a Date:</label>
        <select
          value={selectedDate}
          onChange={(e) => filterByDate(e.target.value)}
          className="w-full p-3 border rounded-md focus:ring-2 focus:ring-[#606bbf] focus:outline-none"
        >
          <option value="">-- Choose a Date --</option>
          {availableDates.map((date, index) => (
            <option key={index} value={date}>
              {date}
            </option>
          ))}
        </select>
      </div>

      {/* Serial Number Search */}
      <div className="mb-6 flex space-x-2 w-full max-w-2xl">
        <input
          type="text"
          placeholder="Enter Serial Number"
          value={searchSerial}
          onChange={(e) => setSearchSerial(e.target.value)}
          className="w-full p-3 border rounded-md focus:ring-2 focus:ring-[#606bbf] focus:outline-none"
        />
        <button
          onClick={searchBySerial}
          className="px-4 py-3 bg-[#606bbf] hover:bg-[#4c54a1] text-white rounded-md font-semibold"
        >
          Search
        </button>
      </div>

      {/* Loading State */}
      {loading && <p className="text-blue-500">Loading reports...</p>}

      {/* Reports Table */}
      {reports.length > 0 ? (
        <div className="overflow-x-auto w-full max-w-3xl">
          <table className="min-w-full border border-gray-200 text-sm">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-4 py-2 border-b">Report #</th>
                <th className="px-4 py-2 border-b">Created At</th>
                <th className="px-4 py-2 border-b">Status</th>
              </tr>
            </thead>
            <tbody>
              {reports.map((report) => (
                <tr
                  key={report.id}
                  className="text-center cursor-pointer hover:bg-gray-100"
                  onClick={() => navigate(`/DespatchDetailView/${report.id}`)}
                >
                  <td className="px-4 py-2 border-b">{report.reportNumber}</td>
                  <td className="px-4 py-2 border-b">
                    {new Date(report.createdAt.seconds * 1000).toLocaleDateString()}
                  </td>
                  <td className="px-4 py-2 border-b">
                    {report.completed ? "Completed" : "Pending"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        !loading && <p className="text-gray-500">No reports found.</p>
      )}
    </div>
  );
};

export default DespatchView;
