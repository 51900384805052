import { useState, useEffect, useMemo } from "react";
import { db } from "../firebase/firebaseConfig";
import { collection, query, where, getDocs, orderBy, doc, getDoc, Timestamp } from "firebase/firestore";
import UserCard from "./UserCard";

const ClockInOverview = ({ setView }) => {
  const [clockIns, setClockIns] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [missedCount, setMissedCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const today = new Date().toISOString().split("T")[0];
        const q = query(
          collection(db, "clock-ins"),
          where("timestamp", ">=", `${today} 00:00:00`),
          where("timestamp", "<=", `${today} 23:59:59`),
          orderBy("timestamp")
        );
        const snapshot = await getDocs(q);
        const clockData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        const overviewSnapshot = await getDocs(collection(db, "clock-in-overview"));
        const overviewMap = {};
        overviewSnapshot.docs.forEach((doc) => {
          overviewMap[doc.id] = {
            name: doc.data().name || `Person-${doc.id.slice(0, 4)}`,
            department: doc.data().department || "Other",
            in_status: doc.data().in_status || false,
          };
        });

        setClockIns(clockData);
        setUserDetails(overviewMap);
      } catch (err) {
        console.error("❌ Fetch error:", err);
      }
      setLoading(false);
    };

    const fetchMissed = async () => {
      const docRef = doc(db, "missed-signouts", "latest");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setMissedCount(data?.users?.length || 0);
      }
    };

    fetchData();
    fetchMissed();
  }, []);

  const totalIn = useMemo(() => Object.values(userDetails).filter((u) => u.in_status).length, [userDetails]);
  const totalOut = useMemo(() => Object.values(userDetails).filter((u) => !u.in_status).length, [userDetails]);
  const currentlyOut = useMemo(() => Object.entries(userDetails).filter(([_, u]) => !u.in_status), [userDetails]);

  const groupedByDepartment = useMemo(() => {
    const result = {};
    Object.entries(userDetails).forEach(([uid, info]) => {
      const dept = info.department || "Other";
      if (!result[dept]) result[dept] = [];
      result[dept].push({ uid, ...info });
    });
    return result;
  }, [userDetails]);

  return (
    <div className="p-4 sm:p-6 bg-gray-100 min-h-screen">
      {/* Header + Widgets */}
      <div className="mb-6">
        <h1 className="text-3xl font-bold text-gray-800 mb-4">📊 Clock-In Overview</h1>

        <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
          <div className="bg-[#606bbf] text-white p-5 rounded-xl shadow-md">
            <h2 className="text-sm uppercase font-semibold">Total IN</h2>
            <p className="text-3xl font-bold mt-2">{totalIn}</p>
          </div>
          <div className="bg-red-500 text-white p-5 rounded-xl shadow-md">
            <h2 className="text-sm uppercase font-semibold">Total OUT</h2>
            <p className="text-3xl font-bold mt-2">{totalOut}</p>
          </div>
          <div className="bg-white p-5 rounded-xl shadow border">
            <h2 className="text-sm font-semibold text-gray-700">Currently Out the Building</h2>
            <ul className="mt-2 space-y-1 text-sm text-gray-800">
              {currentlyOut.slice(0, 7).map(([uid, user]) => (
                <li key={uid}>• {user.name}</li>
              ))}
            </ul>
            {currentlyOut.length > 7 && (
              <button
                className="mt-2 text-sm text-blue-600 underline"
                onClick={() => setView("roll-call")}
              >
                ...see more
              </button>
            )}
          </div>
          <div className="bg-yellow-200 p-5 rounded-xl shadow border">
            <h2 className="text-sm font-semibold text-yellow-800">Missed Clock-Outs</h2>
            <p className="text-xl font-bold text-yellow-900 mt-2">
              {missedCount > 0 ? `⚠️ ${missedCount} missed` : "✅ None"}
            </p>
            {missedCount > 0 && (
              <button
                className="mt-2 text-sm text-blue-700 underline"
                onClick={() => setView("manual-fix")}
              >
                Resolve now
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Grouped Users */}
      {loading ? (
        <p className="text-center text-gray-600">Loading data...</p>
      ) : (
        Object.entries(groupedByDepartment).map(([dept, users]) => (
          <div key={dept} className="mb-6">
            <h2 className="text-lg font-semibold text-gray-700 mb-2">{dept}</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {users.map((user) => (
                <UserCard
                  key={user.uid}
                  user={user}
                  logs={[]}
                  simple
                />
              ))}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default ClockInOverview;
