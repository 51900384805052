import React, { useState } from "react";
import FavouriteButton from "./FavouriteButton";

const HotWaterCylinderSizeCalculator = () => {
  // Liters used per single fixture event
  const BASE_SHOWER = 45;
  const BASE_BASIN = 10;
  const BASE_SINK = 25;

  // State
  const [showerPerHour, setShowerPerHour] = useState(0);  // How many showers/hour
  const [basinPerHour, setBasinPerHour] = useState(0);    // How many basin uses/hour
  const [sinkPerHour, setSinkPerHour] = useState(0);      // How many sink uses/hour

  const [usageType, setUsageType] = useState("low");      // Demand multiplier
  const [operatingHours, setOperatingHours] = useState(1);
  const [recoveryTime, setRecoveryTime] = useState(2);    // hours

  // Results
  const [cylinderSize, setCylinderSize] = useState("");
  const [requiredKW, setRequiredKW] = useState("");
  const [dailyUsage, setDailyUsage] = useState("");

  const calculateSizeAndRecovery = () => {
    // Demand multiplier
    const multiplier =
      usageType === "constant" ? 1.5 : usageType === "high" ? 1.2 : 1;

    // Hourly usage for each fixture (peak hour)
    const hourlyShowerLiters = showerPerHour * BASE_SHOWER;
    const hourlyBasinLiters = basinPerHour * BASE_BASIN;
    const hourlySinkLiters = sinkPerHour * BASE_SINK;

    // Peak Hourly Usage (with usage multiplier)
    const peakHourUsage =
      (hourlyShowerLiters + hourlyBasinLiters + hourlySinkLiters) * multiplier;

    // Total Daily Usage
    const totalDailyUsage = peakHourUsage * operatingHours;

    // Cylinder typically sized to handle the peak hour
    // Recovery Rate Calculation (Temperature rise fixed at 50°C)
    // kW = (peakHourUsage * 50 / 860) / recoveryTime
    const requiredEnergy = (totalDailyUsage * 50) / 860;
    const kwRequired = requiredEnergy / recoveryTime;

    setCylinderSize(`${Math.round(peakHourUsage)} litres (peak hour)`);
    setDailyUsage(`${Math.round(totalDailyUsage)} litres per day`);
    setRequiredKW(`${kwRequired.toFixed(2)} kW`);
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center p-6">
      {/* Favourite Button */}
      <FavouriteButton pageUrl={window.location.pathname} />

      {/* Card Container */}
      <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-2xl border border-gray-300 mt-8">
        <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">
          Commercial Hot Water Calculator
        </h2>

        {/* Grid Layout for Inputs */}
        <div className="grid grid-cols-2 gap-4 mb-4">
          {/* Showers per Hour */}
          <div>
            <label className="block text-gray-700 mb-1 font-medium">
              Showers per Hour:
            </label>
            <input
              type="number"
              value={showerPerHour}
              onChange={(e) => setShowerPerHour(Number(e.target.value))}
              className="w-full p-2 border rounded-md focus:ring-2 focus:ring-primary focus:outline-none"
            />
          </div>

          {/* Basin per Hour */}
          <div>
            <label className="block text-gray-700 mb-1 font-medium">
              Basin Uses per Hour:
            </label>
            <input
              type="number"
              value={basinPerHour}
              onChange={(e) => setBasinPerHour(Number(e.target.value))}
              className="w-full p-2 border rounded-md focus:ring-2 focus:ring-primary focus:outline-none"
            />
          </div>

          {/* Sink per Hour */}
          <div>
            <label className="block text-gray-700 mb-1 font-medium">
              Sink Uses per Hour:
            </label>
            <input
              type="number"
              value={sinkPerHour}
              onChange={(e) => setSinkPerHour(Number(e.target.value))}
              className="w-full p-2 border rounded-md focus:ring-2 focus:ring-primary focus:outline-none"
            />
          </div>

          {/* Operating Hours */}
          <div>
            <label className="block text-gray-700 mb-1 font-medium">
              Operating Hours (per day):
            </label>
            <input
              type="number"
              value={operatingHours}
              onChange={(e) => setOperatingHours(Number(e.target.value))}
              className="w-full p-2 border rounded-md focus:ring-2 focus:ring-primary focus:outline-none"
            />
          </div>
        </div>

        {/* Usage Type */}
        <div className="mb-4">
          <label className="block text-gray-700 mb-1 font-medium">
            Usage Type:
          </label>
          <select
            value={usageType}
            onChange={(e) => setUsageType(e.target.value)}
            className="w-full p-2 border rounded-md focus:ring-2 focus:ring-primary focus:outline-none"
          >
            <option value="low">Low Demand (Offices, Shops)</option>
            <option value="high">High Demand (Hotels, Restaurants)</option>
            <option value="constant">Constant (Gyms, Hospitals)</option>
          </select>
        </div>

        {/* Recovery Time Slider */}
        <div className="mb-6">
          <label className="block text-gray-700 mb-2 font-medium">
            Desired Recovery Time (Hours): {recoveryTime}h
          </label>
          <input
            type="range"
            min="0.5"
            max="6"
            step="0.5"
            value={recoveryTime}
            onChange={(e) => setRecoveryTime(Number(e.target.value))}
            className="w-full"
          />
          <p className="text-sm text-gray-500 mt-1">
            Adjust how quickly you want the cylinder to reheat from cold.
          </p>
        </div>

        {/* Calculate Button */}
        <button
          onClick={calculateSizeAndRecovery}
          className="w-full bg-primary text-white py-3 rounded-md hover:bg-indigo-700 transition text-lg font-semibold"
        >
          Calculate Cylinder Size & kW
        </button>

        {/* Results */}
        {(cylinderSize || requiredKW) && (
          <div className="mt-6 text-center space-y-2">
            <p className="text-lg text-gray-700 font-medium">
              <strong>Peak Hour Cylinder Size:</strong> {cylinderSize}
            </p>
            <p className="text-lg text-gray-700 font-medium">
              <strong>Daily Water Usage:</strong> {dailyUsage}
            </p>
            <p className="text-lg text-gray-700 font-medium">
              <strong>Required kW for Recovery:</strong> {requiredKW}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default HotWaterCylinderSizeCalculator;
