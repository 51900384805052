import React, { useState, useEffect, useMemo } from 'react';
import { getFirestore, collection, doc, getDoc, getDocs, updateDoc } from 'firebase/firestore';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import FavouriteButton from './FavouriteButton';

const StockAllocation = () => {
  const [cylinders, setCylinders] = useState([]);
  const [userData, setUserData] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  useEffect(() => {
    const auth = getAuth();
    const firestore = getFirestore();

    const fetchUserData = async () => {
      const userEmail = auth.currentUser?.email;
      if (userEmail) {
        const userDocRef = doc(firestore, "Users", userEmail);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          setUserData(userDoc.data());
        } else {
          console.log("No user data found!");
        }
      }
    };

    const fetchCode = async (productId) => {
      if (!productId || typeof productId !== 'string') {
        return 'N/A';
      }
      const docRef = doc(firestore, 'cyl_info', productId);
      const docSnap = await getDoc(docRef);
      return docSnap.exists() ? docSnap.data().productCode : 'N/A';
    };

    const fetchLocation = async (LocationId) => {
      if (LocationId.length < 5) return LocationId;
      const locationsRef = collection(firestore, 'warehouse_locations');
      const querySnapshot = await getDocs(locationsRef);
      const uniqueId = LocationId.slice(-5);
      const matchingDoc = querySnapshot.docs.find((doc) => doc.data().barcode.endsWith(uniqueId));
      return matchingDoc ? matchingDoc.id : LocationId;
    };

    const fetchData = async () => {
      const cylindersCollection = collection(firestore, 'cylinders_warehouse');
      const querySnapshot = await getDocs(cylindersCollection);

      const cylindersData = await Promise.all(
        querySnapshot.docs.map(async (docSnapshot) => {
          const cylinderData = docSnapshot.data();
          return {
            id: docSnapshot.id,
            ProductCode: await fetchCode(cylinderData.ProductId),
            SerialNumber: cylinderData.SerialNumber,
            Special: cylinderData.Special,
            BookedInTime: cylinderData.BookedInTime,
            LocationId: await fetchLocation(cylinderData.LocationId),
            ProductId: cylinderData.ProductId,
            Stock: cylinderData.Stock ? 'STOCK' : 'ORDER',
            Allocated: cylinderData.Allocated || false,
          };
        })
      );
      setCylinders(cylindersData);
    };

    fetchUserData();
    fetchData();
  }, []);

  const sortData = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    setCylinders((currentCylinders) => {
      return [...currentCylinders].sort((a, b) => {
        if (a[key] < b[key]) {
          return direction === 'ascending' ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    });
  };

  const formatDate = (timestamp) => {
    return timestamp ? new Date(timestamp.seconds * 1000).toLocaleString() : 'N/A';
  };

  const getSpecialValueLabel = (specialValue) => {
    const specialValueMap = {
      '01': 'G1 - Male',
      '02': 'G1 - Female',
      '03': 'G4 - Male',
      '04': 'G4 - Female',
      '05': 'IDF2 - Male',
      '06': 'IDF2 - Female',
      '07': 'IDH1 - Male',
      '08': 'IDH1 - Female',
      '09': 'IDH2 - Male',
      '10': 'IDH2 - Female',
      '49': 'Standard',
      '56': 'None Standard',
      // Add more mappings here as needed
    };

    return specialValueMap[specialValue] || specialValue; // Return the original code if no mapping found
  };

  const filteredCylinders = useMemo(
    () =>
      cylinders.filter(
        (cylinder) =>
          cylinder.ProductCode.toLowerCase().includes(searchQuery.toLowerCase()) ||
          cylinder.SerialNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
          (cylinder.LocationId && cylinder.LocationId.toLowerCase().includes(searchQuery.toLowerCase()))
      ),
    [searchQuery, cylinders]
  );

  const sendEmail = async (cylinderData) => {
    try {
      const { name, Email } = userData || {};
      const emailContent = `
        Serial Number: ${cylinderData.SerialNumber}\n
        Product ID: ${cylinderData.ProductCode}\n
        Allocated by: ${name}\n
        Product Location: ${cylinderData.LocationId}\n
        Do not reply to this email. Reply to ${Email}.
      `;
  
      console.log("Email Content: ", emailContent); // Log the content to verify it's correct
  
      await axios.post('https://europe-west2-peters-help-desk.cloudfunctions.net/sendEmail', {
        to: 'transportadmin@telford-group.com, hortonwood@telford-group.com',
        cc: `${Email}, sfoskett@telford-group.com`, // Use template literals for string interpolation
        subject: 'Stock Allocation Notification',
        text: emailContent, // Use `text` instead of `html` for simplicity
      });
  
    } catch (error) {
      console.error('Error sending email: ', error);
      alert('Failed to send email');
    }
  };

  const handleAllocationChange = async (id, currentStatus) => {
    const firestore = getFirestore();
    const docRef = doc(firestore, 'cylinders_warehouse', id);
    try {
      await updateDoc(docRef, { Allocated: !currentStatus });
      alert('Allocation status updated successfully');

      // Update the local state
      setCylinders((prevCylinders) =>
        prevCylinders.map((cylinder) =>
          cylinder.id === id ? { ...cylinder, Allocated: !currentStatus } : cylinder
        )
      );

      if (!currentStatus) {
        const userConfirmed = window.confirm('Stock has been allocated. Do you want to send an email notification?');
        if (userConfirmed) {
          const cylinderData = cylinders.find(cylinder => cylinder.id === id);
          sendEmail(cylinderData);
        }
      }
    } catch (error) {
      console.error('Error updating document: ', error);
      alert('Failed to update allocation status');
    }
  };

  return (
    <>
      <FavouriteButton pageUrl={window.location.pathname} />
      <div className="min-h-screen flex flex-col items-center bg-gray-100 p-6">
        <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-6xl border border-gray-300">
          <h2 className="text-2xl font-semibold text-center text-gray-800 mb-4">Stock Allocation</h2>

          {/* 🔍 Search Bar */}
          <div className="mb-6 w-full">
            <input
              type="text"
              placeholder="Search by Product Code, Serial Number or Location..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full p-3 border rounded-md focus:ring-2 focus:ring-primary focus:outline-none"
            />
          </div>

          {/* 📊 Table */}
          <div className="overflow-x-auto">
            <table className="w-full bg-white shadow-md rounded-lg">
              <thead className="bg-gray-200 text-gray-700">
                <tr>
                  <th className="p-3 cursor-pointer hover:bg-gray-300" onClick={() => sortData("ProductCode")}>
                    Product Code
                  </th>
                  <th className="p-3 cursor-pointer hover:bg-gray-300" onClick={() => sortData("SerialNumber")}>
                    Serial Number
                  </th>
                  <th className="p-3">Type</th>
                  <th className="p-3 cursor-pointer hover:bg-gray-300" onClick={() => sortData("BookedInTime")}>
                    Booked In
                  </th>
                  <th className="p-3 cursor-pointer hover:bg-gray-300" onClick={() => sortData("LocationId")}>
                    Location
                  </th>
                  <th className="p-3 cursor-pointer hover:bg-gray-300" onClick={() => sortData("Stock")}>
                    Stock Status
                  </th>
                  <th className="p-3 cursor-pointer hover:bg-gray-300" onClick={() => sortData("Allocated")}>
                    Allocated
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredCylinders.map((cylinder) => (
                  <tr key={cylinder.id} className="border-t hover:bg-gray-100 transition">
                    <td className="p-3">{cylinder.ProductCode}</td>
                    <td className="p-3">{cylinder.SerialNumber || "N/A"}</td>
                    <td className="p-3">{cylinder.Special}</td>
                    <td className="p-3">{formatDate(cylinder.BookedInTime)}</td>
                    <td className="p-3">{cylinder.LocationId}</td>
                    <td className="p-3">{cylinder.Stock}</td>
                    <td className="p-3 flex justify-center">
                      <input
                        type="checkbox"
                        checked={cylinder.Allocated}
                        onChange={() => handleAllocationChange(cylinder.id, cylinder.Allocated)}
                        className="form-checkbox h-5 w-5 text-blue-500 focus:ring-primary"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default StockAllocation;
