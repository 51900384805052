import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  updateDoc,
  doc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import FavouriteButton from "./FavouriteButton";

const WarehouseLocationUpdateForm = () => {
  const [cylinders, setCylinders] = useState([]);
  const [filteredCylinders, setFilteredCylinders] = useState([]);
  const [selectedCylinder, setSelectedCylinder] = useState(null);
  const [newLocation, setNewLocation] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "ascending" });

  const firestore = getFirestore();

  const fetchCode = async (productId) => {
    if (!productId || typeof productId !== "string") {
      return "N/A";
    }
    const docRef = doc(firestore, "cyl_info", productId);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? docSnap.data().productCode : "N/A";
  };

  const fetchLocation = async (LocationId) => {
    if (LocationId.length < 5) return LocationId;
    const locationsRef = collection(firestore, "warehouse_locations");
    const querySnapshot = await getDocs(locationsRef);
    const uniqueId = LocationId.slice(-5);
    const matchingDoc = querySnapshot.docs.find((doc) =>
      doc.data().barcode.endsWith(uniqueId)
    );
    return matchingDoc ? matchingDoc.id : LocationId;
  };

  useEffect(() => {
    const fetchData = async () => {
      const cylindersCollection = collection(firestore, "cylinders_warehouse");
      const querySnapshot = await getDocs(cylindersCollection);

      const loadedCylinders = await Promise.all(
        querySnapshot.docs.map(async (docSnapshot) => {
          const data = docSnapshot.data();
          const productCode = await fetchCode(data.ProductId); 
          const locationId = await fetchLocation(data.LocationId);
          return {
            id: docSnapshot.id,
            ProductCode: productCode,
            SerialNumber: data.SerialNumber,
            LocationId: locationId,
            ...data,
          };
        })
      );

      setCylinders(loadedCylinders);
    };

    fetchData();
  }, [firestore]);

  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filtered = cylinders.filter(
      (cylinder) =>
        cylinder.ProductCode.toLowerCase().includes(lowerCaseQuery) ||
        cylinder.SerialNumber.toLowerCase().includes(lowerCaseQuery) ||
        cylinder.LocationId.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredCylinders(filtered);
  }, [searchQuery, cylinders]);

  const handleMoveClick = (cylinder) => {
    setSelectedCylinder(cylinder);
  };

  const handleLocationChange = (event) => {
    setNewLocation(event.target.value);
  };

  const confirmMove = async () => {
    if (selectedCylinder && newLocation) {
      const cylinderRef = doc(firestore, "cylinders_warehouse", selectedCylinder.id);
      await updateDoc(cylinderRef, { LocationId: newLocation });

      setNewLocation("");
      setSelectedCylinder(null);

      // Update local state
      setCylinders((prevCylinders) =>
        prevCylinders.map((cyl) =>
          cyl.id === selectedCylinder.id ? { ...cyl, LocationId: newLocation } : cyl
        )
      );
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const sortData = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });

    setFilteredCylinders((currentCylinders) => {
      return [...currentCylinders].sort((a, b) => {
        if (a[key] < b[key]) {
          return direction === "ascending" ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    });
  };

  return (
    <>
      <FavouriteButton pageUrl={window.location.pathname} />

      <div className="min-h-screen bg-gray-100 flex flex-col items-center p-6">
        <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-5xl border border-gray-300">
          <h1 className="text-2xl font-semibold text-gray-800 mb-6 text-center">
            Move Cylinder
          </h1>

          {/* Search Bar */}
          <div className="mb-4">
            <input
              type="text"
              placeholder="Search by Product Code, Serial Number, or Location..."
              value={searchQuery}
              onChange={handleSearchChange}
              className="w-full p-3 border rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
            />
          </div>

          {/* Table */}
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white shadow-md rounded-lg">
              <thead>
                <tr className="bg-gray-200 text-gray-700 text-left">
                  <th
                    className="p-3 cursor-pointer hover:bg-gray-300"
                    onClick={() => sortData("ProductCode")}
                  >
                    Product Code
                  </th>
                  <th
                    className="p-3 cursor-pointer hover:bg-gray-300"
                    onClick={() => sortData("SerialNumber")}
                  >
                    Serial Number
                  </th>
                  <th
                    className="p-3 cursor-pointer hover:bg-gray-300"
                    onClick={() => sortData("LocationId")}
                  >
                    Current Location
                  </th>
                  <th className="p-3">Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredCylinders.map((cylinder) => (
                  <tr
                    key={cylinder.id}
                    className={`border-t ${
                      selectedCylinder?.id === cylinder.id
                        ? "bg-blue-100"
                        : "hover:bg-gray-100"
                    } transition`}
                  >
                    <td className="p-3">{cylinder.ProductCode}</td>
                    <td className="p-3">{cylinder.SerialNumber}</td>
                    <td className="p-3">{cylinder.LocationId}</td>
                    <td className="p-3">
                      {selectedCylinder?.id === cylinder.id ? (
                        <div className="flex items-center space-x-2">
                          <input
                            type="text"
                            value={newLocation}
                            onChange={handleLocationChange}
                            placeholder="New Location"
                            className="p-2 border rounded-md w-44 focus:ring-2 focus:ring-blue-500"
                          />
                          <button
                            onClick={confirmMove}
                            className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md transition"
                          >
                            Confirm
                          </button>
                        </div>
                      ) : (
                        <button
                          onClick={() => handleMoveClick(cylinder)}
                          className="bg-primary hover:bg-hover text-white px-4 py-2 rounded-md transition"
                        >
                          Move
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default WarehouseLocationUpdateForm;
