// SketchDashboard.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import FavouriteButton from './FavouriteButton';

import punchIcon from './assets/house-turret.png';
import punchviewIcon from './assets/punchview.png';
import uploadIcon from './assets/upload.png';
import barcodeIcon from './assets/barcode.png';


const FactoryDashboard = () => {
  const navigate = useNavigate();

    // Navigation Handlers
    const handleNavigation = (route) => {
      navigate(route);
    };

    return (
      <div className="min-h-screen bg-gray-100 flex flex-col items-center p-6">
        {/* Favorite Button */}
        <FavouriteButton pageUrl={window.location.pathname} />
  
        {/* Page Title */}
        <h1 className="text-4xl font-semibold text-gray-800 mb-8">Factory Dashboard</h1>
  
        {/* Grid Container for Tiles */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-5xl">
          <Tile title="CSV Uploader" icon={uploadIcon} onClick={() => handleNavigation("/CSVUploader")} />
          <Tile title="Punch Print" icon={barcodeIcon} onClick={() => handleNavigation("/DataTable")} />
          <Tile title="Punch Entry" icon={punchIcon} onClick={() => handleNavigation("/StainlessPunch")} />
          <Tile title="View Punch Entries" icon={punchviewIcon} onClick={() => handleNavigation("/StainlessPunchView")} />

        </div>
      </div>
    );
  };
  
// ✅ Reusable Tile Component
const Tile = ({ title, icon, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="group bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition cursor-pointer transform hover:-translate-y-1 flex flex-col items-center justify-center text-center w-full h-48 border border-gray-200"
    >
      <div className="flex justify-center items-center w-20 h-20 bg-gray-200 rounded-full group-hover:bg-primary transition">
        <img src={icon} alt={title} className="h-10 w-10 object-contain" />
      </div>
      <p className="mt-4 text-lg font-semibold text-gray-800 group-hover:text-primary transition">{title}</p>
    </div>
  );
};

export default FactoryDashboard;
