import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FavouriteButton from './FavouriteButton'; // Adjust the path as necessary


const CylinderSelector = () => {
  const [currentQuestionId, setCurrentQuestionId] = useState(1);
  const [path, setPath] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const navigate = useNavigate();
  
  
  const questions = {
    1: {
      question: "Does the customer require Mains Pressure Water from the cylinder?",
      answers: {
        "Yes, Mains Pressure Water": 2,
        "No, Header Tank needed": 3,
        "Unsure": 4,
      },
    },
    2: {
      question: "Can a discharge pipe be fitted in the property?",
      answers: {
        "Yes": 5,
        "No": 6,
        "Unsure": 7,
      },
    },
    3: {
        question: "Would the customer like Copper or Stainless?",
        answers: {
          "Copper": 8,
          "Stainless": 9,
          "Unsure": 10,
        },
      },
    4: {
        question: "Does the customer want their water to come from a header tank in the loft or do they want the water to come from their mains supply and directly feed the cylinder, if the customer doesn't know this then they need to consult their plumber? If the customer is on Borehole water their cylinder will not have any warranty and could potentially leak.",
        answers: {
        },
      },
    5: {
        question: "What heat sources will supply the cylinder?",
        type: "checkbox",
        answers: {
          "Electric Only - Immersions": "A1",
          "Boiler": "B1",
          "Solar PV (Dry)": "C1",
          "Solar Thermal (Wet)": "D1",
          "Air Source Heat Pump": "E1",
          "Ground Source Heat Pump": "F1",
        },
        unsureButton: true,
      },
    6: {
        question: "What heat sources will supply the cylinder?",
        type: "checkbox",
        answers: {
          "Electric Only - Immersions": "A2",
          "Boiler": "B2",
          "Solar PV (Dry)": "C2",
          "Solar Thermal (Wet)": "D2",
        },
        unsureButton: true,
      },
    7: {
        question: "Unvented cylinders require a safety pipe to be installed incase of high pressure in the cylinder. Some thermal stores do not require a discharge pipe and are particularly useful in apartment buildings. If the customer does not know they need to contact their plumber.",
        answers: {
        },
      },
    8: {
        question: "What heat sources will supply the cylinder?",
        type: "checkbox",
        answers: {
          "Electric Only - Immersions": "A3",
          "Boiler": "B3",
          "Solar PV (Dry)": "C3",
          "Solar Thermal (Wet)": "D3",
        },
        unsureButton: true,
      },
    9: {
        question: "What heat sources will supply the cylinder?",
        type: "checkbox",
        answers: {
          "Electric Only - Immersions": "A4",
          "Boiler": "B4",
          "Solar PV (Dry)": "C4",
          "Solar Thermal (Wet)": "D4",
          "Air Source Heat Pump": "E4",
          "Ground Source Heat Pump": "F4",
        },
        unsureButton: true,
      },
      10: {
        question: "The customer needs to decide whether they would like a Copper cylinder or a Stainless Steel cylinder, Copper cylinders are typical for Open-Vented situations however Stainless Steel can work just as well, this is purely a preference and could depend on the quality of the water supply or the required heat sources as Stainless are more compatiable, ultimately if the customer doesn't know they need to contact their plumber.",
        answers: {
        },
      },
// Direct Only or solar pv or both
      11: {
        question: "What size does the customer require?",
        answers: {
          "Less than 90L": 12,
          "90L": 13,
          "125L": 14,
          "150L": 15,
          "170L": 16,
          "200L": 17,
          "250L": 18,
          "300L": 19,
          "Unsure": 100,
        },
      },
// Boiler Coil Only
      20: {
        question: "What size does the customer require?",
        answers: {
          "Less than 90L": 12,
          "90L": 22,
          "125L": 23,
          "150L": 24,
          "170L": 25,
          "200L": 26,
          "250L": 27,
          "300L": 28,
          "400L": 29,
          "500L": 30,
          "Over 500L": 101,
          "Unsure": 100,
        },
      },

      12: {
        question: "The customer requires a cylinder less than 90L, please refer to Technical for support",
        answers: {
        },
      },

      13: {
        question: "TSMD090",
        productPath: "/ActualProduct/TSMD090",
        answers: {
        },
      },

      14: {
        question: "TSMD125",
        productPath: "/ActualProduct/TSMD125",
        answers: {
        },
      },

      15: {
        question: "TSMD150",
        productPath: "/ActualProduct/TSMD150",
        answers: {
        },
      },

      16: {
        question: "TSMD170",
        productPath: "/ActualProduct/TSMD170",
        answers: {
        },
      },

      17: {
        question: "TSMD200",
        productPath: "/ActualProduct/TSMD170",
        answers: {
        },
      },

      18: {
        question: "TSMD250",
        productPath: "/ActualProduct/TSMD250",
        answers: {
        },
      },

      19: {
        question: "TSMD300",
        productPath: "/ActualProduct/TSMD300",
        answers: {
        },
      },

      22: {
        question: "TSMI090",
        productPath: "/ActualProduct/TSMI090",
        answers: {
        },
      },

      23: {
        question: "TSMI125",
        productPath: "/ActualProduct/TSMI125",
        answers: {
        },
      },

      24: {
        question: "TSMI150",
        productPath: "/ActualProduct/TSMI150",
        answers: {
        },
      },

      25: {
        question: "TSMI170",
        productPath: "/ActualProduct/TSMI170",
        answers: {
        },
      },

      26: {
        question: "TSMI200",
        productPath: "/ActualProduct/TSMI200",
        answers: {
        },
      },

      27: {
        question: "TSMI250",
        productPath: "/ActualProduct/TSMI250",
        answers: {
        },
      },

      28: {
        question: "TSMI300",
        productPath: "/ActualProduct/TSMI300",
        answers: {
        },
      },

      29: {
        question: "TSMI400",
        productPath: "/ActualProduct/TSMI400",
        answers: {
        },
      },

      30: {
        question: "TSMI500",
        productPath: "/ActualProduct/TSMI500",
        answers: {
        },
      },

      31: {
        question: "The customer would like an Indirect cylinder with a back up immersion heater for Solar PV, recommend to the customer a secondary immersion, for a back up from the mains power.",
        answers: {
          "Add extra immersion heater": 32,
          "Customer would not like to an added immersion heater": 20,
        }, 
      },

      // Boiler Coil & Solar PV with secondary immersion
      32: {
        question: "What size does the customer require?",
        answers: {
          "Less than 90L": 12,
          "90L": 34,
          "125L": 35,
          "150L": 36,
          "170L": 37,
          "200L": 38,
          "250L": 39,
          "300L": 40,
          "400L": 41,
          "500L": 42,
          "Over 500L": 101,
          "Unsure": 100,
        },
      },

      34: {
        question: "TSMI090-2IHB",
        productPath: "/ActualProduct/TSMI090-2IHB",
        answers: {
        },
      },

      35: {
        question: "TSMI125-2IHB",
        productPath: "/ActualProduct/TSMI125-2IHB",
        answers: {
        },
      },

      36: {
        question: "TSMI150-2IHB",
        productPath: "/ActualProduct/TSMI150-2IHB",
        answers: {
        },
      },

      37: {
        question: "TSMI170-2IHB",
        productPath: "/ActualProduct/TSMI170-2IHB",
        answers: {
        },
      },

      38: {
        question: "TSMI200-2IHB",
        productPath: "/ActualProduct/TSMI200-2IHB",
        answers: {
        },
      },

      39: {
        question: "TSMI250-2IHB",
        productPath: "/ActualProduct/TSMI250-2IHB",
        answers: {
        },
      },

      40: {
        question: "TSMI300-2IHB",
        productPath: "/ActualProduct/TSMI300-2IHB",
        answers: {
        },
      },

      41: {
        question: "TSMI400-2IHB",
        productPath: "/ActualProduct/TSMI400-2IHB",
        answers: {
        },
      },

      42: {
        question: "TSMI500-2IHB",
        productPath: "/ActualProduct/TSMI500-2IHB",
        answers: {
        },
      },

      // Boiler and Solar Coil selected

      43: {
        question: "What size does the customer require?",
        answers: {
          "170L": 44,
          "200L": 45,
          "250L": 46,
          "300L": 47,
          "400L": 48,
          "500L": 49,
          "Over 500L": 101,
          "Unsure": 100,
        },
      },

      // Boiler and Heat Pump

      50: {
        question: "What size does the customer require?",
        answers: {
          "200L": 51,
          "250L": 52,
          "300L": 53,
          "400L": 54,
          "500L": 55,
          "Over 500L": 101,
          "Unsure": 100,
        },
      },

      // Solar and Heat Pump

      56: {
        question: "What size does the customer require?",
        answers: {
          "200L": 57,
          "250L": 58,
          "300L": 59,
          "400L": 60,
          "500L": 61,
          "Over 500L": 101,
          "Unsure": 100,
        },
      },

      // Heat pump and Heat Pump

      62: {
        question: "What size does the customer require?",
        answers: {
          "200L": 63,
          "250L": 64,
          "300L": 65,
          "400L": 66,
          "500L": 67,
          "Over 500L": 101,
          "Unsure": 100,
        },
      },

      98: {
        question: "Heat Sources incompatiable, please refer to Technical if you require further assistance.",
        answers: {
        },
      },

      99: {
        question: "If your customer is unsure on what heat source they have they need to contact their plumber, if the heat source the customer wants does not show in the selections this means the heat source is not compatiable with the type of cylinder the customer requires.",
        answers: {
        },
      },

      100: {
        question: "If your customer is unsure on what size cylinder they require you can refer to the sizing chart or alternatively the customer needs to contact their Plumber",
        answers: {
            "Sizing Chart": 98,
        },
      },

      101: {
        question: "The customer requires a Commercial cylinder, refer to technical for more information.",
        answers: {
            "Sizing Chart": 98,
        },
      },

      150: {
        question: "Too many heat sources selected.",
        answers: {

        },
      },

      151: {
        question: "You have selected Electric only along with another heat source which is not electric only.",
        answers: {

        },
      },


    // Continue structuring your questions similarly...
  };
  
  const selectAnswer = (nextQuestionId) => {
    if (questions[currentQuestionId].type === "checkbox") {
      // For checkbox questions, determine the next question based on selected options
      const nextId = determineNextQuestion();
      if (nextId) {
        setCurrentQuestionId(nextId);
        setPath((prevPath) => [...prevPath, currentQuestionId]);
        setSelectedOptions([]); // Reset selected options for the next multiple-choice question
      }
    } else {
      // For single-choice questions, proceed as before
      setPath((prevPath) => [...prevPath, currentQuestionId]);
      setCurrentQuestionId(nextQuestionId);
    }
  };

  const goBack = () => {
    if (path.length > 0) {
      const prevQuestionId = path[path.length - 1]; // Get the last question from the path
      setCurrentQuestionId(prevQuestionId); // Set it as the current question
      setPath((prevPath) => prevPath.slice(0, -1)); // Remove the last question from the path
    }
  };

  const handleUnsureSelection = () => {
    // Clear any selected checkboxes if necessary
    setSelectedOptions([]);
  
    // Directly set the next question to question 10
    setCurrentQuestionId(99);
  
    // Update the path if you're keeping track of the user's journey through the questions
    setPath((prevPath) => [...prevPath, currentQuestionId]);
  };
  

  const handleCheckboxChange = (option) => {
    setSelectedOptions(prev => {
      if (prev.includes(option)) {
        // Remove option if it was already selected
        return prev.filter(opt => opt !== option);
      } else {
        // Add option if it wasn't selected
        return [...prev, option];
      }
    });
  };
  
  const compatibilityRules = {
    "A1": ["C1"],
    "B1": ["C1", "D1", "E1", "F1"],
    "C1": ["A1", "B1", "D1", "E1", "F1"], // C1 is compatible with all
    "D1": ["B1", "C1", "E1", "F1"],
    "E1": ["B1", "C1", "D1", "F1"],
    "F1": ["B1", "C1", "D1", "E1"]
  };
  
  const specialCompatibilityRules = [
    { condition: ["E1", "F1"], incompatible: ["A1", "B1", "D1"] },
    { condition: ["E1", "B1", "D1"], incompatible: ["F1"] },
    { condition: ["F1", "B1", "D1"], incompatible: ["E1"] }
  ];
  
  const determineNextQuestion = () => {
    // Check special compatibility rules first
    for (let rule of specialCompatibilityRules) {
      if (rule.condition.every(option => selectedOptions.includes(option)) && rule.incompatible.some(option => selectedOptions.includes(option))) {
        // Return to a specific error or incompatibility question, if needed
        return 98; // Example: return to a question indicating incompatibility
      }
    }
  
    // Check general compatibility
    for (let selectedOption of selectedOptions) {
      const compatibleOptions = compatibilityRules[selectedOption] || [];
      const isCompatible = selectedOptions.every(option => compatibleOptions.includes(option) || option === selectedOption);
      if (!isCompatible) {
        // Return to a specific error or incompatibility question, if needed
        return 98; // Adjust based on your application's needs for incompatibility
      }
    }
  
    if (selectedOptions.includes("E1") && selectedOptions.includes("F1")) {
      return 62;
    } else if ((selectedOptions.includes("D1") && selectedOptions.includes("E1")) || (selectedOptions.includes("D1") && selectedOptions.includes("F1"))) {
      return 56;
    } else if ((selectedOptions.includes("B1") && selectedOptions.includes("F1")) || (selectedOptions.includes("B1") && selectedOptions.includes("E1"))) {
      return 50;
    } else if (selectedOptions.includes("B1") && selectedOptions.includes("D1")) {
      return 43;
    } else if (selectedOptions.includes("B1") && selectedOptions.includes("C1")) {
      return 31;
    } else if (selectedOptions.includes("B1")) {
      return 20;
    } else if (selectedOptions.includes("A1") || selectedOptions.includes("C1") || (selectedOptions.includes("A1") && selectedOptions.includes("C1"))) {
      return 11; // This checks for A1 alone, C1 alone, and both A1 and C1
    }
  
    return 100
  };

  return (
    <>
    <FavouriteButton pageUrl={window.location.pathname} />

    <div className="questions">
      <div className="questions-container">
        <p>{questions[currentQuestionId].question}</p>
        {questions[currentQuestionId].productPath && (
          <button onClick={() => navigate(questions[currentQuestionId].productPath)}>
            View Product
          </button>
        )}
        <div>
          {questions[currentQuestionId].type === "checkbox" ?
            // Render checkboxes for a multiple-choice question
            Object.entries(questions[currentQuestionId].answers).map(([answer, value]) => (
              <div className="checkbox-container" key={value}>
                <input
                  type="checkbox"
                  id={value}
                  checked={selectedOptions.includes(value)}
                  onChange={() => handleCheckboxChange(value)}
                />
                <label htmlFor={value}>{answer}</label>
              </div>
            ))
            :
            // Render buttons for a single-choice question
            Object.entries(questions[currentQuestionId].answers).map(([answer, nextQuestionId]) => (
              <button key={answer} onClick={() => selectAnswer(nextQuestionId)}>
                {answer}
              </button>
            ))
          }
        </div>
        
        {questions[currentQuestionId].unsureButton && (
          <button onClick={handleUnsureSelection}>
            Unsure
          </button>
        )}
        <br /> <br />
        {path.length > 0 && (
          <button className="back-button" onClick={goBack}>Back</button>
        )}
        {questions[currentQuestionId].type === "checkbox" && (
          <button onClick={() => selectAnswer()}>Next</button>
        )}
      </div>
    </div>
  </>
  );
};

export default CylinderSelector;