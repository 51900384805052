import { useEffect, useState } from "react";
import { db } from "../firebase/firebaseConfig";
import { collectionGroup, getDocs, getDoc, doc } from "firebase/firestore";
import { format } from "date-fns";
import ManualFixForm from "./ManualFixForm";
import { getAuth } from "firebase/auth";

const ProblemClocking = () => {
  const [issues, setIssues] = useState([]);
  const [selectedIssue, setSelectedIssue] = useState(null);
  const [adminUser, setAdminUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      setAdminUser({
        email: user.email,
        name: user.displayName || user.email,
      });
    }
  }, []);

  useEffect(() => {
    const fetchAndDetect = async () => {
      const snapshot = await getDocs(collectionGroup(db, "entries"));
      const allLogs = snapshot.docs.map((doc) => doc.data());

      const byUser = {};
      allLogs.forEach((entry) => {
        const date = entry.timestamp.split(" ")[0];
        if (!byUser[entry.uid]) byUser[entry.uid] = {};
        if (!byUser[entry.uid][date]) byUser[entry.uid][date] = [];
        byUser[entry.uid][date].push(entry);
      });

      const today = format(new Date(), "yyyy-MM-dd");
      const foundIssues = [];

      for (const [uid, dates] of Object.entries(byUser)) {
        const userSnap = await getDoc(doc(db, "clock-in-overview", uid));
        const name = userSnap.exists() ? userSnap.data().name : `Person-${uid.slice(0, 4)}`;

        for (const [date, logs] of Object.entries(dates)) {
          if (date === today) continue;

          const sorted = logs.sort((a, b) => a.timestamp.localeCompare(b.timestamp));
          let last = null;

          for (let i = 0; i < sorted.length; i++) {
            const curr = sorted[i];

            if (curr.status === last?.status) {
              foundIssues.push({ uid, name, date, message: `⚠️ Repeated "${curr.status}"`, timestamp: curr.timestamp });
            }

            if (curr.status === "IN" && !sorted[i + 1]) {
              foundIssues.push({ uid, name, date, message: `⚠️ Clocked IN but never OUT`, timestamp: curr.timestamp });
            }

            last = curr;
          }
        }
      }

      setIssues(foundIssues);
    };

    fetchAndDetect();
  }, []);

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-2xl font-bold mb-4">❗ Clocking Issues</h1>

      {issues.length === 0 ? (
        <p className="text-green-700 font-semibold">✅ No issues detected</p>
      ) : (
        <table className="w-full bg-white rounded shadow text-sm">
          <thead className="bg-red-100 text-red-900 font-semibold">
            <tr>
              <th className="p-3 text-left">User</th>
              <th className="p-3 text-left">UID</th>
              <th className="p-3 text-left">Date</th>
              <th className="p-3 text-left">Time</th>
              <th className="p-3 text-left">Issue</th>
              <th className="p-3 text-left">Fix?</th>
            </tr>
          </thead>
          <tbody>
            {issues.map((issue, idx) => (
              <tr key={idx} className="border-b hover:bg-red-50">
                <td className="p-3">{issue.name}</td>
                <td className="p-3">{issue.uid}</td>
                <td className="p-3">{issue.date}</td>
                <td className="p-3">{issue.timestamp.split(" ")[1]}</td>
                <td className="p-3 text-red-600">{issue.message}</td>
                <td className="p-3">
                  <button
                    onClick={() => setSelectedIssue(issue)}
                    className="text-sm text-blue-600 underline"
                  >
                    Fix
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {selectedIssue && adminUser && (
        <ManualFixForm
          issue={selectedIssue}
          adminUser={adminUser}
          onClose={() => setSelectedIssue(null)}
        />
      )}
    </div>
  );
};

export default ProblemClocking;
