import React, { useState, useEffect, useMemo } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  doc,
  updateDoc,
  addDoc,
  deleteDoc,
  Timestamp,
} from "firebase/firestore";

const DeadStockView = () => {
  const [items, setItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "ascending" });
  const firestore = getFirestore();

  useEffect(() => {
    fetchDeadStock();
  }, []);

  const fetchDeadStock = async () => {
    const warehouseRef = collection(firestore, "cylinders_warehouse");
    const warehouseSnap = await getDocs(warehouseRef);

    const itemsData = await Promise.all(
      warehouseSnap.docs.map(async (snap) => {
        const data = snap.data();
        const productCode = await getProductCode(data.ProductId);
        const location = data.LocationId;

        const relevantTimestamp = data.lastChecked || data.BookedInTime;
        const ageInDays = getAgeInDays(relevantTimestamp);
        const color =
          ageInDays >= 35
            ? "bg-red-200"
            : ageInDays >= 21
            ? "bg-yellow-200"
            : "";

        return {
          id: snap.id,
          SerialNumber: data.SerialNumber,
          ProductCode: productCode,
          Location: location,
          Timestamp: relevantTimestamp,
          Age: ageInDays,
          Color: color,
          FullData: data,
        };
      })
    );

    setItems(itemsData);
  };

  const getProductCode = async (productId) => {
    if (!productId) return "N/A";
    const productRef = doc(firestore, "cyl_info", productId);
    const snap = await getDoc(productRef);
    return snap.exists() ? snap.data().productCode : "N/A";
  };

  const getAgeInDays = (timestamp) => {
    if (!timestamp || !timestamp.seconds) return 0;
    const ms = new Date().getTime() - timestamp.seconds * 1000;
    return Math.floor(ms / (1000 * 60 * 60 * 24));
  };

  const handleStillHere = async (itemId) => {
    await updateDoc(doc(firestore, "cylinders_warehouse", itemId), {
      lastChecked: Timestamp.now(),
    });
    fetchDeadStock();
  };

  const handleNotHere = async (item) => {
    const docRef = doc(firestore, "cylinders_warehouse", item.id);
    await addDoc(collection(firestore, "warehouse_archive"), {
      ...item.FullData,
      BookedOutTime: Timestamp.now(),
    });
    await deleteDoc(docRef);
    fetchDeadStock();
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedItems = useMemo(() => {
    let sortable = [...items];
    if (sortConfig.key !== null) {
      sortable.sort((a, b) => {
        const valA = a[sortConfig.key];
        const valB = b[sortConfig.key];

        if (typeof valA === "string") {
          return sortConfig.direction === "ascending"
            ? valA.localeCompare(valB)
            : valB.localeCompare(valA);
        } else {
          return sortConfig.direction === "ascending" ? valA - valB : valB - valA;
        }
      });
    }
    return sortable;
  }, [items, sortConfig]);

  const filteredItems = useMemo(() => {
    return sortedItems.filter((item) => {
      const query = searchQuery.toLowerCase();
      return (
        item.SerialNumber?.toLowerCase().includes(query) ||
        item.ProductCode?.toLowerCase().includes(query) ||
        item.Location?.toLowerCase().includes(query)
      );
    });
  }, [searchQuery, sortedItems]);

  return (
    <div className="p-6 max-w-7xl mx-auto">
      <h1 className="text-2xl font-semibold mb-4">Dead Stock</h1>

      {/* 🔍 Search Input */}
      <input
        type="text"
        placeholder="Search by Serial, Product Code, or Location..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="w-full max-w-md mb-4 p-2 border rounded-md focus:ring-2 focus:ring-blue-500"
      />

      <table className="min-w-full border border-gray-300">
        <thead className="bg-gray-100">
          <tr>
            {[
              { key: "SerialNumber", label: "Serial" },
              { key: "ProductCode", label: "Product Code" },
              { key: "Location", label: "Location" },
              { key: "Timestamp", label: "Last Seen" },
              { key: "Age", label: "Age (days)" },
            ].map(({ key, label }) => (
              <th
                key={key}
                className="p-2 text-left cursor-pointer hover:bg-gray-200"
                onClick={() => handleSort(key)}
              >
                {label}
                {sortConfig.key === key && (sortConfig.direction === "ascending" ? " ↑" : " ↓")}
              </th>
            ))}
            <th className="p-2 text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredItems.map((item) => (
            <tr key={item.id} className={item.Color}>
              <td className="p-2">{item.SerialNumber}</td>
              <td className="p-2">{item.ProductCode}</td>
              <td className="p-2">{item.Location}</td>
              <td className="p-2">
                {item.Timestamp?.seconds
                  ? new Date(item.Timestamp.seconds * 1000).toLocaleDateString()
                  : "N/A"}
              </td>
              <td className="p-2">{item.Age}</td>
              <td className="p-2 space-x-2">
                <button
                  onClick={() => handleStillHere(item.id)}
                  className="bg-green-500 text-white px-3 py-1 rounded"
                >
                  Still Here
                </button>
                <button
                  onClick={() => handleNotHere(item)}
                  className="bg-red-500 text-white px-3 py-1 rounded"
                >
                  Not Here
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DeadStockView;
