import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import pagesIndex from './pagesIndex.json';

const searchPages = (query, pages) => {
  if (!query) return [];
  const lowerCaseQuery = query.toLowerCase().split(" ");

  return pages
    .map(page => {
      const titleMatch = page.title.toLowerCase().includes(lowerCaseQuery.join(" ")) ? 2 : 0;
      const descriptionMatch = page.description.toLowerCase().includes(lowerCaseQuery.join(" ")) ? 1 : 0;
      const keywordMatches = page.keywords.filter(keyword =>
        lowerCaseQuery.some(word => keyword.toLowerCase().includes(word))
      ).length;

      return {
        ...page,
        relevance: titleMatch + descriptionMatch + keywordMatches
      };
    })
    .filter(page => page.relevance > 0)
    .sort((a, b) => b.relevance - a.relevance);
};

const SearchPage = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);

  const handleSearch = () => {
    const searchResults = searchPages(query, pagesIndex);
    setResults(searchResults);
  };

  return (
    <div className="helpdesk-search-container">
      <h1>Search Page</h1>
      <div className="helpdesk-search-bar">
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search..."
        />
        <button onClick={handleSearch}>Search</button>
      </div>
      <div className="helpdesk-search-results">
        {results.map((result, index) => (
          <div key={index} className="helpdesk-search-result">
            <h3>{result.title}</h3>
            <p>{result.description}</p>
            <Link to={result.link}>Go to page</Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchPage;
