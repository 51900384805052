import React, { useState, useEffect, useRef } from "react";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
  Timestamp,
} from "firebase/firestore";
import FavouriteButton from "./FavouriteButton";

const WarehouseBookingOutForm = () => {
  const [barcode, setBarcode] = useState("");
  const [cylinders, setCylinders] = useState([]);
  const [bgColorClass, setBgColorClass] = useState(""); // For flashing colors (red/green)

  const barcodeInputRef = useRef(null);

  useEffect(() => {
    barcodeInputRef.current.focus();
  }, []);

  // ✅ Only allow digits in the barcode
  const handleBarcodeChange = (e) => {
    const numericOnly = e.target.value.replace(/\D/g, ""); // remove all non-digits
    setBarcode(numericOnly);
  };

  // True if barcode is 13 digits
  const isBarcodeValid = barcode.length === 13;

  // 🔴 Flash red background for invalid input
  const flashRedAndReset = () => {
    setBgColorClass("bg-red-500");
    setTimeout(() => {
      setBgColorClass("");
      resetForm();
    }, 1000);
  };

  // ✅ Flash green background after successful booking out
  const flashGreenAfterSubmit = () => {
    setBgColorClass("bg-green-500");
    setTimeout(() => {
      setBgColorClass("");
      barcodeInputRef.current.focus();
    }, 1000);
  };

  // Resets form fields & focus
  const resetForm = () => {
    setBarcode("");
    setCylinders([]);
    barcodeInputRef.current.focus();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if barcode is valid
    if (!isBarcodeValid) {
      flashRedAndReset();
      return;
    }

    const firestore = getFirestore();
    const serialNumber = barcode.slice(0, 6);
    const productId = barcode.slice(8);

    try {
      const cylindersCollection = collection(firestore, "cylinders_warehouse");
      const q = query(
        cylindersCollection,
        where("SerialNumber", "==", serialNumber),
        where("ProductId", "==", productId)
      );
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log("No matching document found");
        setCylinders([]);
        // If no doc is found => flash red & reset
        flashRedAndReset();
        return;
      }

      const docs = querySnapshot.docs;
      // If multiple docs found
      if (docs.length > 1) {
        const allSameLocation = docs.every(
          (cyl) => cyl.data().LocationId === docs[0].data().LocationId
        );
        // If all same location => book out automatically
        if (allSameLocation) {
          await bookOutCylinder(docs[0]);
          flashGreenAfterSubmit();
        } else {
          console.log(
            "Multiple cylinders found in different locations, please select one to book out."
          );
          setCylinders(docs); // Let user choose
        }
      } else {
        // Just one doc => book out automatically
        await bookOutCylinder(docs[0]);
        flashGreenAfterSubmit();
      }
    } catch (error) {
      console.error("Error in finding cylinders: ", error);
      flashRedAndReset();
    } finally {
      // Reset the barcode input
      setBarcode("");
      barcodeInputRef.current.focus();
    }
  };

  // Book out the selected cylinder
  const bookOutCylinder = async (cylinderSnapshot) => {
    const firestore = getFirestore();
    const archiveData = {
      ...cylinderSnapshot.data(),
      BookedOutTime: Timestamp.now(),
    };

    // Move doc to warehouse_archive
    await addDoc(collection(firestore, "warehouse_archive"), archiveData);
    // Remove from cylinders_warehouse
    await deleteDoc(doc(firestore, "cylinders_warehouse", cylinderSnapshot.id));

    console.log("Cylinder booked out and archived");
    setCylinders([]); // Clear after successful booking
    setBarcode("");
  };

  // User selects which cylinder to book out from multiple
  const handleSelection = async (id) => {
    const selectedSnapshot = cylinders.find((cylinder) => cylinder.id === id);
    if (selectedSnapshot) {
      await bookOutCylinder(selectedSnapshot);
      flashGreenAfterSubmit();
    }
  };

  return (
    <>
      <FavouriteButton pageUrl={window.location.pathname} />

      {/* Outer container with dynamic background color */}
      <div
        className={`min-h-screen w-full flex flex-col items-center justify-center transition-colors duration-500 ${bgColorClass}`}
      >
        {/* Card Container */}
        <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-md border border-gray-300 mx-4">
          <h1 className="text-xl font-semibold text-gray-800 mb-4">
            Booking Out
          </h1>

          {/* Form */}
          <form onSubmit={handleSubmit}>
            <label
              htmlFor="barcode"
              className="block text-gray-700 font-medium mb-1"
            >
              Scan or Enter 13-Digit Barcode:
            </label>
            <input
              type="text"
              id="barcode"
              name="barcode"
              required
              value={barcode}
              onChange={handleBarcodeChange}
              ref={barcodeInputRef}
              className="mb-4 w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
            />
            {/* Hidden Submit Button */}
            <button type="submit" className="hidden">
              Submit
            </button>
          </form>

          {/* Multiple Cylinder Selection */}
          {cylinders.length > 1 && (
            <div className="mt-4">
              <h2 className="text-md font-medium text-gray-700 mb-2">
                Multiple cylinders found. Select one to book out:
              </h2>
              <div className="space-y-2">
                {cylinders.map((cyl) => (
                  <button
                    key={cyl.id}
                    onClick={() => handleSelection(cyl.id)}
                    className="block w-full text-left bg-gray-100 hover:bg-gray-200 p-2 rounded-md text-gray-700"
                  >
                    Book Out - Location: {cyl.data().LocationId} - Serial:{" "}
                    {cyl.data().SerialNumber}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default WarehouseBookingOutForm;
