import { useState, useEffect } from "react";
import { db } from "../firebase/firebaseConfig";
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";

const UserEdit = () => {
  const [users, setUsers] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [name, setName] = useState("");
  const [department, setDepartment] = useState("");
  const [newDepartment, setNewDepartment] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      const usersSnapshot = await getDocs(collection(db, "clock-in-overview"));
      const usersData = usersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setUsers(usersData);

      // Get unique departments
      const uniqueDepartments = [
        ...new Set(usersData.map((user) => user.department || "Other")),
      ];
      setDepartments(uniqueDepartments);
    };

    fetchUsers();
  }, []);

  const handleSelectUser = (user) => {
    setSelectedUser(user);
    setName(user.name);
    setDepartment(user.department);
    setNewDepartment("");
  };

  const handleSave = async () => {
    if (!selectedUser) return;

    const userRef = doc(db, "clock-in-overview", selectedUser.id);
    await updateDoc(userRef, {
      name,
      department: newDepartment || department, // Use new department if entered
    });

    alert("User updated successfully!");
    window.location.reload();
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-2xl font-semibold text-gray-800 mb-4">Edit Users</h1>

      {/* User List */}
      <ul className="bg-white p-4 rounded-lg shadow">
        {users.map((user) => (
          <li key={user.id} className="p-3 border-b flex justify-between">
            <span>{user.name} ({user.department})</span>
            <button
              onClick={() => handleSelectUser(user)}
              className="px-3 py-1 bg-blue-500 text-white rounded"
            >
              Edit
            </button>
          </li>
        ))}
      </ul>

      {/* Edit Section */}
      {selectedUser && (
        <div className="bg-white p-4 rounded-lg shadow mt-4">
          <h2 className="text-lg font-semibold text-gray-700 mb-2">Edit {selectedUser.name}</h2>

          <label className="block text-gray-700">Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-2 border rounded mb-2"
          />

          <label className="block text-gray-700">Department:</label>
          <select
            value={department}
            onChange={(e) => setDepartment(e.target.value)}
            className="w-full p-2 border rounded mb-2"
          >
            {departments.map((dept) => (
              <option key={dept} value={dept}>{dept}</option>
            ))}
          </select>

          <input
            type="text"
            placeholder="Or enter new department"
            value={newDepartment}
            onChange={(e) => setNewDepartment(e.target.value)}
            className="w-full p-2 border rounded mb-2"
          />

          <button
            onClick={handleSave}
            className="w-full bg-green-500 text-white p-2 rounded mt-2"
          >
            Save
          </button>
        </div>
      )}
    </div>
  );
};

export default UserEdit;
