import React, { useState, useEffect } from 'react';
import FavouriteButton from './FavouriteButton';
import { db } from '../firebase/firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { useParams, useNavigate } from 'react-router-dom';

const convertToFraction = (value) => {
  const fractionMappings = {
    "0.5": "1/2",
    "1.75": "1 3/4",
    // Add more mappings as needed
  };
  return fractionMappings[value.toString()] || value;
};

const ActualProduct = () => {
  const { productCode } = useParams();
  const [productData, setProductData] = useState(null);
  const [partsData, setPartsData] = useState(null);
  const [isDimensionsOpen, setIsDimensionsOpen] = useState(false);
  const [isPartsOpen, setIsPartsOpen] = useState(false); // New state for Specifications accordion
  const [isProInfoOpen, setIsProInfoOpen] = useState(false); // New state for ProInfo Information accordion
  const navigate = useNavigate();

  const navigateToPart = (link) => {
    navigate(`/components/parts/${link}`);
  };


  useEffect(() => {
    const fetchProductData = async () => {
      const docRef = doc(db, 'dimensions', productCode);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setProductData(docSnap.data());
      } else {
        console.log("No such document!");
      }
    };

    const fetchPartsData = async () => {
      const docRef = doc(db, 'parts', productCode); // Assuming parts are also indexed by productCode
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setPartsData(docSnap.data());
      } else {
        console.log("No such document in parts!");
      }
    };

    if (productCode) {
      fetchProductData();
      fetchPartsData();
    }
  }, [productCode]);


  const sortedData = () => {
    if (!productData) return [];
  
    // Define keys that have priority and should always appear first, if present.
    const priorityKeys = ["Height", "Diameter"];
  
    // Extract priority data while ensuring it's not undefined, "NULL", or the "description".
    const priorityData = priorityKeys.map(key => ([key, productData[key]]))
    .filter(([key, value]) => value !== undefined && value !== "NULL");
  
    // Filter out the "description", priority keys, and "NULL" values for the remaining data.
    // Additionally, sort the remaining entries by their numeric values where applicable.
    const remainingData = Object.entries(productData)
      .filter(([key, value]) => 
      key !== "description" &&
      key !== "imageName" &&
      !priorityKeys.includes(key) && 
      value !== "NULL" && 
      (!(typeof value === 'object') || value.value !== "NULL"))
      .sort(([, valueA], [, valueB]) => {
        // Parse the values to numbers for sorting, if they are objects with a 'value' property or plain values.
        const numA = typeof valueA === 'object' && valueA.value ? parseFloat(valueA.value) : parseFloat(valueA);
        const numB = typeof valueB === 'object' && valueB.value ? parseFloat(valueB.value) : parseFloat(valueB);
        return numA - numB; // Perform an ascending sort based on these numeric values.
      });
  
    // Combine the prioritized "Height" and "Diameter" data with the sorted remaining data.
    return [...priorityData, ...remainingData];
  };
  
  const toggleDimensions = () => setIsDimensionsOpen(!isDimensionsOpen); // Renamed for clarity
  const toggleParts = () => setIsPartsOpen(!isPartsOpen); // New toggle function
  const toggleProInfo = () => setIsProInfoOpen(!isProInfoOpen); // New toggle function


  return (
    
    <>
    <FavouriteButton pageUrl={window.location.pathname} />

    <div className="container">
      <div className="sidebar">
      <img src={process.env.PUBLIC_URL + `/assets/pictures/${productData?.imageName || 'default.png'}`} alt="Product" />
        <h2>{productCode}</h2>
        <p>{productData?.description || 'Product Description'}</p>
        <button>Datasheet</button>
        
      </div>


      <div className="product-accordion-container">
      <section>
        <div className="product-accordion" onClick={toggleDimensions}>
          <div className="product-accordion-header">
            <h3>Dimensions</h3>
            <span className={`accordion-toggle ${isDimensionsOpen ? 'open' : ''}`}>▼</span>
          </div>
          {isDimensionsOpen && (
            <div className="product-accordion-content">
              <table>
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Value (mm)</th>
                    <th>Size</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedData().map(([key, value], index) => {
                    const isObject = typeof value === 'object' && value !== null;
                    const displayValue = isObject ? convertToFraction(value.value) : convertToFraction(value);
                    const displaySize = isObject && value.size ? value.size : '';

                    if(displayValue === "NULL") return null;

                    return (
                      <tr key={index}>
                        <td>{key}</td>
                        <td>{displayValue}</td>
                        <td>{displaySize}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          
          )}
      </div>
</section>

<section>
  <div className="product-accordion" onClick={toggleParts}>
    <div className="product-accordion-header">
      <h3>Parts Supplied</h3>
      <span className={`accordion-toggle ${isPartsOpen ? 'open' : ''}`}>▼</span>
    </div>
    {isPartsOpen && (
      <div className="product-accordion-content">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Info</th>
              <th>Code</th>
              <th>Qty</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(partsData).filter(([_, value]) => value.code !== "NULL").map(([partName, partDetails], index) => (
              <tr key={index} onClick={() => navigateToPart(partDetails.link)} style={{ cursor: 'pointer' }}>
                <td>{partName}</td>
                <td>{partDetails.info}</td>
                <td>{partDetails.code}</td>
                <td>{partDetails.qty}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )}
  </div>
</section>
<section>
      <div className="product-accordion" onClick={toggleProInfo}>
        <div className="product-accordion-header">
          <h3>Product Information</h3>
          <span className={`accordion-toggle ${isProInfoOpen ? 'open' : ''}`}>▼</span>
        </div>
        {isProInfoOpen && (
          <div className="product-accordion-content">
            {/* Product Info information content here */}
          </div>
          )}
        </div>
</section>
        </div>
      </div>
    </>
    

    );
  };

export default ActualProduct;