import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, addDoc, deleteDoc, doc, Timestamp, where, query, setDoc } from 'firebase/firestore';

const DriverLoadList = () => {
  const [loadLists, setLoadLists] = useState([]);

  useEffect(() => {
    const fetchLoadLists = async () => {
      const firestore = getFirestore();
      const loadListsCollection = collection(firestore, "load_lists");
      const loadListsSnapshot = await getDocs(loadListsCollection);

      const loadedLoadLists = loadListsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      setLoadLists(loadedLoadLists);
    };

    fetchLoadLists();
  }, []);

  const handleBookOut = async (loadListId, itemIndex) => {
    const firestore = getFirestore();
    const loadListRef = doc(firestore, "load_lists", loadListId);
    const loadListSnapshot = await getDocs(query(collection(firestore, "load_lists"), where(doc.id, "==", loadListId)));
    const loadListData = loadListSnapshot.docs[0].data();

    const item = loadListData.items[itemIndex];
    await addDoc(collection(firestore, 'warehouse_archive'), {
      ...item,
      BookedOutTime: Timestamp.now(),
    });

    loadListData.items.splice(itemIndex, 1);

    if (loadListData.items.length === 0) {
      await deleteDoc(loadListRef);
      setLoadLists(loadLists.filter(list => list.id !== loadListId));
    } else {
      await setDoc(loadListRef, loadListData);
      setLoadLists(loadLists.map(list => list.id === loadListId ? loadListData : list));
    }

    console.log("Item booked out and moved to warehouse archive");
  };

  return (
    <div className="DriverLoadListBody">
      <div className="loadlistscontainer">
        <h1>Driver Load Lists:</h1>
        {loadLists.map(loadList => (
          <div key={loadList.id} className="loadlist">
            <h3>{loadList.name}</h3>
            {loadList.items.map((item, index) => (
              <div key={index} className="loadlist-item">
                {item.SerialNumber} - {item.ProductId}
                <button onClick={() => handleBookOut(loadList.id, index)}>Book Out</button>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DriverLoadList;
