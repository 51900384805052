import React, { useState, useEffect, useRef } from "react";
import { collection, addDoc, getFirestore, Timestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import FavouriteButton from "./FavouriteButton";

const WarehouseBookingInForm = () => {
  const [barcode, setBarcode] = useState("");
  const [locationBarcode, setLocationBarcode] = useState("");
  const [bgColorClass, setBgColorClass] = useState("");

  const firestore = getFirestore();
  const navigate = useNavigate();

  // Refs
  const barcodeInputRef = useRef(null);
  const locationInputRef = useRef(null);

  useEffect(() => {
    // Focus on barcode input when page loads
    barcodeInputRef.current.focus();
  }, []);

  // Only allow digits in the barcode
  const handleBarcodeChange = (e) => {
    const numericOnly = e.target.value.replace(/\D/g, ""); // strip non-digits
    setBarcode(numericOnly);
  };

  // True if barcode is exactly 13 digits
  const isBarcodeValid = barcode.length === 13;

  // Flash red background for invalid input
  const flashRedAndReset = () => {
    setBgColorClass("bg-red-500");
    setTimeout(() => {
      setBgColorClass("");
      resetForm();
    }, 1500);
  };

  // Flash green background after successful submission
  const flashGreenAfterSubmit = () => {
    setBgColorClass("bg-green-500");
    setTimeout(() => {
      setBgColorClass("");
      barcodeInputRef.current.focus();
    }, 1500);
  };

  // Reset form fields & focus on the barcode
  const resetForm = () => {
    setBarcode("");
    setLocationBarcode("");
    barcodeInputRef.current.focus();
  };

  // Pressing Enter on the barcode field
  const handleBarcodeKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (!isBarcodeValid) {
        // Invalid => flash red
        flashRedAndReset();
        return;
      }
      // If user has special test barcodes
      if (barcode === "1234567891234") {
        navigate("/WarehouseBookingInForm");
      } else if (barcode === "1234567891235") {
        navigate("/WarehouseBookingStock");
      } else {
        locationInputRef.current.focus();
      }
    }
  };

  // Pressing Enter on the location field triggers form submission
  const handleLocationBarcodeKeyDown = (event) => {
    if (event.key === "Enter" && locationBarcode) {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  // Form Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Double-check validity
    if (!isBarcodeValid) {
      flashRedAndReset();
      return;
    }

    // Prepare data
    const serialNumber = barcode.slice(0, 6);
    const special = barcode.slice(6, 8);
    const productId = barcode.slice(8);
    const locationId = locationBarcode;
    const isStock = special === "50";

    const productData = {
      ProductId: productId,
      Special: special,
      SerialNumber: serialNumber,
      LocationId: locationId,
      Allocated: false,
      Stock: isStock,
      Dispatch: false,
      currentlyEditingBy: null,
      BookedInTime: Timestamp.now(),
    };

    try {
      await addDoc(collection(firestore, "cylinders_warehouse"), productData);
      console.log("Data added to Firestore!");
    } catch (error) {
      console.error("Error adding data to Firestore: ", error);
    }

    // Reset form & flash green
    resetForm();
    flashGreenAfterSubmit();
  };

  return (
    <>
      <FavouriteButton pageUrl={window.location.pathname} />

      {/* Outer container with dynamic background color */}
      <div
        className={`min-h-screen w-full flex flex-col items-center justify-center transition-colors duration-500 ${bgColorClass}`}
      >
        {/* Card Container */}
        <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-md border border-gray-300 mx-4">
          <h1 className="text-xl font-semibold text-gray-800 mb-2">
            Booking In
          </h1>

          {/* Form */}
          <form onSubmit={handleSubmit}>
            {/* Barcode Input */}
            <label
              htmlFor="barcode"
              className="block text-gray-700 font-medium mb-1"
            >
              Scan or Enter Barcode:
            </label>
            <input
              type="text"
              id="barcode"
              name="barcode"
              required
              value={barcode}
              onChange={handleBarcodeChange}
              onKeyDown={handleBarcodeKeyDown}
              ref={barcodeInputRef}
              className="mb-4 w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
            />

            {/* Location Barcode */}
            <label
              htmlFor="locationBarcode"
              className="block text-gray-700 font-medium mb-1"
            >
              Location:
            </label>
            <input
              type="text"
              id="locationBarcode"
              name="locationBarcode"
              required
              value={locationBarcode}
              onChange={(e) => setLocationBarcode(e.target.value)}
              onKeyDown={handleLocationBarcodeKeyDown}
              ref={locationInputRef}
              className="mb-4 w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
            />

            {/* Hidden button so 'Enter' can submit the form */}
            <button type="submit" className="hidden">
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default WarehouseBookingInForm;
