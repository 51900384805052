import React, { useState } from 'react';
import { db } from '../firebase/firebaseConfig';
import { getDocs, collection } from 'firebase/firestore';
import { utils, writeFile } from 'xlsx';

const MassDownload = () => {
  const [isLoading, setIsLoading] = useState(false);

  const fetchStocktakeScanned = async () => {
    setIsLoading(true);

    try {
      const snapshot = await getDocs(collection(db, 'stocktake_scanned'));
      const data = snapshot.docs.map((doc) => {
        const docData = doc.data();
        return {
          docId: doc.id,
          LocationId: docData.LocationId ?? '',
          ProductId: docData.ProductId ?? '',
          ScannedTime: docData.ScannedTime?.toDate().toISOString() ?? '',
          SerialNumber: docData.SerialNumber ?? '',
        };
      });

      const ws = utils.json_to_sheet(data);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, 'StocktakeScanned');

      writeFile(wb, 'stocktake_scanned.xlsx');
    } catch (error) {
      console.error('Error downloading Firestore data:', error);
    }

    setIsLoading(false);
  };

  return (
    <div>
      <button onClick={fetchStocktakeScanned} disabled={isLoading}>
        {isLoading ? 'Exporting...' : 'Download Stocktake Scanned'}
      </button>
    </div>
  );
};

export default MassDownload;
