import { useEffect, useState } from "react";
import { db } from "../firebase/firebaseConfig";
import { collection, getDocs } from "firebase/firestore";

const FireRollCall = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchOverview = async () => {
      const snapshot = await getDocs(collection(db, "clock-in-overview"));
      const data = snapshot.docs.map((doc) => ({
        uid: doc.id,
        ...doc.data(),
      }));
      setUsers(data);
    };

    fetchOverview();
  }, []);

  const sortByName = (a, b) => {
    const nameA = (a.name || `Person-${a.uid.slice(0, 4)}`).toLowerCase();
    const nameB = (b.name || `Person-${b.uid.slice(0, 4)}`).toLowerCase();
    return nameA.localeCompare(nameB);
  };

  const usersIn = users.filter((u) => u.in_status).sort(sortByName);
  const usersOut = users.filter((u) => !u.in_status).sort(sortByName);

  const sortedUsers = [...usersIn, ...usersOut];

  return (
    <div className="p-6 min-h-screen bg-white">
      <h1 className="text-2xl font-bold mb-4 text-red-600">🔥 Fire Roll Call</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {sortedUsers.map((user) => (
          <div
            key={user.uid}
            className={`p-4 rounded-xl shadow border-l-4 ${
              user.in_status ? "border-green-500 bg-green-50" : "border-red-500 bg-red-50"
            }`}
          >
            <h2 className="text-lg font-semibold">
              {user.name || `Person-${user.uid.slice(0, 4)}`}
            </h2>
            <p className="text-sm text-gray-600">{user.department || "Unassigned"}</p>
            <span
              className={`inline-block mt-2 px-3 py-1 rounded-full text-sm font-semibold text-white ${
                user.in_status ? "bg-green-500" : "bg-red-500"
              }`}
            >
              {user.in_status ? "IN" : "OUT"}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FireRollCall;
