import React from "react";

const StainlessCylinderSizing = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center p-6">
      {/* Title */}
      <h1 className="text-3xl font-semibold text-gray-800 mb-6 text-center">
         Stainless Standard Cylinders Sizing Table
      </h1>

      {/* Table Container */}
      <div className="overflow-x-auto w-full max-w-5xl bg-white shadow-lg rounded-lg p-6 border border-gray-300">
        <table className="w-full border-collapse">
          {/* Table Header */}
          <thead>
            <tr className="bg-primary text-white text-lg">
              <th colSpan="10" className="py-3 text-center">
                Stainless Standard Sizing Chart (L)
              </th>
            </tr>
            <tr className="bg-gray-200 text-gray-700">
              <th colSpan="10" className="py-2">Height</th>
            </tr>
          </thead>

          {/* Table Body */}
          <tbody>
            <tr className="bg-gray-100">
              <th rowSpan="6" className="py-3 text-center text-gray-700">Width</th>
              <td className="text-center"></td>
              <td className="text-center">700mm (27")</td>
              <td className="text-center">750mm (29")</td>
              <td className="text-center">825mm (32")</td>
              <td className="text-center">900mm (36")</td>
              <td className="text-center">1050mm (42")</td>
              <td className="text-center">1200mm (48")</td>
              <td className="text-center">1500mm (60")</td>
              <td className="text-center">1800mm (71")</td>
            </tr>
            {[
              ["375mm", "69L", "74L", "78L", "88L", "103L", "118L", "157L", "176L"],
              ["400mm", "76L", "81L", "87L", "98L", "114L", "130L", "174L", "196L"],
              ["450mm", "96L", "103L", "110L", "124L", "144L", "165L", "220L", "247L"],
              ["500mm", "119L", "127L", "136L", "153L", "178L", "204L", "272L", "306L"],
              ["600mm", "171L", "183L", "196L", "220L", "257L", "293L", "391L", "440L"],
              
            ].map((row, index) => (
              <tr
                key={index}
                className={`${
                  index % 2 === 0 ? "bg-white" : "bg-gray-100"
                } hover:bg-primary hover:text-white transition`}
              >
                <td className="py-3 px-4 text-center font-medium">{row[0]}</td>
                {row.slice(1).map((value, i) => (
                  <td key={i} className="py-3 px-4 text-center">
                    {value}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StainlessCylinderSizing;
