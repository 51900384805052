import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import FavouritesModal from "./FavouritesModal";

// Import all icons
import helpdeskIcon from "./assets/helpdesk-icon.svg";
import factoryIcon from "./assets/factory.png";
import warehouseIcon from "./assets/warehouse-icon.svg";
import driverIcon from "./assets/van.png";
import sketchIcon from "./assets/sketch-icon.svg";
import salesIcon from "./assets/sales.png";
import adminIcon from "./assets/admin-icon.png";
import noticeIcon from "./assets/notice-board.png";
import returnIcon from "./assets/product-return.png";
import maintenanceIcon from "./assets/wrench.png";

const Dashboard = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/"); // Redirect to the login route
    } catch (error) {
      console.error("Logout Failed:", error);
    }
  };

  const toggleModal = () => {
    console.log("Modal Open:", !isModalOpen); // Debugging log
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      const userEmail = auth.currentUser?.email;
      if (userEmail) {
        const db = getFirestore();
        const userDocRef = doc(db, "Users", userEmail);
        const userDoc = await getDoc(userDocRef);
  
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserData(userData); // Save the user data, including favourites
        } else {
          console.log("No user data found!");
        }
      }
    };
  
    fetchData();
  }, [auth]);
  
  return (
    <div>
      {/* Header */}
      <div className="bg-primary text-white p-6 flex justify-between items-center shadow-md">
        <h1 className="text-2xl font-bold">{userData?.name ? `${userData.name}'s Dashboard` : "Dashboard"}</h1>
        <div className="space-x-4">
          <button onClick={toggleModal} className="bg-gray-200 text-primary px-4 py-2 rounded-lg hover:bg-gray-300 transition">
            Favourites
          </button>
          <button onClick={handleLogout} className="bg-red-500 px-4 py-2 rounded-lg hover:bg-red-600 transition">
            Log Out
          </button>
        </div>
      </div>
  
      {/* Render modal when open */}
      {isModalOpen && <FavouritesModal isOpen={isModalOpen} onClose={toggleModal} favourites={userData?.favourites ?? []} />}


      {/* Widget Grid */}
      <div className="p-6">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {userData && (
            <>
              {userData.helpdesk && <Widget title="Help Desk" icon={helpdeskIcon} onClick={() => navigate("/HelpDeskDashboard")} />}
              {userData.warehouse && <Widget title="Warehouse" icon={warehouseIcon} onClick={() => navigate("/WarehouseDashboard")} />}
              {userData.stocktake && <Widget title="Stock Take" icon={warehouseIcon} onClick={() => navigate("/StocktakeDashboard")} />}
              {userData.despatch && <Widget title="Despatch" icon={driverIcon} onClick={() => navigate("/DespatchDashboard")} />}
              {userData.driver && <Widget title="Driver" icon={driverIcon} onClick={() => navigate("/DeliveryUpload")} />}
              {userData.factory && <Widget title="Factory" icon={factoryIcon} onClick={() => navigate("/FactoryDashboard")} />}
              {userData.sketch && <Widget title="Sketches" icon={sketchIcon} onClick={() => navigate("/SketchDashboard")} />}
              {userData.returns && <Widget title="Returns" icon={returnIcon} onClick={() => navigate("/ReturnsDashboard")} />}
              {userData.maintenance && <Widget title="Maintenance" icon={maintenanceIcon} onClick={() => navigate("/MaintenanceDashboard")} />}
              {userData.sales && <Widget title="Sales" icon={salesIcon} onClick={() => navigate("/SalesDashboard")} />}
              {userData.clock && <Widget title="Clock Ins" icon={adminIcon} onClick={() => navigate("/ClockInDashboard")} />}
              {userData.notice && <Widget title="Notice Board" icon={noticeIcon} onClick={() => navigate("/NoticeDashboard")} />}
              {userData.tablet && <Widget title="Sign In/Out" icon={noticeIcon} onClick={() => navigate("/SignInOutSystem")} />}
              {userData.tabletadmin && <Widget title="Sign In Admin" icon={adminIcon} onClick={() => navigate("/UserEdit")} />}
              {userData.admin && <Widget title="Admin Panel" icon={adminIcon} onClick={() => navigate("/AdminDashboard")} />}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

// **Reusable Widget Component**
const Widget = ({ title, icon, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="flex flex-col items-center bg-white p-4 rounded-lg shadow-md hover:shadow-xl transition cursor-pointer transform hover:-translate-y-1"
    >
      <img src={icon} alt={title} className="h-12 w-12" />
      <p className="mt-2 font-semibold">{title}</p>
    </div>
  );
};

export default Dashboard;
