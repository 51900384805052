// src/components/Quoting.jsx
import React, { useState, useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import stainlessData from "../data/stainlessQuoteData.json";
import copperData from "../data/copperQuoteData.json";
import ventedData from "../data/ventedQuoteData.json";

const Quoting = () => {
  const { type } = useParams();

  // 🟡 Select the appropriate JSON data based on URL
  const quoteData = useMemo(() => {
    switch (type) {
      case "copper":
        return copperData;
      case "vented":
        return ventedData;
      default:
        return stainlessData;
    }
  }, [type]);

  // 🟡 Set Title Dynamically
  const title = useMemo(() => {
    switch (type) {
      case "copper":
        return "Copper Quoting";
      case "vented":
        return "Vented Quoting";
      default:
        return "Stainless (Unvented) Quoting";
    }
  }, [type]);

  // ────────────────────────────────────────────────
  // 1. Firestore "cyl_info" fetching
  // ────────────────────────────────────────────────
  const [allProducts, setAllProducts] = useState([]);
  const [productSearch, setProductSearch] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      const firestore = getFirestore();
      const colRef = collection(firestore, "cyl_info");
      const snapshot = await getDocs(colRef);
      const temp = snapshot.docs.map((d) => ({
        id: d.id,
        ...d.data(),
      }));
      setAllProducts(temp);
    };
    fetchProducts();
  }, []);

  // Search Filter
  const filteredProducts = useMemo(() => {
    if (!productSearch.trim()) return [];
    const lower = productSearch.toLowerCase();
    return allProducts.filter(
      (p) =>
        p.productCode?.toLowerCase().includes(lower) ||
        p.productDescription?.toLowerCase().includes(lower)
    );
  }, [allProducts, productSearch]);

  // ────────────────────────────────────────────────
  // 2. Base Price Handling
  // ────────────────────────────────────────────────
  const [basePricePence, setBasePricePence] = useState(10000); // Default £100
  const handleBasePriceChange = (e) => {
    const val = e.target.value;
    if (/^[0-9]*\.?[0-9]*$/.test(val)) {
      const floatVal = parseFloat(val || "0");
      setBasePricePence(Math.round(floatVal * 100));
    }
  };
  const formatPrice = (pence) => "£" + (pence / 100).toFixed(2);
  const basePriceDisplay = useMemo(() => (basePricePence / 100).toFixed(2), [basePricePence]);

  // ────────────────────────────────────────────────
  // 3. Extras from JSON
  // ────────────────────────────────────────────────
  const [selections, setSelections] = useState({});
  const categories = useMemo(() => {
    const groups = {};
    quoteData.forEach((item) => {
      if (!groups[item.category]) groups[item.category] = [];
      groups[item.category].push(item);
    });
    return groups;
  }, [quoteData]);

  const isSelected = (docid) => (selections[docid] || 0) > 0;

  const isDisabledByConflict = (item) =>
    item.conflict?.some((c) => isSelected(c));

  const handleCheckbox = (item) => {
    setSelections((prev) => {
      const newValue = isSelected(item.docid) ? 0 : 1;
      const updated = { ...prev, [item.docid]: newValue };

      if (newValue > 0 && item.conflict?.length) {
        item.conflict.forEach((conflictId) => {
          updated[conflictId] = 0;
        });
      }
      return updated;
    });
  };

  const extrasTotalPence = useMemo(() => {
    return quoteData.reduce((sum, item) => {
      const qty = selections[item.docid] || 0;
      return sum + item.price * qty;
    }, 0);
  }, [selections, quoteData]);

  const grandTotalPence = basePricePence + extrasTotalPence;

  // ────────────────────────────────────────────────
  // 4. Emailing Logic (Fully Restored 💪)
  // ────────────────────────────────────────────────
  const [leadTime, setLeadTime] = useState("");

  const buildEmailBody = () => {
    const productCode = selectedProduct?.productCode || "Not Selected";
    const productDesc = selectedProduct?.productDescription || "N/A";
    const baseStr = formatPrice(basePricePence);

    // Summarize selected extras
    let extrasList = "";
    quoteData.forEach((item) => {
      const qty = selections[item.docid] || 0;
      if (qty > 0) {
        extrasList += `- ${item.label}`;
        if (item.type === "multiple") {
          extrasList += ` (x${qty})`;
        }
        extrasList += `\n`;
      }
    });

    return `

Product Code: ${productCode}/SKETCH
Product Description: ${productDesc}
Total: ${formatPrice(grandTotalPence)}

Lead Time: ${leadTime || "Not specified"}

Carriage: N/A (If delivered direct to any UK mainland merchant address)

Cylinder Specification:
${extrasList || "None"}

Thank you,
Telford Cylinders Team
    `;
  };

  const handleSendEmail = () => {
    const subject = `Quote for ${selectedProduct?.productCode || "UNKNOWN"}`;
    const body = buildEmailBody();

    const mailtoLink = `mailto:?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  // ────────────────────────────────────────────────
  // 5. Render (Fully Styled with Tailwind 🌟)
  // ────────────────────────────────────────────────
  return (
    <div className="p-6 max-w-4xl mx-auto space-y-8">
      <h1 className="text-4xl font-bold text-gray-800">{title}</h1>

      {/* PRODUCT SEARCH */}
      <div className="space-y-2">
        <label className="block text-lg font-semibold text-gray-700">
          Product Code / Description
        </label>
        <input
          type="text"
          className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring"
          placeholder="Search product codes..."
          value={productSearch}
          onChange={(e) => setProductSearch(e.target.value)}
        />

        {productSearch.trim() && !selectedProduct && (
          <ul className="border border-gray-200 rounded-md bg-white max-h-48 overflow-auto">
            {filteredProducts.map((p) => (
              <li
                key={p.id}
                onClick={() => {
                  setSelectedProduct(p);
                  setProductSearch(p.productCode || "");
                }}
                className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
              >
                <strong>{p.productCode}</strong> — {p.productDescription}
              </li>
            ))}
            {filteredProducts.length === 0 && (
              <li className="px-3 py-2 text-gray-500">No matches found.</li>
            )}
          </ul>
        )}

        {selectedProduct && (
          <p className="text-green-700 text-sm">
            Selected: <strong>{selectedProduct.productCode}</strong> —{" "}
            {selectedProduct.productDescription}
          </p>
        )}
      </div>

      {/* BASE PRICE */}
      <div className="space-y-2">
        <label className="block text-lg font-semibold text-gray-700">
          Base Price (£)
        </label>
        <input
          type="text"
          value={basePriceDisplay}
          onChange={handleBasePriceChange}
          className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring"
        />
        <p className="text-gray-600">Current: {formatPrice(basePricePence)}</p>
      </div>

      {/* EXTRAS */}
      {Object.entries(categories).map(([catName, items]) => (
        <div
          key={catName}
          className="border border-gray-300 rounded-md mb-4 overflow-hidden"
        >
          <div className="bg-gray-100 px-4 py-2 border-b border-gray-300">
            <h2 className="text-xl font-semibold text-gray-800">{catName}</h2>
          </div>
          <div className="p-4 space-y-3">
            {items.map((item) => (
              <div
                key={item.docid}
                className={`flex items-center justify-between ${
                  isDisabledByConflict(item) ? "opacity-50" : ""
                }`}
              >
                <label className="text-gray-700 select-none">
                  {item.label}
                  <span className="ml-2 text-sm text-gray-500">
                    ({formatPrice(item.price)})
                  </span>
                </label>
                <input
                  type="checkbox"
                  className="h-5 w-5 accent-blue-600"
                  checked={isSelected(item.docid)}
                  onChange={() => handleCheckbox(item)}
                />
              </div>
            ))}
          </div>
        </div>
      ))}

      {/* LEAD TIME */}
      <div className="space-y-2">
        <label className="block text-lg font-semibold text-gray-700">
          Lead Time
        </label>
        <input
          type="text"
          className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring"
          placeholder="e.g. 2-3 weeks"
          value={leadTime}
          onChange={(e) => setLeadTime(e.target.value)}
        />
      </div>

      {/* TOTALS */}
      <div className="flex justify-between items-center border-t border-gray-300 pt-4">
        <div className="text-lg font-medium text-gray-700">
          Extras Total:{" "}
          <span className="font-bold">{formatPrice(extrasTotalPence)}</span>
        </div>
        <div className="text-2xl font-bold text-primary">
          Grand Total: {formatPrice(grandTotalPence)}
        </div>
      </div>

      {/* EMAIL BUTTON */}
      <button
        onClick={handleSendEmail}
        className="mt-4 px-6 py-2 bg-primary text-white rounded shadow hover:bg-hover"
      >
        Send Quote via Email
      </button>
    </div>
  );
};

export default Quoting;
