import React, { useState, useMemo } from "react";
import {
  getFirestore,
  collection,
  query,
  where,
  getDoc,
  getDocs,
  doc,
  Timestamp,
} from "firebase/firestore";
import FavouriteButton from "./FavouriteButton";

const WarehouseArchive = () => {
  // ─────────────────────────────────────────────────────────────────────────────
  // 1️⃣ State
  // ─────────────────────────────────────────────────────────────────────────────
  const [cylinders, setCylinders] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);

  // Firestore filter controls
  const [searchType, setSearchType] = useState("serial");  // "serial" or "date"
  const [searchQuery, setSearchQuery] = useState("");      // Serial Number filter
  const [selectedMonth, setSelectedMonth] = useState("");  // YYYY-MM for date

  // Sorting
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "ascending" });

  // **NEW**: Table-level search AFTER Firestore returns data
  const [tableSearch, setTableSearch] = useState(""); // in-memory filter text

  // ─────────────────────────────────────────────────────────────────────────────
  // 2️⃣ Helper: Decode Product & Location
  // ─────────────────────────────────────────────────────────────────────────────
  const fetchProductCode = async (productId) => {
    if (!productId || typeof productId !== "string") {
      return "N/A";
    }
    const firestore = getFirestore();
    const docRef = doc(firestore, "cyl_info", productId);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? docSnap.data().productCode : "N/A";
  };

  const fetchLocationName = async (locationId) => {
    if (!locationId || locationId.length < 5) {
      return locationId || "N/A";
    }
    const firestore = getFirestore();
    const locationsRef = collection(firestore, "warehouse_locations");
    const querySnapshot = await getDocs(locationsRef);

    // E.g., find doc whose 'barcode' ends with the last 5 digits of the locationId
    const uniqueId = locationId.slice(-5);
    const matchingDoc = querySnapshot.docs.find((doc) =>
      doc.data().barcode.endsWith(uniqueId)
    );
    return matchingDoc ? matchingDoc.id : locationId;
  };

  // ─────────────────────────────────────────────────────────────────────────────
  // 3️⃣ Fetch Data on "Search" Click from Firestore
  // ─────────────────────────────────────────────────────────────────────────────
  const handleSearch = async () => {
    setHasSearched(true); // Now we know the user has searched
    const firestore = getFirestore();
    let cylindersRef = collection(firestore, "warehouse_archive");

    // Build Firestore query
    if (searchType === "serial" && searchQuery.trim() !== "") {
      cylindersRef = query(
        cylindersRef,
        where("SerialNumber", "==", searchQuery)
      );
    } else if (searchType === "date" && selectedMonth) {
      const [year, month] = selectedMonth.split("-").map(Number);
      const startDate = new Date(Date.UTC(year, month - 1, 1, 0, 0, 0));
      const endDate = new Date(Date.UTC(year, month, 0, 23, 59, 59));

      const startTimestamp = Timestamp.fromDate(startDate);
      const endTimestamp = Timestamp.fromDate(endDate);

      cylindersRef = query(
        cylindersRef,
        where("BookedOutTime", ">=", startTimestamp),
        where("BookedOutTime", "<=", endTimestamp)
      );
    }

    // Query Firestore
    const querySnapshot = await getDocs(cylindersRef);

    // Decode each record's product code & location
    const decodedData = await Promise.all(
      querySnapshot.docs.map(async (docSnap) => {
        const raw = docSnap.data();
        const decodedProductCode = await fetchProductCode(raw.ProductId);
        const decodedLocation = await fetchLocationName(raw.LocationId);

        return {
          id: docSnap.id,
          ...raw,
          ProductCode: decodedProductCode,
          LocationId: decodedLocation,
        };
      })
    );

    setCylinders(decodedData);
  };

  // ─────────────────────────────────────────────────────────────────────────────
  // 4️⃣ Sorting
  // ─────────────────────────────────────────────────────────────────────────────
  const sortData = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });

    setCylinders((prev) => {
      return [...prev].sort((a, b) => {
        let valueA = a[key];
        let valueB = b[key];

        // If comparing Firestore Timestamps, use .seconds
        if (key === "BookedOutTime" || key === "BookedInTime") {
          valueA = valueA ? valueA.seconds : 0;
          valueB = valueB ? valueB.seconds : 0;
        }

        // Compare as strings/numbers
        if (valueA < valueB) return direction === "ascending" ? -1 : 1;
        if (valueA > valueB) return direction === "ascending" ? 1 : -1;
        return 0;
      });
    });
  };

  // ─────────────────────────────────────────────────────────────────────────────
  // 5️⃣ Format Timestamp for Display
  // ─────────────────────────────────────────────────────────────────────────────
  const formatDate = (timestamp) => {
    return timestamp ? new Date(timestamp.seconds * 1000).toLocaleString() : "N/A";
  };

  // ─────────────────────────────────────────────────────────────────────────────
  // 6️⃣ In-Memory Filtering (ProductCode OR SerialNumber) AFTER Firestore Query
  // ─────────────────────────────────────────────────────────────────────────────
  // We only filter the data we've already fetched & decoded.
  const filteredCylinders = useMemo(() => {
    if (!tableSearch.trim()) {
      return cylinders; // No filter text, return all
    }
    const lowerSearch = tableSearch.toLowerCase();
    return cylinders.filter((cyl) => {
      const serialMatch = cyl.SerialNumber?.toLowerCase().includes(lowerSearch);
      const productMatch = cyl.ProductCode?.toLowerCase().includes(lowerSearch);
      return serialMatch || productMatch;
    });
  }, [cylinders, tableSearch]);

  // ─────────────────────────────────────────────────────────────────────────────
  // 7️⃣ Render
  // ─────────────────────────────────────────────────────────────────────────────
  return (
    <>
      <FavouriteButton pageUrl={window.location.pathname} />

      <div className="p-6 max-w-7xl mx-auto">
        <h1 className="text-3xl font-semibold mb-4">Warehouse Archive</h1>
        <h4 className="text-2l bg-red-100 font-semibold mb-4">Please be patient when using this page</h4>

        {/* ───────────────────────────────────────────────────────────────────── */}
        {/* FIRESTORE FILTERS - CHOOSE BEFORE FETCHING */}
        {/* ───────────────────────────────────────────────────────────────────── */}
        <div className="p-4 border rounded-md mb-6 bg-gray-100">
          <h2 className="text-lg font-medium mb-2">Search Filters</h2>
          <div className="flex flex-col md:flex-row gap-4">
            {/* 1) Search By: Serial or Date */}
            <div>
              <label className="block text-sm font-medium">Search By:</label>
              <select
                value={searchType}
                onChange={(e) => setSearchType(e.target.value)}
                className="p-2 border rounded-md w-full md:w-48"
              >
                <option value="serial">Serial Number</option>
                <option value="date">Booked Out Month</option>
              </select>
            </div>

            {/* 2) Serial Number */}
            {searchType === "serial" && (
              <div>
                <label className="block text-sm font-medium">Serial Number:</label>
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="p-2 border rounded-md w-full md:w-48"
                  placeholder="Enter Serial Number"
                />
              </div>
            )}

            {/* 3) Month Picker */}
            {searchType === "date" && (
              <div>
                <label className="block text-sm font-medium">Select Month:</label>
                <input
                  type="month"
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}
                  className="p-2 border rounded-md w-full md:w-48"
                />
              </div>
            )}

            {/* 4) Search Button (Firestore) */}
            <button
              onClick={handleSearch}
              className="bg-primary text-white px-4 py-2 rounded-md self-end"
            >
              Search
            </button>
          </div>
        </div>

        {/* ───────────────────────────────────────────────────────────────────── */}
        {/*  OPTIONAL IN-MEMORY SEARCH BAR (TABLE SEARCH) */}
        {/* ───────────────────────────────────────────────────────────────────── */}
        {cylinders.length > 0 && (
          <div className="flex flex-col md:flex-row items-start md:items-center mb-4 gap-4">
            <input
              type="text"
              placeholder="Filter results by Serial or Product Code..."
              value={tableSearch}
              onChange={(e) => setTableSearch(e.target.value)}
              className="p-2 border rounded-md w-full md:w-1/2"
            />
          </div>
        )}

        {/* ───────────────────────────────────────────────────────────────────── */}
        {/* Table of Results */}
        {/* ───────────────────────────────────────────────────────────────────── */}
        {hasSearched && cylinders.length === 0 && (
          <p className="text-gray-600 text-center mt-6">
            No records found. Try another search.
          </p>
        )}

        {!hasSearched && cylinders.length === 0 && (
          <p className="text-gray-600 text-center mt-6">
            Please choose a filter and click Search.
          </p>
        )}

        {filteredCylinders.length > 0 && (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-200">
              <thead className="bg-primary border-b border-gray-200">
                <tr>
                  <th
                    className="px-4 py-2 text-left text-sm font-medium text-white cursor-pointer"
                    onClick={() => sortData("SerialNumber")}
                  >
                    Serial Number
                  </th>
                  <th
                    className="px-4 py-2 text-left text-sm font-medium text-white cursor-pointer"
                    onClick={() => sortData("BookedOutTime")}
                  >
                    Booked Out
                  </th>
                  <th
                    className="px-4 py-2 text-left text-sm font-medium text-white cursor-pointer"
                    onClick={() => sortData("BookedInTime")}
                  >
                    Booked In
                  </th>
                  <th
                    className="px-4 py-2 text-left text-sm font-medium text-white cursor-pointer"
                    onClick={() => sortData("ProductCode")}
                  >
                    Product Code
                  </th>
                  <th
                    className="px-4 py-2 text-left text-sm font-medium text-white cursor-pointer"
                    onClick={() => sortData("LocationId")}
                  >
                    Location
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100">
                {filteredCylinders.map((cyl) => (
                  <tr key={cyl.id} className="hover:bg-gray-50">
                    <td className="px-4 py-2 text-sm text-gray-700">
                      {cyl.SerialNumber || "N/A"}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-700">
                      {formatDate(cyl.BookedOutTime)}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-700">
                      {formatDate(cyl.BookedInTime)}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-700">
                      {cyl.ProductCode || "N/A"}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-700">
                      {cyl.LocationId || "N/A"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default WarehouseArchive;
