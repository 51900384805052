// App.jsx
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './firebase/AuthContext';
import React from 'react';
import Modal from 'react-modal';
import LoginForm from './components/Login/LoginForm';
import ForgotPassword from './components/Login/ForgotPassword';
import Dashboard from './components/Dashboard';
import AdminDashboard from './components/AdminDashboard';
import MassUpload from './components/MassUpload';
import WarehouseDashboard from './components/WarehouseDashboard';
import WarehouseView from './components/WarehouseView';
import WarehouseBookingInForm from './components/WarehouseBookingInForm';
import WarehouseBookingStock from './components/WarehouseBookingStock';
import WarehouseBookingOutForm from './components/WarehouseBookingOutForm';
import WarehouseMoveForm from './components/WarehouseMoveForm';
import WarehouseBarcode from './components/WarehouseBarcode';
import ProductPage from './components/ProductPage';
import ProtectedRoute from './firebase/ProtectedRoute';
import EmailTestComponent from './components/EmailTestComponent';
import DuplicateFirestoreForm from './components/DuplicateFirestoreForm';
import ReturnsEditor from './components/ReturnsEditor';
import ActualProduct from './components/ActualProduct';
import ComingSoon from './components/assets/ComingSoon';
import DataTable from './components/DataTable';
import SketchDashboard from './components/SketchDashboard';
import FileUpload from './components/FileUpload';
import LoadListManager from './components/LoadListManager';
import DriverLoadList from './components/DriverLoadList';
import StockAllocation from './components/StockAllocation';
import FileViewer from './components/FileViewer';
import ReturnsDashboard from './components/ReturnsDashboard';
import ReturnsEntry from './components/ReturnsEntry';
import SearchPage from './components/SearchPage';
import SignInOutSystem from './components/SignInOutSystem';
import SignInAdmin from './components/SignInAdmin';
import ReturnsList from './components/ReturnsList';
import SalesDashboard from './components/SalesDashboard';
import PartsPrices from './components/PartsPrices';
import QuoteList from './components/QuoteList';
import CopperQuote from './components/CopperQuote';
import StainlessPunch from './components/StainlessPunch';
import StainlessPunchView from './components/StainlessPunchView';
import StainlessUnventedQuote from './components/StainlessUnventedQuote';
import StainlessVentedQuote from './components/StainlessVentedQuote';
import NoticeDashboard from './components/NoticeDashboard';
import NoticeBoard from './components/NoticeBoard';
import CSVUploader from './components/CSVUploader';
import WarehouseDelete from './components/WarehouseDelete';
import NoticeUpload from './components/NoticeUpload';
import FactoryDashboard from './components/FactoryDashboard';
import DuplicateWarehouse from './components/DuplicateWarehouse';
import HelpDeskDashboard from './components/HelpDeskDashboard';
import CylinderSelector from './components/CylinderSelector';
import Machines from './components/Machines';
import ReorderTable from './components/ReorderTable';
import TechnicalDashboard from './components/TechnicalDashboard';
import TechnicalCalculator from './components/TechnicalCalculator';
import HeatUpCalculator from './components/HeatUpCalculator';
import TimeRequiredCalculator from './components/TimeRequiredCalculator';
import WarehouseArchive from './components/WarehouseArchive';
import CylinderSizingCalculator from './components/CylinderSizingCalculator';
import ExpansionVesselCalculator from './components/ExpansionVesselCalculator';
import PartsList from './components/PartsList';
import MassDownload from './components/MassDownload';
import UpdateStock from './components/UpdateStock';
import EmailForm from './components/EmailForm';
import SalesWarehouse from './components/SalesWarehouse';
import MerchantMap from './components/MerchantMap';
import DeleteManufacturingData from './components/DeleteManufactureData';
import AddProductForm from './components/AddProductForm';
import ProductUpload from './components/ProductUpload';
import DeliveryUpload from './components/DeliveryUpload';
import DeliveryViewer from './components/DeliveryViewer';
import DespatchDashboard from './components/DespatchDashboard';
import MaintenanceDashboard from './components/MaintenanceDashboard';
import JobList from './components/JobList';
import SizingList from './components/SizingList';
import CombiSizing from './components/CombiSizing';
import CylinderSizing from './components/CylinderSizing';
import StainlessCylinderSizing from './components/StainlessCylinderSizing';
import SMSTest from './components/SMSTest';
import Quoting from './components/Quoting';
import DespatchReport from './components/DespatchReport';
import DespatchView from './components/DespatchView';
import DespatchDetailView from './components/DespatchDetailView';
import SketchPads from './components/SketchPads';
import SalesRepSearch from './components/SalesRepSearch';
import DespatchSummary from './components/DespatchSummary';
import ClockInDashboard from './components/ClockInDashboard';
import UserEdit from './components/UserEdit';
import StockComparison from './components/StockComparison';
import StockTakeForm from './components/StockTakeForm';
import StocktakeDashboard from './components/StocktakeDashboard';
import DeadStockView from './components/DeadStockView';

Modal.setAppElement('#root');

const App = () => {
  return (
    <AuthProvider>
    <Router>
      <Routes>
        <Route path="/" element={<LoginForm />} />
        <Route path="/dashboard" element={
        <ProtectedRoute>
        <Dashboard />
        </ProtectedRoute>
        } />
        <Route path="/WarehouseDashboard" element={
            <ProtectedRoute allowedRoles={['warehouse', 'admin']}>
              <WarehouseDashboard />
            </ProtectedRoute>
          } />
          <Route path="/AdminDashboard" element={
            <ProtectedRoute allowedRoles={['admin']}>
              <AdminDashboard />
            </ProtectedRoute>
          } />
          <Route path="/ClockInDashboard" element={
            <ProtectedRoute allowedRoles={['clock', 'admin']}>
              <ClockInDashboard />
            </ProtectedRoute>
          } />
          <Route path="/UserEdit" element={
            <ProtectedRoute allowedRoles={['clock', 'admin']}>
              <UserEdit />
            </ProtectedRoute>
          } />
          <Route path="/StockComparison" element={
            <ProtectedRoute allowedRoles={['stocktake', 'admin']}>
              <StockComparison />
            </ProtectedRoute>
          } />
          <Route path="/StockTakeForm" element={
            <ProtectedRoute allowedRoles={['stocktake', 'admin']}>
              <StockTakeForm />
            </ProtectedRoute>
          } />
          <Route path="/StocktakeDashboard" element={
            <ProtectedRoute allowedRoles={['stocktake', 'admin']}>
              <StocktakeDashboard />
            </ProtectedRoute>
          } />
          <Route path="/AddProductForm" element={
            <ProtectedRoute allowedRoles={['admin']}>
              <AddProductForm />
            </ProtectedRoute>
          } />
          <Route path="/MassDownload" element={
            <ProtectedRoute allowedRoles={['admin']}>
              <MassDownload />
            </ProtectedRoute>
          } />
          <Route path="/EmailForm" element={
            <ProtectedRoute allowedRoles={['admin']}>
              <EmailForm />
            </ProtectedRoute>
          } />
          <Route path="/WarehouseDelete" element={
            <ProtectedRoute allowedRoles={['admin']}>
              <WarehouseDelete />
            </ProtectedRoute>
          } />
          <Route path="/DuplicateFirestoreForm" element={
            <ProtectedRoute allowedRoles={['admin']}>
              <DuplicateFirestoreForm />
            </ProtectedRoute>
          } />
          <Route path="/ComingSoon" element={
            <ProtectedRoute allowedRoles={['admin', 'sales']}>
              <ComingSoon />
            </ProtectedRoute>
          } />
          <Route path="/QuoteList" element={
            <ProtectedRoute allowedRoles={['admin', 'sales']}>
              <QuoteList />
            </ProtectedRoute>
          } />
          <Route path="/SalesRepSearch" element={
            <ProtectedRoute allowedRoles={['admin', 'sales']}>
              <SalesRepSearch />
            </ProtectedRoute>
          } />
          <Route path="/Quoting/:type" element={
            <ProtectedRoute allowedRoles={['admin', 'sales']}>
              <Quoting />
            </ProtectedRoute>
          } />
          <Route path="/DeliveryUpload" element={
            <ProtectedRoute allowedRoles={['admin', 'driver']}>
              <DeliveryUpload />
            </ProtectedRoute>
          } />
          <Route path="/DespatchDashboard" element={
            <ProtectedRoute allowedRoles={['admin', 'despatch']}>
              <DespatchDashboard />
            </ProtectedRoute>
          } />
          <Route path="/DespatchReport" element={
            <ProtectedRoute allowedRoles={['admin', 'despatch']}>
              <DespatchReport />
            </ProtectedRoute>
          } />
          <Route path="/DespatchSummary" element={
            <ProtectedRoute allowedRoles={['admin', 'despatch']}>
              <DespatchSummary />
            </ProtectedRoute>
          } />
          <Route path="/DespatchView" element={
            <ProtectedRoute allowedRoles={['admin', 'despatch']}>
              <DespatchView />
            </ProtectedRoute>
          } />
          <Route path="/DespatchDetailView/:id" element={
            <ProtectedRoute allowedRoles={['admin', 'despatch']}>
              <DespatchDetailView />
            </ProtectedRoute>
          } />
          <Route path="/EmailTestComponent" element={
            <ProtectedRoute allowedRoles={['admin']}>
              <EmailTestComponent />
            </ProtectedRoute>
          } />
          <Route path="/CSVUploader" element={
            <ProtectedRoute allowedRoles={['admin']}>
              <CSVUploader />
            </ProtectedRoute>
          } />
          <Route path="/MaintenanceDashboard" element={
            <ProtectedRoute allowedRoles={['admin', 'maintenance']}>
              <MaintenanceDashboard />
            </ProtectedRoute>
          } />
          <Route path="/DataTable" element={
            <ProtectedRoute allowedRoles={['admin']}>
              <DataTable />
            </ProtectedRoute>
          } />
          <Route path="/DuplicateWarehouse" element={
            <ProtectedRoute allowedRoles={['admin']}>
              <DuplicateWarehouse />
            </ProtectedRoute>
          } />
          <Route path="/DeleteManufactureData" element={
            <ProtectedRoute allowedRoles={['admin']}>
              <DeleteManufacturingData />
            </ProtectedRoute>
          } />
          <Route path="/SMSTest" element={
            <ProtectedRoute allowedRoles={['admin']}>
              <SMSTest />
            </ProtectedRoute>
          } />
          <Route path="/MerchantMap" element={
            <ProtectedRoute allowedRoles={['admin', 'sales']}>
              <MerchantMap />
            </ProtectedRoute>
          } />
          <Route path="/SalesWarehouse" element={
            <ProtectedRoute allowedRoles={['admin', 'sales']}>
              <SalesWarehouse />
            </ProtectedRoute>
          } />
          <Route path="/StockAllocation" element={
            <ProtectedRoute allowedRoles={['admin', 'internal']}>
              <StockAllocation />
            </ProtectedRoute>
          } />
          <Route path="/ReorderTable" element={
            <ProtectedRoute allowedRoles={['admin', 'internal']}>
              <ReorderTable />
            </ProtectedRoute>
          } />
          <Route path="/ReturnsDashboard" element={
            <ProtectedRoute allowedRoles={['admin', 'returns']}>
              <ReturnsDashboard />
            </ProtectedRoute>
          } />
          <Route path="/SignInOutSystem" element={
            <ProtectedRoute allowedRoles={['admin', 'tablet']}>
              <SignInOutSystem />
            </ProtectedRoute>
          } />
          <Route path="/SignInAdmin" element={
            <ProtectedRoute allowedRoles={['admin', 'tabletadmin']}>
              <SignInAdmin />
            </ProtectedRoute>
          } />
          <Route path="/ReturnsEntry" element={
            <ProtectedRoute allowedRoles={['admin', 'returns']}>
              <ReturnsEntry />
            </ProtectedRoute>
          } />
          <Route path="/ReturnsList" element={
            <ProtectedRoute allowedRoles={['admin', 'returns']}>
              <ReturnsList />
            </ProtectedRoute>
          } />
          <Route path="/SizingList" element={
            <ProtectedRoute allowedRoles={['admin', 'office']}>
              <SizingList />
            </ProtectedRoute>
          } />
          <Route path="/CombiSizing" element={
            <ProtectedRoute allowedRoles={['admin', 'office']}>
              <CombiSizing />
            </ProtectedRoute>
          } />
          <Route path="/CylinderSizing" element={
            <ProtectedRoute allowedRoles={['admin', 'office']}>
              <CylinderSizing />
            </ProtectedRoute>
          } />
          <Route path="/StainlessCylinderSizing" element={
            <ProtectedRoute allowedRoles={['admin', 'office']}>
              <StainlessCylinderSizing />
            </ProtectedRoute>
          } />
          <Route path="/MassUpload" element={
            <ProtectedRoute allowedRoles={['admin']}>
              <MassUpload />
            </ProtectedRoute>
          } />
          <Route path="/UpdateStock" element={
            <ProtectedRoute allowedRoles={['admin']}>
              <UpdateStock />
            </ProtectedRoute>
          } />
          <Route path="/WarehouseBookingInForm" element={
            <ProtectedRoute allowedRoles={['warehouse', 'admin']}>
              <WarehouseBookingInForm />
            </ProtectedRoute>
          } />
          <Route path="/DeliveryViewer" element={
            <ProtectedRoute allowedRoles={['warehouse', 'admin']}>
              <DeliveryViewer />
            </ProtectedRoute>
          } />
          <Route path="/WarehouseBookingStock" element={
            <ProtectedRoute allowedRoles={['warehouse', 'admin']}>
              <WarehouseBookingStock />
            </ProtectedRoute>
          } />
          <Route path="/WarehouseView" element={
            <ProtectedRoute allowedRoles={['warehouse', 'admin']}>
              <WarehouseView />
            </ProtectedRoute>
          } />
          <Route path="/LoadListManager" element={
            <ProtectedRoute allowedRoles={['warehouse', 'admin']}>
              <LoadListManager />
            </ProtectedRoute>
          } />
          <Route path="/DriverLoadList" element={
            <ProtectedRoute allowedRoles={['warehouse', 'admin']}>
              <DriverLoadList />
              </ProtectedRoute>
            } />
          <Route path="/DeadStockView" element={
            <ProtectedRoute allowedRoles={['warehouse', 'admin']}>
              <DeadStockView />
              </ProtectedRoute>
            } />
          <Route path="/WarehouseArchive" element={
            <ProtectedRoute allowedRoles={['admin', 'internal']}>
              <WarehouseArchive />
            </ProtectedRoute>
          } />
          <Route path="/ProductUpload" element={
            <ProtectedRoute allowedRoles={['admin']}>
              <ProductUpload />
            </ProtectedRoute>
          } />
          <Route path="/WarehouseBookingOutForm" element={
            <ProtectedRoute allowedRoles={['warehouse', 'admin']}>
              <WarehouseBookingOutForm />
            </ProtectedRoute>
          } />
          <Route path="/WarehouseBookingOutForm" element={
            <ProtectedRoute allowedRoles={['warehouse', 'admin']}>
              <WarehouseBookingOutForm />
            </ProtectedRoute>
          } />
          <Route path="/WarehouseBarcode" element={
            <ProtectedRoute allowedRoles={['warehouse', 'admin']}>
              <WarehouseBarcode />
            </ProtectedRoute>
          } />
          <Route path="/WarehouseMoveForm" element={
            <ProtectedRoute allowedRoles={['warehouse', 'admin']}>
              <WarehouseMoveForm />
            </ProtectedRoute>
          } />
          <Route path="/ProductPage" element={
            <ProtectedRoute allowedRoles={['admin', 'office']}>
              <ProductPage />
            </ProtectedRoute>
          } />
          <Route path="/CylinderSelector" element={
            <ProtectedRoute allowedRoles={['admin', 'office']}>
              <CylinderSelector />
            </ProtectedRoute>
          } />
          <Route path="/ActualProduct/:productCode" element={
            <ProtectedRoute allowedRoles={['admin', 'office']}>
              <ActualProduct />
            </ProtectedRoute>
          } />
          <Route path="/reports/:id" element={
            <ProtectedRoute allowedRoles={['admin', 'returns']}>
              <ReturnsEditor />
            </ProtectedRoute>
          } />
          <Route path="/HelpDeskDashboard" element={
            <ProtectedRoute allowedRoles={['admin', 'office']}>
              <HelpDeskDashboard />
            </ProtectedRoute>
          } />
          <Route path="/TechnicalDashboard" element={
            <ProtectedRoute allowedRoles={['admin', 'office']}>
              <TechnicalDashboard />
            </ProtectedRoute>
          } />
          <Route path="/SearchPage" element={
            <ProtectedRoute allowedRoles={['admin', 'office']}>
              <SearchPage />
            </ProtectedRoute>
          } />
          <Route path="/TechnicalCalculator" element={
            <ProtectedRoute allowedRoles={['admin', 'office']}>
              <TechnicalCalculator />
            </ProtectedRoute>
          } />
          <Route path="/HeatUpCalculator" element={
            <ProtectedRoute allowedRoles={['admin', 'office']}>
              <HeatUpCalculator />
            </ProtectedRoute>
          } />
          <Route path="/ExpansionVesselCalculator" element={
            <ProtectedRoute allowedRoles={['admin', 'office']}>
              <ExpansionVesselCalculator />
            </ProtectedRoute>
          } />
          <Route path="/CylinderSizingCalculator" element={
            <ProtectedRoute allowedRoles={['admin', 'office']}>
              <CylinderSizingCalculator />
            </ProtectedRoute>
          } />
          <Route path="/TimeRequiredCalculator" element={
            <ProtectedRoute allowedRoles={['admin', 'office']}>
              <TimeRequiredCalculator />
            </ProtectedRoute>
          } />
          <Route path="/PartsList" element={
            <ProtectedRoute allowedRoles={['admin', 'office']}>
              <PartsList />
            </ProtectedRoute>
          } />
          <Route path="/FactoryDashboard" element={
            <ProtectedRoute allowedRoles={['admin', 'factory', 'manager']}>
              <FactoryDashboard />
            </ProtectedRoute>
          } />
          <Route path="/StainlessPunch" element={
            <ProtectedRoute allowedRoles={['admin', 'manager', 'factory']}>
              <StainlessPunch />
            </ProtectedRoute>
          } />
          <Route path="/StainlessPunchView" element={
            <ProtectedRoute allowedRoles={['admin', 'manager']}>
              <StainlessPunchView />
            </ProtectedRoute>
          } />
          <Route path="/SketchDashboard" element={
            <ProtectedRoute allowedRoles={['admin', 'sketch']}>
              <SketchDashboard />
            </ProtectedRoute>
          } />
          <Route path="/NoticeDashboard" element={
            <ProtectedRoute allowedRoles={['admin', 'notice']}>
              <NoticeDashboard />
            </ProtectedRoute>
          } />
          <Route path="/Machines" element={
            <ProtectedRoute allowedRoles={['admin', 'maintenance']}>
              <Machines />
            </ProtectedRoute>
          } />
          <Route path="/JobList" element={
            <ProtectedRoute allowedRoles={['admin', 'maintenance', 'operator']}>
              <JobList />
            </ProtectedRoute>
          } />
          <Route path="/NoticeBoard" element={
            <ProtectedRoute allowedRoles={['admin', 'notice']}>
              <NoticeBoard />
            </ProtectedRoute>
          } />
          <Route path="/NoticeUpload" element={
            <ProtectedRoute allowedRoles={['admin', 'notice']}>
              <NoticeUpload />
            </ProtectedRoute>
          } />
          <Route path="/FileViewer" element={
            <ProtectedRoute allowedRoles={['admin', 'sketch']}>
              <FileViewer />
            </ProtectedRoute>
          } />
          <Route path="/FileUpload" element={
            <ProtectedRoute allowedRoles={['admin', 'sketch']}>
              <FileUpload />
            </ProtectedRoute>
          } />
          <Route path="/SalesDashboard" element={
            <ProtectedRoute allowedRoles={['admin', 'sales']}>
              <SalesDashboard />
            </ProtectedRoute>
          } />
          <Route path="/PartsPrices" element={
            <ProtectedRoute allowedRoles={['admin', 'sales']}>
              <PartsPrices />
            </ProtectedRoute>
          } />
          <Route path="/QuoteList" element={
            <ProtectedRoute allowedRoles={['admin', 'sales']}>
              <QuoteList />
            </ProtectedRoute>
          } />
          <Route path="/SketchPads" element={
            <ProtectedRoute allowedRoles={['admin', 'sales']}>
              <SketchPads />
            </ProtectedRoute>
          } />
          <Route path="/CopperQuote" element={
            <ProtectedRoute allowedRoles={['admin', 'sales']}>
              <CopperQuote />
            </ProtectedRoute>
          } />
          <Route path="/StainlessVentedQuote" element={
            <ProtectedRoute allowedRoles={['admin', 'sales']}>
              <StainlessVentedQuote />
            </ProtectedRoute>
          } />
          <Route path="/StainlessUnventedQuote" element={
            <ProtectedRoute allowedRoles={['admin', 'sales']}>
              <StainlessUnventedQuote />
            </ProtectedRoute>
          } />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        {/* Other routes go here */}
      </Routes>
    </Router>
    </AuthProvider>
  );
};

export default App;