import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

const ProductPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const products = [
    { id: "TSMD", code: "TSMD", description: "Tempest Stainless Unvented Direct", category: "Tempest Direct - (TSMD)", imageUrl: "/images/TSMD090.jpg" },
    { id: "TSMD125_info", code: "TSMD125", description: "Tempest Stainless Unvented Indirect", category: "Tempest Direct - (TSMD)", imageUrl: "/images/TSMD125.jpg" },
    // Add more products with imageUrl
  ];

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredProducts = products.filter(product => {
    const code = product.code || '';
    const description = product.description || '';
    const category = product.category || '';
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return (
      code.toLowerCase().includes(lowerCaseSearchTerm) ||
      description.toLowerCase().includes(lowerCaseSearchTerm) ||
      category.toLowerCase().includes(lowerCaseSearchTerm)
    );
  });

  const categories = [...new Set(filteredProducts.map(product => product.category))];
  const productsByCategory = categories.map(category => ({
    category,
    items: filteredProducts.filter(product => product.category === category)
  }));

  const handleProductClick = (productCode) => {
    navigate(`/ActualProduct/${productCode}`);
  };

  return (
    <div className="product-page">
      <h1>Product Catalogue</h1>
      <input
        type="text"
        placeholder="Search products..."
        onChange={handleSearchChange}
        className="search-input"
      />

      {productsByCategory.map(({ category, items }) => (
        <div key={category} className="category-section">
          <h2>{category}</h2>
          <div className="product-grid">
            {items.map(item => (
              <div key={item.id} className="product-card" onClick={() => handleProductClick(item.code)}>
                <img src={item.imageUrl} alt={item.description} className="product-image" />
                <div className="product-details">
                  <h3>{item.code}</h3>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductPage;
