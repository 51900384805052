import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig';
import FavouriteButton from './FavouriteButton';

function PartsTable() {
  const [allParts, setAllParts] = useState([]); // Store all parts as an array
  const [searchTerm, setSearchTerm] = useState(''); // Search term state

  useEffect(() => {
    const fetchParts = async () => {
      const q = query(collection(db, "partsPrices"), orderBy("category"));
      const querySnapshot = await getDocs(q);
      const parts = [];
      
      querySnapshot.forEach((doc) => {
        parts.push(doc.data());
      });

      setAllParts(parts);
    };

    fetchParts();
  }, []);

  // Filter parts based on the search term
  const filteredParts = allParts.filter((part) =>
    part.desc.toLowerCase().includes(searchTerm) ||
    part.code.toLowerCase().includes(searchTerm)
  );

  // Group the filtered parts by category
  const partsByCategory = filteredParts.reduce((acc, part) => {
    if (!acc[part.category]) {
      acc[part.category] = [];
    }
    acc[part.category].push(part);
    return acc;
  }, {});

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  return (
    <>
      <FavouriteButton pageUrl={window.location.pathname} />
      <div className="min-h-screen bg-gray-100 flex flex-col items-center p-6">
        <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-5xl border border-gray-300">
          <h2 className="text-2xl font-semibold text-center text-gray-800 mb-4">
            Parts List
          </h2>

          {/* Search Bar */}
          <div className="mb-6">
            <input
              type="text"
              placeholder="Search by description or code..."
              onChange={handleSearchChange}
              className="w-full p-3 border rounded-md focus:ring-2 focus:ring-primary focus:outline-none"
            />
          </div>

          {/* Parts Table */}
          {Object.keys(partsByCategory).map((category) => (
            <div key={category} className="mb-8">
              <h3 className="text-lg font-bold text-white bg-primary p-2 rounded-md text-center mb-2">
                {category}
              </h3>
              
              <div className="overflow-x-auto">
                <table className="w-full bg-white shadow-md rounded-lg">
                  <thead className="bg-gray-200 text-gray-700">
                    <tr>
                      <th className="p-3 text-left">Code</th>
                      <th className="p-3 text-left">Description</th>
                      <th className="p-3 text-left">Merchant</th>
                      <th className="p-3 text-left">Retail Customer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {partsByCategory[category].map((part, index) => (
                      <tr 
                        key={index}
                        className="border-t hover:bg-gray-100 transition"
                      >
                        <td className="p-3">{part.code}</td>
                        <td className="p-3">{part.desc}</td>
                        <td className="p-3">£{part.costexvat}</td>
                        <td className="p-3">£{part.costincvat}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default PartsTable;
