// WeeklyView.jsx — With Full Clock History + Flag for Missing Clock Outs (Excludes Today)
import { useEffect, useState } from "react";
import {
  collectionGroup,
  getDocs,
  query,
  where,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import {
  startOfWeek,
  endOfWeek,
  format,
  addDays,
  isToday
} from "date-fns";

const WeeklyView = () => {
  const [selectedUser, setSelectedUser] = useState("");
  const [users, setUsers] = useState([]);
  const [weekStart, setWeekStart] = useState(startOfWeek(new Date(), { weekStartsOn: 1 }));
  const [weekData, setWeekData] = useState([]);
  const [userInfo, setUserInfo] = useState(null);

  const formattedStart = format(weekStart, "yyyy-MM-dd");
  const formattedEnd = format(endOfWeek(weekStart, { weekStartsOn: 1 }), "yyyy-MM-dd");

  useEffect(() => {
    const fetchUsers = async () => {
      const snapshot = await getDocs(collectionGroup(db, "clock-in-overview"));
      const userList = snapshot.docs.map((doc) => ({
        uid: doc.id,
        ...doc.data(),
      }));
      setUsers(userList);
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchEntries = async () => {
      if (!selectedUser) return;
      const weekDates = Array.from({ length: 7 }).map((_, i) =>
        format(addDays(weekStart, i), "yyyy-MM-dd")
      );

      const entriesRef = collectionGroup(db, "entries");
      const q = query(
        entriesRef,
        where("uid", "==", selectedUser),
        where("timestamp", ">=", `${formattedStart} 00:00:00`),
        where("timestamp", "<=", `${formattedEnd} 23:59:59`)
      );

      const snapshot = await getDocs(q);
      const logs = snapshot.docs.map((doc) => doc.data());

      const grouped = weekDates.map((date) => {
        const entries = logs
          .filter((e) => e.timestamp.startsWith(date))
          .sort((a, b) => a.timestamp.localeCompare(b.timestamp));

        const isCurrentDay = isToday(new Date(date));
        const pairs = [];

        for (let i = 0; i < entries.length; i++) {
          if (entries[i].status === "IN") {
            const inTime = entries[i].timestamp.split(" ")[1];
            let outTime = null;
            let missed = false;

            if (entries[i + 1] && entries[i + 1].status === "OUT") {
              outTime = entries[i + 1].timestamp.split(" ")[1];
              i++;
            } else {
              outTime = format(new Date(), "HH:mm:ss") + " (LIVE)";
              missed = !isCurrentDay;
            }

            pairs.push({ in: inTime, out: outTime, missed });
          }
        }

        return {
          date,
          logs: pairs,
        };
      });

      setWeekData(grouped);

      const userSnap = await getDoc(doc(db, "clock-in-overview", selectedUser));
      if (userSnap.exists()) setUserInfo(userSnap.data());
    };

    fetchEntries();
  }, [selectedUser, weekStart]);

  const totalHours = weekData.reduce((acc, day) => {
    const total = day.logs.reduce((sum, log) => {
      const isLive = log.out.includes("(LIVE)");
      const [h1, m1] = log.in.split(":");
      const [h2, m2] = isLive
        ? format(new Date(), "HH:mm").split(":")
        : log.out.split(":");
      const s = new Date(0, 0, 0, h1, m1);
      const e = new Date(0, 0, 0, h2, m2);
      const diff = (e - s) / 3600000;
      return sum + (diff > 0 ? diff : 0);
    }, 0);
    return acc + total;
  }, 0);

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-2xl font-bold mb-4">📅 Weekly Overview</h1>

      <div className="flex flex-col md:flex-row gap-4 mb-6">
        <select
          value={selectedUser}
          onChange={(e) => setSelectedUser(e.target.value)}
          className="p-2 border rounded shadow-sm"
        >
          <option value="">Select User</option>
          {users.map((u) => (
            <option key={u.uid} value={u.uid}>
              {u.name || u.uid.slice(0, 6)}
            </option>
          ))}
        </select>

        <input
          type="date"
          value={formattedStart}
          onChange={(e) => setWeekStart(startOfWeek(new Date(e.target.value), { weekStartsOn: 1 }))}
          className="p-2 border rounded shadow-sm"
        />
      </div>

      {userInfo && (
        <div className="bg-white p-4 rounded-xl shadow mb-6">
          <h2 className="text-lg font-semibold">{userInfo.name}</h2>
          <p className="text-sm text-gray-600">Expected hours: {userInfo.expected_hours_per_week || 37.5} hrs/week</p>
        </div>
      )}

      <table className="w-full text-sm bg-white shadow rounded-xl overflow-hidden">
        <thead className="bg-gray-200 text-gray-700">
          <tr>
            <th className="p-3 text-left">Date</th>
            <th className="p-3 text-center">Activity</th>
            <th className="p-3 text-center">Total</th>
          </tr>
        </thead>
        <tbody>
          {weekData.map((day) => {
            const total = day.logs.reduce((sum, log) => {
              const isLive = log.out.includes("(LIVE)");
              const [h1, m1] = log.in.split(":");
              const [h2, m2] = isLive
                ? format(new Date(), "HH:mm").split(":")
                : log.out.split(":");
              const s = new Date(0, 0, 0, h1, m1);
              const e = new Date(0, 0, 0, h2, m2);
              const diff = (e - s) / 3600000;
              return sum + (diff > 0 ? diff : 0);
            }, 0);

            return (
              <tr key={day.date} className="border-t">
                <td className="p-3 font-medium text-gray-800">{format(new Date(day.date), "EEE dd MMM")}</td>
                <td className="p-3 text-center">
                  {day.logs.length ? (
                    <ul className="space-y-1">
                      {day.logs.map((log, i) => (
                        <li key={i} className={log.missed ? "text-red-600 font-semibold" : ""}>
                          {log.in} ➝ {log.out} {log.missed && <span className="text-xs ml-1">⚠️ Missing clock out</span>}
                        </li>
                      ))}
                    </ul>
                  ) : "—"}
                </td>
                <td className="p-3 text-center">{total.toFixed(2)} hrs</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr className="bg-gray-100 font-bold">
            <td className="p-3">Total</td>
            <td></td>
            <td className="p-3 text-center">{totalHours.toFixed(2)} hrs</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default WeeklyView;