import React, { useState, useEffect, useMemo } from "react";
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import FavouriteButton from "./FavouriteButton";

const WarehouseView = () => {
  const [cylinders, setCylinders] = useState([]);
  const [viewMode, setViewMode] = useState("table");
  const [selectedProductCode, setSelectedProductCode] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "ascending" });

  useEffect(() => {
    const fetchData = async () => {
      const firestore = getFirestore();
      const cylindersCollection = collection(firestore, "cylinders_warehouse");
      const querySnapshot = await getDocs(cylindersCollection);

      const cylindersData = await Promise.all(
        querySnapshot.docs.map(async (docSnapshot) => {
          const cylinderData = docSnapshot.data();
          return {
            id: docSnapshot.id,
            ProductCode: await fetchCode(cylinderData.ProductId),
            SerialNumber: cylinderData.SerialNumber,
            BookedInTime: cylinderData.BookedInTime,
            LocationId: await fetchLocation(cylinderData.LocationId),
            ProductId: cylinderData.ProductId,
            Stock: cylinderData.Stock ? "STOCK" : "ORDER",
            Allocated: cylinderData.Allocated || false,
            // Special & Barcode are fetched but not displayed
            Special: cylinderData.Special,
            BarcodeCombined: `${cylinderData.SerialNumber || ""}${
              cylinderData.Special || ""
            }${cylinderData.ProductId || ""}`,
          };
        })
      );
      setCylinders(cylindersData);
    };

    const fetchCode = async (productId) => {
      const firestore = getFirestore();
      if (!productId || typeof productId !== "string") {
        return "N/A";
      }
      const docRef = doc(firestore, "cyl_info", productId);
      const docSnap = await getDoc(docRef);
      return docSnap.exists() ? docSnap.data().productCode : "N/A";
    };

    const fetchLocation = async (LocationId) => {
      const firestore = getFirestore();
      if (LocationId.length < 5) return LocationId;
      const locationsRef = collection(firestore, "warehouse_locations");
      const querySnapshot = await getDocs(locationsRef);
      const uniqueId = LocationId.slice(-5);
      const matchingDoc = querySnapshot.docs.find((doc) =>
        doc.data().barcode.endsWith(uniqueId)
      );
      return matchingDoc ? matchingDoc.id : LocationId;
    };

    fetchData();
  }, []);

  // 🔄 Sorting
  const sortData = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    setCylinders((currentCylinders) => {
      return [...currentCylinders].sort((a, b) => {
        if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
        if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
        return 0;
      });
    });
  };

  // 🗓 Format Firestore Timestamp
  const formatDate = (timestamp) => {
    return timestamp
      ? new Date(timestamp.seconds * 1000).toLocaleString()
      : "N/A";
  };

  // 🔍 Filter by search query
  const filteredCylinders = useMemo(() => {
    return cylinders.filter((cyl) => {
      const query = searchQuery.toLowerCase();
      return (
        cyl.ProductCode.toLowerCase().includes(query) ||
        cyl.SerialNumber.toLowerCase().includes(query) ||
        (cyl.LocationId && cyl.LocationId.toLowerCase().includes(query))
      );
    });
  }, [searchQuery, cylinders]);

  // 📃 Aggregated list view
  const aggregatedProducts = useMemo(() => {
    return filteredCylinders.reduce((acc, cylinder) => {
      const key = cylinder.ProductCode;
      if (!acc[key]) {
        acc[key] = { count: 0, serialNumbers: [] };
      }
      acc[key].count += 1;
      acc[key].serialNumbers.push(cylinder.SerialNumber);
      return acc;
    }, {});
  }, [filteredCylinders]);

  return (
    <>
      <FavouriteButton pageUrl={window.location.pathname} />

      <div className="p-6 max-w-7xl mx-auto">
        <h1 className="text-3xl font-semibold mb-4">Warehouse Stock</h1>

        {/* Controls */}
        <div className="flex flex-col md:flex-row items-start md:items-center mb-6 gap-4">
          {/* Search */}
          <input
            type="text"
            placeholder="Search by Product Code, Serial Number, or Location..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full md:w-1/2 p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
          />

          {/* View Mode */}
          <div className="flex items-center">
            <label htmlFor="viewMode" className="mr-2 font-medium text-gray-700">
              View:
            </label>
            <select
              id="viewMode"
              value={viewMode}
              onChange={(e) => setViewMode(e.target.value)}
              className="p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
            >
              <option value="table">Table</option>
              <option value="list">List</option>
            </select>
          </div>
        </div>

        {/* Table View */}
        {viewMode === "table" && (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-200">
              <thead className="bg-primary border-b border-gray-200">
                <tr>
                  <th
                    className="px-4 py-2 text-left text-sm font-medium text-white cursor-pointer hover:bg-hover"
                    onClick={() => sortData("ProductCode")}
                  >
                    Product Code
                  </th>
                  <th
                    className="px-4 py-2 text-left text-sm font-medium text-white cursor-pointer hover:bg-hover"
                    onClick={() => sortData("SerialNumber")}
                  >
                    Serial Number
                  </th>
                  <th
                    className="px-4 py-2 text-left text-sm font-medium text-white cursor-pointer hover:bg-hover"
                    onClick={() => sortData("BookedInTime")}
                  >
                    Booked In
                  </th>
                  <th
                    className="px-4 py-2 text-left text-sm font-medium text-white cursor-pointer hover:bg-hover"
                    onClick={() => sortData("LocationId")}
                  >
                    Location
                  </th>
                  <th
                    className="px-4 py-2 text-left text-sm font-medium text-white cursor-pointer hover:bg-hover"
                    onClick={() => sortData("Stock")}
                  >
                    Stock Status
                  </th>
                  <th
                    className="px-4 py-2 text-left text-sm font-medium text-white cursor-pointer hover:bg-hover"
                    onClick={() => sortData("Allocated")}
                  >
                    Allocated
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100">
                {filteredCylinders.map((cyl) => (
                  <tr key={cyl.id} className="hover:bg-gray-50">
                    <td className="px-4 py-2 text-sm text-gray-700">
                      {cyl.ProductCode}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-700">
                      {cyl.SerialNumber || "N/A"}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-700">
                      {formatDate(cyl.BookedInTime)}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-700">
                      {cyl.LocationId}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-700">
                      {cyl.Stock}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-700">
                      {cyl.Allocated ? "Yes" : "No"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* List View */}
        {viewMode === "list" && (
          <div className="bg-white border border-gray-200 rounded-md p-4 mt-4">
            <ul className="space-y-2">
              {Object.entries(aggregatedProducts).map(
                ([code, { count, serialNumbers }]) => (
                  <li
                    key={code}
                    className="cursor-pointer hover:bg-gray-50 p-2 rounded"
                    onClick={() =>
                      setSelectedProductCode(
                        code === selectedProductCode ? null : code
                      )
                    }
                  >
                    <span className="font-medium">{code}</span> - {count} QTY
                    {selectedProductCode === code && (
                      <ul className="ml-4 mt-2 list-disc list-inside">
                        {serialNumbers.map((sn, index) => (
                          <li key={index} className="text-sm text-gray-600">
                            {sn}
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                )
              )}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default WarehouseView;
