import { useEffect, useState } from "react";
import { db } from "../firebase/firebaseConfig";
import {
  collection,
  getDocs,
  doc,
  setDoc,
  deleteDoc,
  collectionGroup,
  addDoc
} from "firebase/firestore";
import { format } from "date-fns";
import { getAuth } from "firebase/auth";

const UserSetupForm = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [form, setForm] = useState({
    name: "",
    department: "",
    shift_start: "09:00",
    expected_hours_per_week: 37.5,
  });

  const [logs, setLogs] = useState([]);
  const [logDate, setLogDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [newLog, setNewLog] = useState({ date: "", time: "", status: "IN" });
  const [admin, setAdmin] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      setAdmin({
        email: user.email,
        name: user.displayName || user.email,
      });
    }
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      const snapshot = await getDocs(collection(db, "clock-in-overview"));
      const data = snapshot.docs.map((doc) => ({
        uid: doc.id,
        ...doc.data(),
      }));
      setUsers(data);
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchLogs = async () => {
      if (!selectedUser || !logDate) return;

      const snapshot = await getDocs(collectionGroup(db, "entries"));
      const all = snapshot.docs.map((doc) => doc.data());

      const filtered = all
        .filter(
          (e) =>
            e.uid === selectedUser &&
            e.timestamp.startsWith(logDate)
        )
        .sort((a, b) => a.timestamp.localeCompare(b.timestamp));

      setLogs(filtered);
    };

    fetchLogs();
  }, [selectedUser, logDate]);

  const grouped = users.reduce((acc, user) => {
    const dept = user.department || "Other";
    if (!acc[dept]) acc[dept] = [];
    acc[dept].push(user);
    return acc;
  }, {});

  const startEditing = (user) => {
    setSelectedUser(user.uid);
    setForm({
      name: user.name || "",
      department: user.department || "",
      shift_start: user.shift_start || "09:00",
      expected_hours_per_week: user.expected_hours_per_week || 37.5,
    });
  };

  const saveUser = async () => {
    await setDoc(doc(db, "clock-in-overview", selectedUser), form, { merge: true });
    alert("✅ User updated");
    setSelectedUser(null);
    window.location.reload();
  };

  const addClockEntry = async () => {
    if (!newLog.date || !newLog.time) return alert("Please fill out date and time.");
    const full = `${newLog.date} ${newLog.time}:00`;
    const timeKey = newLog.time.replace(":", "");
    const entryId = `${selectedUser}-${timeKey}`;
    const userPath = doc(db, `clock-users/${selectedUser}/${newLog.date}/${timeKey}`);
    const dayPath = doc(db, `clock-days/${newLog.date}/entries/${entryId}`);

    const entry = {
      uid: selectedUser,
      timestamp: full,
      status: newLog.status,
    };

    await setDoc(userPath, entry);
    await setDoc(dayPath, entry);

    await addDoc(collection(db, "audit-log"), {
      uid: selectedUser,
      action: "manual_clock_entry_added",
      timestamp: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
      performed_by_email: admin?.email || "unknown",
      performed_by_name: admin?.name || "unknown",
      new_value: entry,
    });

    setNewLog({ date: "", time: "", status: "IN" });
    alert("✅ Entry added");
    window.location.reload();
  };

  const deleteEntry = async (timestamp) => {
    const [date, time] = timestamp.split(" ");
    const timeKey = time.replace(":", "").slice(0, 4);
    const entryId = `${selectedUser}-${timeKey}`;
    const userPath = doc(db, `clock-users/${selectedUser}/${date}/${timeKey}`);
    const dayPath = doc(db, `clock-days/${date}/entries/${entryId}`);

    await deleteDoc(userPath);
    await deleteDoc(dayPath);

    await addDoc(collection(db, "audit-log"), {
      uid: selectedUser,
      action: "manual_clock_entry_deleted",
      timestamp: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
      performed_by_email: admin?.email || "unknown",
      performed_by_name: admin?.name || "unknown",
      deleted_value: timestamp,
    });

    alert("❌ Entry deleted");
    window.location.reload();
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <aside className="w-72 bg-white shadow-lg border-r overflow-y-auto sticky top-0 h-screen p-4">
        <h2 className="text-lg font-bold mb-4 text-[#606bbf]">Departments</h2>
        {Object.entries(grouped).map(([dept, deptUsers]) => (
          <div key={dept} className="mb-6">
            <h3 className="font-semibold text-gray-700 text-sm mb-2">{dept}</h3>
            <ul className="space-y-1">
              {deptUsers.map((user) => (
                <li
                  key={user.uid}
                  onClick={() => startEditing(user)}
                  className={`cursor-pointer px-2 py-1 rounded hover:bg-[#efefff] ${
                    selectedUser === user.uid ? "bg-[#dcdcff] font-semibold" : ""
                  }`}
                >
                  {user.name || `Person-${user.uid.slice(0, 4)}`}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </aside>

      {/* Main */}
      <main className="flex-1 overflow-y-auto p-8">
        <h1 className="text-2xl font-bold text-gray-800 mb-6">👤 User Setup</h1>

        {!selectedUser ? (
          <p className="text-gray-500">Select a user to edit their settings.</p>
        ) : (
          <div className="max-w-xl space-y-8">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-lg font-semibold mb-4">User Info</h2>

              <label className="block text-sm font-medium">Name</label>
              <input
                value={form.name}
                onChange={(e) => setForm({ ...form, name: e.target.value })}
                className="w-full p-2 border rounded mb-4"
              />

              <label className="block text-sm font-medium">Department</label>
              <input
                value={form.department}
                onChange={(e) => setForm({ ...form, department: e.target.value })}
                className="w-full p-2 border rounded mb-4"
              />

              <label className="block text-sm font-medium">Start Time</label>
              <input
                type="time"
                value={form.shift_start}
                onChange={(e) => setForm({ ...form, shift_start: e.target.value })}
                className="w-full p-2 border rounded mb-4"
              />

              <label className="block text-sm font-medium">Hours/Week</label>
              <input
                type="number"
                value={form.expected_hours_per_week}
                onChange={(e) =>
                  setForm({ ...form, expected_hours_per_week: parseFloat(e.target.value) })
                }
                className="w-full p-2 border rounded mb-4"
              />

              <button
                onClick={saveUser}
                className="bg-[#606bbf] text-white px-4 py-2 rounded hover:bg-[#4e54a6]"
              >
                ✅ Save
              </button>
            </div>

            {/* Manual Entry */}
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-lg font-semibold mb-4">Manual Clock Entry</h2>

              <div className="grid grid-cols-2 gap-4">
                <input
                  type="date"
                  value={newLog.date}
                  onChange={(e) => setNewLog({ ...newLog, date: e.target.value })}
                  className="p-2 border rounded"
                />
                <input
                  type="time"
                  value={newLog.time}
                  onChange={(e) => setNewLog({ ...newLog, time: e.target.value })}
                  className="p-2 border rounded"
                />
                <select
                  value={newLog.status}
                  onChange={(e) => setNewLog({ ...newLog, status: e.target.value })}
                  className="p-2 border rounded"
                >
                  <option value="IN">IN</option>
                  <option value="OUT">OUT</option>
                </select>
                <button
                  onClick={addClockEntry}
                  className="bg-[#606bbf] text-white px-4 py-2 rounded hover:bg-[#4e54a6]"
                >
                  ➕ Add
                </button>
              </div>
            </div>

            {/* Logs */}
            <div className="bg-white p-6 rounded-lg shadow-md">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold">Recent Clock Activity</h2>
                <input
                  type="date"
                  value={logDate}
                  onChange={(e) => setLogDate(e.target.value)}
                  className="p-2 border rounded text-sm"
                />
              </div>
              <ul className="text-sm text-gray-700 space-y-2">
                {logs.length === 0 ? (
                  <li className="text-gray-500 italic">No entries for selected date.</li>
                ) : (
                  logs.map((log, idx) => (
                    <li key={idx} className="flex justify-between border-b pb-1">
                      <span>
                        {log.timestamp} — {log.status}
                      </span>
                      <button
                        onClick={() => deleteEntry(log.timestamp)}
                        className="text-red-500 hover:underline"
                      >
                        Delete
                      </button>
                    </li>
                  ))
                )}
              </ul>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default UserSetupForm;
