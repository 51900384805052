import React from "react";

const CombiSizing = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center p-6">
      {/* Title */}
      <h1 className="text-3xl font-semibold text-gray-800 mb-6 text-center">
        Combination Cylinders Sizing Table
      </h1>

      {/* Table Container */}
      <div className="overflow-x-auto w-full max-w-5xl bg-white shadow-lg rounded-lg p-6 border border-gray-300">
        <table className="w-full border-collapse">
          {/* Table Header */}
          <thead>
            <tr className="bg-primary text-white text-lg">
              <th colSpan="8" className="py-3 text-center">
                Combination Sizing Chart (Cold Tank / Hot Tank)
              </th>
            </tr>
            <tr className="bg-gray-200 text-gray-700">
              <th colSpan="8" className="py-2">Height</th>
            </tr>
          </thead>

          {/* Table Body */}
          <tbody>
            <tr className="bg-gray-100">
              <th rowSpan="6" className="py-3 text-center text-gray-700">Width</th>
              <td className="text-center"></td>
              <td className="text-center">825mm</td>
              <td className="text-center">900mm</td>
              <td className="text-center">1050mm</td>
              <td className="text-center">1200mm</td>
              <td className="text-center">1500mm</td>
              <td className="text-center">1800mm</td>
            </tr>
            {[
              ["375mm", "20/52L", "20/60L", "20/75L", "20/90L", "20/120L", "40/120L"],
              ["400mm", "20/61L", "20/70L", "20/90L", "20/105L", "20/130L", "40/150L"],
              ["450mm", "20/80L", "20/90L", "20/120L", "40/120L", "40/160L", "45/210L"],
              ["500mm", "20/110L", "20/120L", "40/150L", "40/170L", "45/220L", "45/250L"],
              ["600mm", "40/155L", "40/175L", "40/205L", "45/255L", "60/335L", "60/375L"],
            ].map((row, index) => (
              <tr
                key={index}
                className={`${
                  index % 2 === 0 ? "bg-white" : "bg-gray-100"
                } hover:bg-primary hover:text-white transition`}
              >
                <td className="py-3 px-4 text-center font-medium">{row[0]}</td>
                {row.slice(1).map((value, i) => (
                  <td key={i} className="py-3 px-4 text-center">
                    {value}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CombiSizing;
