import React, { useEffect, useState } from "react";
import { db } from "../firebase/firebaseConfig";
import { collection, onSnapshot, updateDoc, doc } from "firebase/firestore";

const JobList = () => {
  const [jobs, setJobs] = useState({ new: [], inProgress: [], complete: [] });
  const [expandedJob, setExpandedJob] = useState(null);
  const [jobNotes, setJobNotes] = useState({});
  const [showArchive, setShowArchive] = useState(false);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "machines"), (snapshot) => {
      const jobsData = snapshot.docs.map((docSnapshot) => ({
        id: docSnapshot.id,
        ...docSnapshot.data(),
      }));

      setJobs({
        new: jobsData.filter((job) => job.status === "new"),
        inProgress: jobsData.filter((job) => job.status === "inprogress"),
        complete: jobsData.filter((job) => job.status === "complete"),
      });

      // Ensure jobNotes initializes correctly
      const notesMap = {};
      jobsData.forEach((job) => {
        if (job.id) {
          notesMap[job.id] = job.notes || ""; // Set default empty string
        }
      });
      setJobNotes(notesMap);
    });

    return () => unsubscribe();
  }, []);

  const handleExpand = (jobId) => {
    setExpandedJob(expandedJob === jobId ? null : jobId);
  };

  const acknowledgeJob = async (id) => {
    const jobRef = doc(db, "machines", id);
    await updateDoc(jobRef, {
      status: "inprogress",
      dateOfAcknowledgement: new Date(),
    });
  };

  const saveNotes = async (id) => {
    if (!jobNotes[id]) return; // Prevent saving undefined
    const jobRef = doc(db, "machines", id);
    await updateDoc(jobRef, { notes: jobNotes[id] });
  };

  const completeJob = async (id) => {
    const jobRef = doc(db, "machines", id);
    await updateDoc(jobRef, {
      status: "complete",
      dateOfCompletion: new Date(),
    });
  };

  const unarchiveJob = async (id) => {
    const jobRef = doc(db, "machines", id);
    await updateDoc(jobRef, {
      status: "inprogress",
      dateOfCompletion: null, // Remove completion date
    });
  };

  return (
    <div className="min-h-screen flex flex-col items-center bg-gray-100 p-6">
      <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-3xl border border-gray-300">
        <h2 className="text-2xl font-semibold text-center text-gray-800 mb-4">Job Management</h2>

        {/* New Jobs */}
        <JobSection
          title="🆕 New Jobs"
          jobs={jobs.new}
          expandedJob={expandedJob}
          handleExpand={handleExpand}
          actionButton={(job) => (
            <button
              onClick={() => acknowledgeJob(job.id)}
              className="w-full bg-yellow-500 text-white py-2 rounded-md hover:bg-yellow-600 transition text-lg font-semibold"
            >
              Acknowledge
            </button>
          )}
        />

        {/* In Progress Jobs */}
        <JobSection
          title="🔧 In Progress Jobs"
          jobs={jobs.inProgress}
          expandedJob={expandedJob}
          handleExpand={handleExpand}
          jobNotes={jobNotes}
          setJobNotes={setJobNotes}
          actionButtons={(job) => (
            <div className="flex justify-between space-x-2">
              <button
                onClick={() => saveNotes(job.id)}
                className="w-1/2 bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition text-lg font-semibold"
              >
                Save Changes
              </button>
              <button
                onClick={() => completeJob(job.id)}
                className="w-1/2 bg-green-500 text-white py-2 rounded-md hover:bg-green-600 transition text-lg font-semibold"
              >
                Complete
              </button>
            </div>
          )}
        />

        {/* Archive Toggle */}
        <button
          onClick={() => setShowArchive(!showArchive)}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
        >
          {showArchive ? "Hide Archive" : "View Archive"}
        </button>

        {/* Completed Jobs (Archive) */}
        {showArchive && (
          <JobSection
            title="✅ Completed Jobs (Archive)"
            jobs={jobs.complete}
            expandedJob={expandedJob}
            handleExpand={handleExpand}
            archiveMode
            actionButton={(job) => (
              <button
                onClick={() => unarchiveJob(job.id)}
                className="w-full bg-gray-500 text-white py-2 rounded-md hover:bg-gray-600 transition text-lg font-semibold"
              >
                Unarchive
              </button>
            )}
          />
        )}
      </div>
    </div>
  );
};

const JobSection = ({
  title,
  jobs,
  expandedJob,
  handleExpand,
  jobNotes,
  setJobNotes,
  actionButtons,
  actionButton,
  archiveMode,
}) => (
  <div className="mb-6">
    <h2 className="text-xl font-semibold mb-2">{title}</h2>
    {jobs.length > 0 ? (
      <ul className="space-y-4">
        {jobs.map((job) => (
          <li key={job.id} className="p-4 bg-gray-100 rounded shadow-md">
            {/* Job Title */}
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => handleExpand(job.id)}
            >
              <div className="text-lg font-medium">
                <span className="text-gray-700">{job.machine} - {job.fault}</span>
              </div>
              <span className="text-gray-500">{expandedJob === job.id ? "▲" : "▼"}</span>
            </div>

            {/* Expanded Details */}
            {expandedJob === job.id && (
              <div className="mt-3 p-3 bg-white rounded border border-gray-300 space-y-3">
                <p className="text-gray-700">
                  <strong>Reported By:</strong> {job.name}
                </p>
                <p className="text-gray-700">
                  <strong>Submitted:</strong> {job.timestamp ? new Date(job.timestamp.toDate()).toLocaleString() : "N/A"}
                </p>
                {archiveMode && (
                  <p className="text-gray-700">
                    <strong>Completed:</strong> {job.dateOfCompletion ? new Date(job.dateOfCompletion.toDate()).toLocaleString() : "N/A"}
                  </p>
                )}

                {!archiveMode && (
                  <div>
                    <label className="block text-gray-700 font-medium">Notes:</label>
                    <textarea
                      className="w-full p-2 border rounded-md focus:ring-2 focus:ring-primary focus:outline-none"
                      placeholder="Add notes..."
                      value={jobNotes?.[job.id] || ""} // Safeguard with optional chaining
                      onChange={(e) =>
                        setJobNotes((prev) => ({ ...prev, [job.id]: e.target.value }))
                      }
                    />
                  </div>
                )}

                {actionButtons ? actionButtons(job) : actionButton(job)}
              </div>
            )}
          </li>
        ))}
      </ul>
    ) : (
      <p className="text-gray-500">No jobs found.</p>
    )}
  </div>
);

export default JobList;
