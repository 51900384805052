// ForgotPassword.jsx
import React from 'react';

const ForgotPassword = () => {
    return (
      <div className="forgot-password-container">
        <div className="forgot-password-message">
          <p>Send an email to <a href="mailto:ptaylor@telford-group.com">ptaylor@telford-group.com</a> for password assistance.</p>
        </div>
      </div>
    );
  };
  
export default ForgotPassword;
