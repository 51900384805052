import { getAuth } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase/firebaseConfig";
import ClockInOverview from "./ClockInOverview";
import FireRollCall from "./FireRollCall";
import WeeklyView from "./WeeklyView";
import UserSetupForm from "./UserSetupForm";
import ProblemClocking from "./ProblemClocking";

const ClockInDashboard = () => {

  const [adminUser, setAdminUser] = useState(null);

useEffect(() => {
  const fetchUserInfo = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const userDocRef = doc(db, "Users", user.email);
      const userSnap = await getDoc(userDocRef);

      if (userSnap.exists()) {
        setAdminUser({
          email: user.email,
          name: userSnap.data().name || user.email,
        });
      }
    }
  };

  fetchUserInfo();
}, []);


  const [currentView, setCurrentView] = useState("dashboard");

  const renderView = () => {
    switch (currentView) {
      case "roll-call":
        return <FireRollCall />;
      case "weekly":
        return <WeeklyView />;
      case "setup":
        return <UserSetupForm />;
      case "issues":
        return <ProblemClocking />;
      default:
        return <ClockInOverview />;
    }
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Fixed Sidebar */}
      <aside className="w-64 bg-[#606bbf] text-white flex flex-col px-4 py-6 space-y-4 fixed top-0 left-0 bottom-0 shadow-xl z-10">
        <h2 className="text-xl font-bold text-center mb-6 tracking-wide">🕒 Clock-In</h2>

        <button
          onClick={() => setCurrentView("dashboard")}
          className="text-left hover:bg-white hover:text-[#606bbf] transition-all font-medium p-2 rounded"
        >
          📊 Dashboard
        </button>
        <button
          onClick={() => setCurrentView("roll-call")}
          className="text-left hover:bg-white hover:text-[#606bbf] transition-all font-medium p-2 rounded"
        >
          🔥 Roll Call
        </button>
        <button
          onClick={() => setCurrentView("weekly")}
          className="text-left hover:bg-white hover:text-[#606bbf] transition-all font-medium p-2 rounded"
        >
          📅 Weekly View
        </button>
        <button
          onClick={() => setCurrentView("setup")}
          className="text-left hover:bg-white hover:text-[#606bbf] transition-all font-medium p-2 rounded"
        >
          👤 User Setup
        </button>
        <button
          onClick={() => setCurrentView("issues")}
          className="text-left hover:bg-white hover:text-[#606bbf] transition-all font-medium p-2 rounded"
        >
          ⁉️ Issues
        </button>
      </aside>

      {/* Main Content (scrollable) */}
      <main className="ml-64 flex-1 overflow-y-auto bg-gray-100 p-6">
        {renderView()}
      </main>
    </div>
  );
};

export default ClockInDashboard;
