import React, { useState } from 'react';
import FavouriteButton from './FavouriteButton';

function HeatUpCalculator() {
  const [capacity, setCapacity] = useState('');
  const [temperatureRise, setTemperatureRise] = useState('50');
  const [powerInput, setPowerInput] = useState('');
  const [time, setTime] = useState('');

  const calculateTime = () => {
    const energyNeeded = capacity * temperatureRise;
    const energySupplied = 14.3 * powerInput; // Assuming 14.3 is a fixed balancer value
    const timeNeeded = energyNeeded / energySupplied;
    setTime(timeNeeded.toFixed(2));    
};


return (
  <div className="min-h-screen bg-gray-100 flex flex-col items-center p-6">
    {/* Favourite Button */}
    <FavouriteButton pageUrl={window.location.pathname} />

    {/* Container */}
    <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-md border border-gray-300 mt-8">
      <h2 className="text-2xl font-semibold text-center text-gray-800 mb-6">
        Heat Up kW Calculator
      </h2>

      {/* Input Fields */}
      <div className="space-y-4">
        <div>
          <label className="block text-gray-600 font-medium mb-1">
            Capacity (L):
          </label>
          <input
            type="number"
            value={capacity}
            onChange={(e) => setCapacity(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-primary focus:outline-none"
          />
        </div>

        <div>
          <label className="block text-gray-600 font-medium mb-1">
            Temperature Rise (°C):
          </label>
          <input
            type="number"
            value={temperatureRise}
            onChange={(e) => setTemperatureRise(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-primary focus:outline-none"
          />
        </div>

        <div>
          <label className="block text-gray-600 font-medium mb-1">
            Time Required (Mins):
          </label>
          <input
            type="number"
            value={powerInput}
            onChange={(e) => setPowerInput(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-primary focus:outline-none"
          />
        </div>
      </div>

      {/* Calculate Button */}
      <button
        onClick={calculateTime}
        className="w-full bg-primary text-white py-3 mt-4 rounded-md hover:bg-opacity-90 transition text-lg font-semibold"
      >
        Calculate kW Required
      </button>

      {/* Result Display */}
      {time && (
        <h3 className="text-lg text-center text-primary font-medium mt-4">
          Power input required to achieve time: {time} kW
        </h3>
      )}
    </div>
  </div>
);
}

export default HeatUpCalculator;
