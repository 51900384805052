import React, { useState, useEffect, useRef } from "react";
import {
  getFirestore,
  doc,
  collection,
  getDoc,
  updateDoc,
  getDocs,
  query,
  where,
  Timestamp,
  arrayUnion,
  arrayRemove,
  setDoc,
} from "firebase/firestore";
import axios from "axios";
import { v4 as uuidv4 } from "uuid"; // npm install uuid
import { useNavigate } from "react-router-dom"; // npm install react-router-dom

const DespatchReport = () => {
  const firestore = getFirestore();
  const navigate = useNavigate();

  // States
  const [reportDocId, setReportDocId] = useState(null);
  const [reportNumber, setReportNumber] = useState(null);
  const [barcodes, setBarcodes] = useState([]); // Each barcode + product details
  const [currentBarcode, setCurrentBarcode] = useState("");
  const [bgColorClass, setBgColorClass] = useState("");
  const [productCodeCache, setProductCodeCache] = useState({}); // Caches { productId: productCode }
  const [loading, setLoading] = useState(false); // New Loading State

  const barcodeInputRef = useRef(null);

  // ---------------------------------------
  // On Component Mount: Find or Create Despatch
  // ---------------------------------------
  useEffect(() => {
    barcodeInputRef.current?.focus();
    (async () => {
      try {
        const existing = await findIncompleteDespatch();
        if (existing) {
          setReportDocId(existing.docId);
          setReportNumber(existing.reportNumber);
          setBarcodes(existing.items ?? []);
        } else {
          const { docId, number } = await createNewDespatch();
          setReportDocId(docId);
          setReportNumber(number);
        }
      } catch (err) {
        console.error("Error checking/creating despatch:", err);
      }
    })();
  }, []);

  // ---------------------------------------
  // 1) Find Incomplete Despatch
  // ---------------------------------------
  const findIncompleteDespatch = async () => {
    const despatchReportsRef = collection(firestore, "despatchreports");
    const q = query(despatchReportsRef, where("completed", "==", false));
    const snap = await getDocs(q);

    if (snap.empty) return null;

    let incompleteDocs = [];
    snap.forEach((docSnap) => {
      incompleteDocs.push({
        docId: docSnap.id,
        ...docSnap.data(),
      });
    });
    incompleteDocs.sort((a, b) => b.reportNumber - a.reportNumber);
    const newest = incompleteDocs[0];

    const detailRef = doc(firestore, "despatchreportsdetailed", newest.docId);
    const detailSnap = await getDoc(detailRef);

    let items = [];
    if (detailSnap.exists()) {
      items = detailSnap.data().items || [];
    }

    return {
      docId: newest.docId,
      reportNumber: newest.reportNumber,
      items,
    };
  };

  // ---------------------------------------
  // 2) Create New Despatch
  // ---------------------------------------
  const createNewDespatch = async () => {
    const countersRef = doc(firestore, "appData", "counters");
    const countersSnap = await getDoc(countersRef);

    let newNumber;
    if (!countersSnap.exists()) {
      newNumber = 1000;
      await setDoc(countersRef, { despatchCounter: newNumber });
    } else {
      const currentVal = countersSnap.data().despatchCounter ?? 1000;
      newNumber = currentVal + 1;
      await updateDoc(countersRef, { despatchCounter: newNumber });
    }

    const despatchReportsRef = collection(firestore, "despatchreports");
    const newDocRef = doc(despatchReportsRef);

    await setDoc(newDocRef, {
      reportNumber: newNumber,
      createdAt: Timestamp.now(),
      completed: false,
    });

    const detailedRef = doc(firestore, "despatchreportsdetailed", newDocRef.id);
    await setDoc(detailedRef, {
      items: [],
    });

    return { docId: newDocRef.id, number: newNumber };
  };

  // ---------------------------------------
  // 3) Fetch Product Code for Scanned Item
  // ---------------------------------------
  const fetchProductCode = async (productId) => {
    if (productCodeCache[productId]) {
      return productCodeCache[productId]; // Return from cache if available
    }

    try {
      const cylRef = doc(firestore, "cyl_info", productId);
      const cylSnap = await getDoc(cylRef);

      const productCode = cylSnap.exists() ? cylSnap.data().productCode : `Unknown (${productId})`;

      // Cache the result
      setProductCodeCache((prevCache) => ({
        ...prevCache,
        [productId]: productCode,
      }));

      return productCode;
    } catch (error) {
      console.error("Error fetching product code:", error);
      return `Unknown (${productId})`;
    }
  };

  // ---------------------------------------
  // 4) Barcode Scanning Logic (Add Unique ID)
  // ---------------------------------------
  const handleBarcodeChange = (e) => {
    const numericOnly = e.target.value.replace(/\D/g, "");
    setCurrentBarcode(numericOnly);
  };

  const isBarcodeValid = currentBarcode.length === 13;

  const flashColor = (color) => {
    setBgColorClass(color);
    setTimeout(() => {
      setBgColorClass("");
    }, 1500);
  };

  // On Enter Key: Scan and Show Product Live
  const handleBarcodeKeyDown = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!isBarcodeValid || !reportDocId) {
        flashColor("bg-red-500");
        setCurrentBarcode("");
        return;
      }

      const serialNumber = currentBarcode.slice(0, 6);
      const special = currentBarcode.slice(6, 8);
      const productId = currentBarcode.slice(8);
      const specialLabel = special === "49" ? "ORDER" : special === "50" ? "STOCK" : special;

      // Fetch the product code for live display
      const productCode = await fetchProductCode(productId);

      // Add a unique ID to each scanned item
      const newItem = {
        id: uuidv4(), // Unique ID for deletion
        barcode: currentBarcode,
        serialNumber,
        productId,
        productCode,
        special: specialLabel,
        scannedAt: Timestamp.now(),
      };

      try {
        const detailedRef = doc(firestore, "despatchreportsdetailed", reportDocId);
        await updateDoc(detailedRef, {
          items: arrayUnion(newItem),
        });

        // Update local state (UI) immediately
        setBarcodes((prev) => [...prev, newItem]);
        setCurrentBarcode("");
        flashColor("bg-green-500");
      } catch (error) {
        console.error("Error updating despatchreportsdetailed:", error);
        flashColor("bg-red-500");
        setCurrentBarcode("");
      }
    }
  };

  // ---------------------------------------
  // 5) Delete Scanned Item (By Unique ID)
  // ---------------------------------------
  const handleDeleteItem = async (itemToDelete) => {
    try {
      // Remove from Firestore
      const detailedRef = doc(firestore, "despatchreportsdetailed", reportDocId);
      const updatedItems = barcodes.filter((item) => item.id !== itemToDelete.id);

      // Update Firestore with new items list
      await updateDoc(detailedRef, {
        items: updatedItems,
      });

      // Update local state
      setBarcodes(updatedItems);

      flashColor("bg-yellow-500");
    } catch (error) {
      console.error("Error deleting scanned item:", error);
      alert("Failed to delete the item. Please check the console for details.");
    }
  };

  // ---------------------------------------
  // 6) Complete Despatch, Show Loading & Redirect
  // ---------------------------------------
  const handleCompleteDespatch = async () => {
    setLoading(true);
  
    try {
      if (!reportDocId) return;
  
      const reportRef = doc(firestore, "despatchreports", reportDocId);
      await updateDoc(reportRef, { completed: true });
  
      // Convert barcode data into table format
      const tableData = barcodes.map((item, index) => ({
        "#": index + 1,
        "Serial Number": item.serialNumber,
        "Product Code": item.productCode,
        "Type": item.special,
        "Barcode": item.barcode,
      }));
  
      // Send Email
      await axios.post("https://europe-west2-peters-help-desk.cloudfunctions.net/sendEmail", {
        to: "transportadmin@telford-group.com, hortonwood@telford-group.com",
        cc: "sfoskett@telford-group.com, iwebb@telford-group.com",
        subject: `Despatch Report #${reportNumber}`,
        text: `Despatch #${reportNumber} has been completed. See details below:`, // Fallback text
        tableData, // Sends table data
      });
  
      alert("Despatch completed and email sent!");
      navigate("/DespatchDashboard");
    } catch (error) {
      console.error("Error sending email or completing despatch:", error);
      alert("Error completing despatch. Please check console for details.");
    } finally {
      setLoading(false);
    }
  };
  
  // ---------------------------------------
  // 7) UI Layout (With Loading Screen)
  // ---------------------------------------
  if (loading) {
    return (
      <div className="min-h-screen w-full flex flex-col items-center justify-center bg-gray-200">
        <h1 className="text-2xl font-bold mb-4 text-gray-700">
          Generating despatch report, do not close this page...
        </h1>
        <div className="animate-spin rounded-full h-16 w-16 border-b-4 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div
      className={`min-h-screen w-full flex flex-col items-center justify-center transition-colors duration-500 ${bgColorClass}`}
    >
      <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-2xl border border-gray-300 mx-4">
        <h1 className="text-xl font-semibold text-gray-800 mb-4">
          Warehouse Despatch (Report #{reportNumber ?? "loading..."})
        </h1>

        {/* Barcode Input */}
        <label htmlFor="barcode" className="block text-gray-700 font-medium mb-1">
          Scan or Enter Barcode:
        </label>
        <input
          type="text"
          id="barcode"
          value={currentBarcode}
          onChange={handleBarcodeChange}
          onKeyDown={handleBarcodeKeyDown}
          ref={barcodeInputRef}
          placeholder="Scan 13-digit barcode and press Enter"
          className="mb-4 w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
        />

        {/* Live Table of Scanned Items */}
        {barcodes.length > 0 && (
          <div className="mb-4 overflow-x-auto">
            <table className="min-w-full border border-gray-200 text-sm">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-2 py-2 border-b">#</th>
                  <th className="px-2 py-2 border-b">Serial</th>
                  <th className="px-2 py-2 border-b">Product</th>
                  <th className="px-2 py-2 border-b">Type</th>
                  <th className="px-2 py-2 border-b">Full Barcode</th>
                  <th className="px-2 py-2 border-b">Actions</th>
                </tr>
              </thead>
              <tbody>
                {barcodes.map((item, index) => (
                  <tr key={item.id} className="text-center">
                    <td className="px-2 py-1 border-b">{index + 1}</td>
                    <td className="px-2 py-1 border-b">{item.serialNumber}</td>
                    <td className="px-2 py-1 border-b">{item.productCode}</td>
                    <td className="px-2 py-1 border-b">{item.special}</td>
                    <td className="px-2 py-1 border-b">{item.barcode}</td>
                    <td className="px-2 py-1 border-b">
                      <button
                        onClick={() => handleDeleteItem(item)}
                        className="bg-red-500 text-white text-xs px-2 py-1 rounded hover:bg-red-600"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* Complete Despatch Button */}
        <button
          onClick={handleCompleteDespatch}
          className="bg-primary hover:bg-hover text-white px-4 py-2 rounded"
        >
          Complete Despatch
        </button>
      </div>
    </div>
  );
};

export default DespatchReport;
