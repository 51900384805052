

import React, { useState } from "react";

const SendSMSForm = () => {
  const [message, setMessage] = useState("");
  const [response, setResponse] = useState("");

  // Replace with your actual Cloud Function endpoint
  const functionUrl =
    "https://europe-west2-peters-help-desk.cloudfunctions.net/sendSMSMultiple";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setResponse("Sending...");

    try {
      const res = await fetch(functionUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ text: message }), // Body param, e.g. "text"
      });

      if (!res.ok) {
        const errorText = await res.text();
        throw new Error(errorText);
      }

      const result = await res.text();
      setResponse(`Success: ${result}`);
    } catch (error) {
      console.error("Error sending SMS:", error);
      setResponse(`Error: ${error.message}`);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center p-6">
      <h2 className="text-xl font-semibold mb-4">Send Test SMS</h2>
      <form onSubmit={handleSubmit} className="space-y-4 w-full max-w-sm">
        <div>
          <label className="block mb-1 text-gray-700">Message:</label>
          <textarea
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="w-full p-2 border rounded-md focus:outline-none"
            placeholder="Type your test message..."
          />
        </div>
        <button
          type="submit"
          className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition"
        >
          Send SMS
        </button>
      </form>

      {/* Display Response / Error */}
      {response && (
        <p className="mt-4 text-sm text-gray-800 whitespace-pre-line">
          {response}
        </p>
      )}
    </div>
  );
};

export default SendSMSForm;
