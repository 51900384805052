import React, { useState, useEffect, useRef } from "react";
import {
  collection,
  addDoc,
  getDocs,
  getFirestore,
  Timestamp,
  doc,
  getDoc,
} from "firebase/firestore";

const StockTakeForm = () => {
  const [barcode, setBarcode] = useState("");
  const [locationBarcode, setLocationBarcode] = useState("");
  const [scannedItems, setScannedItems] = useState([]);
  const [lastScannedId, setLastScannedId] = useState(null);
  const [productCodeMap, setProductCodeMap] = useState({});

  const firestore = getFirestore();
  const barcodeInputRef = useRef(null);
  const locationInputRef = useRef(null);

  useEffect(() => {
    barcodeInputRef.current.focus();
    fetchProductCodes();
    fetchScannedItems();
  }, []);

  const fetchProductCodes = async () => {
    const snapshot = await getDocs(collection(firestore, "cyl_info"));
    const codeMap = {};
    snapshot.forEach((doc) => {
      const data = doc.data();
      codeMap[doc.id] = data?.productCode || "Unknown";
    });
    setProductCodeMap(codeMap);
  };

  const fetchScannedItems = async () => {
    const scannedCollection = collection(firestore, "stocktake_scanned");
    const querySnapshot = await getDocs(scannedCollection);

    const items = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        id: doc.id,
        SerialNumber: data.SerialNumber || "Unknown",
        ProductId: data.ProductId || "Unknown",
        ProductCode: productCodeMap[data.ProductId] || "Loading...",
        LocationId: data.LocationId || "Unknown",
        ScannedTime: data.ScannedTime ?? null,
      };
    });

    const sorted = items.sort((a, b) => b.ScannedTime?.seconds - a.ScannedTime?.seconds);
    setScannedItems(sorted);
  };

  const handleScanSubmit = async () => {
    if (!barcode || !locationBarcode) return;

    const serialNumber = barcode.slice(0, 6);
    const productId = barcode.slice(8);

    const newItem = {
      SerialNumber: serialNumber,
      ProductId: productId,
      LocationId: locationBarcode,
      ScannedTime: Timestamp.now(),
    };

    const docRef = await addDoc(collection(firestore, "stocktake_scanned"), newItem);

    setScannedItems((prev) => [
      {
        ...newItem,
        id: docRef.id,
        ProductCode: productCodeMap[productId] || "Loading...",
      },
      ...prev,
    ]);

    setLastScannedId(docRef.id);
    setBarcode("");
    setLocationBarcode("");

    setTimeout(() => {
      setLastScannedId(null);
      barcodeInputRef.current.focus();
    }, 1000);
  };

  return (
    <div className="p-4 max-w-xl mx-auto">
      <h1 className="text-2xl font-semibold mb-4 text-center">📦 Stock Take</h1>

      {/* Mobile-Friendly Vertical Inputs */}
      <div className="flex flex-col gap-4 mb-6">
        <input
          type="text"
          placeholder="Scan Item Barcode"
          value={barcode}
          onChange={(e) => setBarcode(e.target.value.replace(/\D/g, ""))}
          ref={barcodeInputRef}
          className="p-3 border rounded-md focus:ring-2 focus:ring-blue-500"
          onKeyDown={(e) => {
            if (e.key === "Enter" && locationBarcode) {
              handleScanSubmit();
            } else if (e.key === "Enter") {
              locationInputRef.current.focus();
            }
          }}
        />
        <input
          type="text"
          placeholder="Scan Location Barcode"
          value={locationBarcode}
          onChange={(e) => setLocationBarcode(e.target.value)}
          ref={locationInputRef}
          className="p-3 border rounded-md focus:ring-2 focus:ring-blue-500"
          onKeyDown={(e) => {
            if (e.key === "Enter" && barcode) {
              handleScanSubmit();
            }
          }}
        />
      </div>

      {/* Scanned Items Table */}
      <div className="overflow-x-auto text-sm">
        <table className="min-w-full bg-white border border-gray-200">
          <thead className="bg-gray-100 text-gray-700">
            <tr>
              <th className="px-4 py-2 text-left">Serial</th>
              <th className="px-4 py-2 text-left">Product</th>
              <th className="px-4 py-2 text-left">Location</th>
              <th className="px-4 py-2 text-left">Scanned</th>
            </tr>
          </thead>
          <tbody>
            {scannedItems.length > 0 ? (
              scannedItems.map((item) => (
                <tr
                  key={item.id}
                  className={`border-t ${
                    item.id === lastScannedId ? "bg-green-100 animate-pulse" : ""
                  }`}
                >
                  <td className="px-4 py-2">{item.SerialNumber}</td>
                  <td className="px-4 py-2">{item.ProductCode}</td>
                  <td className="px-4 py-2">{item.LocationId}</td>
                  <td className="px-4 py-2">
                    {item.ScannedTime?.seconds
                      ? new Date(item.ScannedTime.seconds * 1000).toLocaleString()
                      : "N/A"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center py-4 text-gray-500">
                  No items scanned yet.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StockTakeForm;
