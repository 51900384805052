import React, { useState } from "react";
import { db } from "../firebase/firebaseConfig";
import { collection, addDoc } from "firebase/firestore";

const Machines = () => {
  const [formData, setFormData] = useState({
    name: "",
    machine: "",
    fault: "",
    faultType: "",
    notes: "",
  });

  const [message, setMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "machines"), {
        ...formData,
        status: "new", // Initial status
        timestamp: new Date(), // Add timestamp
      });
      setMessage("✅ Fault logged successfully!");
      setFormData({
        name: "",
        machine: "",
        fault: "",
        faultType: "",
        notes: "",
      });
    } catch (error) {
      setMessage("❌ Error logging fault: " + error.message);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-6">
      <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-md border border-gray-300">
        <h1 className="text-2xl font-semibold text-center text-gray-800 mb-4">
          Log a Machine Fault
        </h1>

        {/* Form */}
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Name Input */}
          <div>
            <label className="block text-gray-700 font-medium mb-1">Name:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
              className="w-full p-2 border rounded-md focus:ring-2 focus:ring-primary focus:outline-none"
            />
          </div>

          {/* Machine Input */}
          <div>
            <label className="block text-gray-700 font-medium mb-1">Machine:</label>
            <input
              type="text"
              name="machine"
              value={formData.machine}
              onChange={handleInputChange}
              required
              className="w-full p-2 border rounded-md focus:ring-2 focus:ring-primary focus:outline-none"
            />
          </div>

          {/* Fault Input */}
          <div>
            <label className="block text-gray-700 font-medium mb-1">Fault:</label>
            <input
              type="text"
              name="fault"
              value={formData.fault}
              onChange={handleInputChange}
              required
              className="w-full p-2 border rounded-md focus:ring-2 focus:ring-primary focus:outline-none"
            />
          </div>

          {/* Fault Type Dropdown */}
          <div>
            <label className="block text-gray-700 font-medium mb-1">Fault Type:</label>
            <select
              name="faultType"
              value={formData.faultType}
              onChange={handleInputChange}
              required
              className="w-full p-2 border rounded-md focus:ring-2 focus:ring-primary focus:outline-none"
            >
              <option value="">Select Type</option>
              <option value="Stoppage">Stoppage</option>
              <option value="Service">Service</option>
            </select>
          </div>

          {/* Notes Textarea */}
          <div>
            <label className="block text-gray-700 font-medium mb-1">Notes:</label>
            <textarea
              name="notes"
              value={formData.notes}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md focus:ring-2 focus:ring-primary focus:outline-none"
            />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-primary text-white py-2 rounded-md hover:bg-indigo-700 transition text-lg font-semibold"
          >
            Submit
          </button>
        </form>

        {/* Message Display */}
        {message && (
          <p className="text-center mt-4 text-lg font-medium text-gray-800">{message}</p>
        )}
      </div>
    </div>
  );
};

export default Machines;
